import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function BusinessDetails({ businessData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div>
			<h4 className='text-20 text-black-1000 font-bold mb-[5px]'>
				{businessData?.business_name}
			</h4>
			<h6 className='text-16 text-black-500 font-normal mb-[5px]'>
				{businessData?.business_type}
			</h6>
			<h6 className='text-16 text-black-500 font-normal mb-5'>
				{businessData?.address_line}
			</h6>
			<div>
				<Card title={ekkLanguage.businessProfile.businessDetailsLabel}>
					<div className='px-2'>
						<table className='table-fixed w-full'>
							<tbody>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.registrationNo}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										{businessData?.registration_number &&
											digitChanger(
												Number(businessData?.registration_number)
											)}
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.taxNo}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										{businessData?.tax_vat_number &&
											digitChanger(
												Number(businessData?.tax_vat_number)
											)}
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.mobileNo}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										{digitChanger(businessData?.phone_number)}
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.officeNo}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										{businessData?.office_number &&
											digitChanger(
												Number(businessData?.office_number)
											)}
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.email}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										{businessData?.email}
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.website}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										{businessData?.website}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Card>
			</div>
		</div>
	);
}
