import React from "react";

export default function OfficeCall() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'>
			<path
				d='M19 2H7.463C6.77 0.805 5.478 0 4 0C1.794 0 0 1.794 0 4V19C0 21.757 2.243 24 5 24H19C21.757 24 24 21.757 24 19V7C24 4.243 21.757 2 19 2ZM21.828 6H17C16.448 6 16 5.552 16 5V4H19C20.304 4 21.415 4.836 21.828 6ZM2 4C2 2.897 2.897 2 4 2C5.103 2 6 2.897 6 4V16C6 17.103 5.103 18 4 18C2.897 18 2 17.103 2 16V4ZM19 22H5C3.511 22 2.273 20.91 2.04 19.486C2.62 19.813 3.288 20 4 20C6.206 20 8 18.206 8 16V4H14V5C14 6.654 15.346 8 17 8H22V19C22 20.654 20.654 22 19 22ZM16 11C16 11.552 15.552 12 15 12C14.448 12 14 11.552 14 11C14 10.448 14.448 10 15 10C15.552 10 16 10.448 16 11ZM12 11C12 11.552 11.552 12 11 12C10.448 12 10 11.552 10 11C10 10.448 10.448 10 11 10C11.552 10 12 10.448 12 11ZM20 11C20 11.552 19.552 12 19 12C18.448 12 18 11.552 18 11C18 10.448 18.448 10 19 10C19.552 10 20 10.448 20 11ZM16 15C16 15.552 15.552 16 15 16C14.448 16 14 15.552 14 15C14 14.448 14.448 14 15 14C15.552 14 16 14.448 16 15ZM12 15C12 15.552 11.552 16 11 16C10.448 16 10 15.552 10 15C10 14.448 10.448 14 11 14C11.552 14 12 14.448 12 15ZM20 15C20 15.552 19.552 16 19 16C18.448 16 18 15.552 18 15C18 14.448 18.448 14 19 14C19.552 14 20 14.448 20 15ZM16 19C16 19.552 15.552 20 15 20C14.448 20 14 19.552 14 19C14 18.448 14.448 18 15 18C15.552 18 16 18.448 16 19ZM12 19C12 19.552 11.552 20 11 20C10.448 20 10 19.552 10 19C10 18.448 10.448 18 11 18C11.552 18 12 18.448 12 19ZM20 19C20 19.552 19.552 20 19 20C18.448 20 18 19.552 18 19C18 18.448 18.448 18 19 18C19.552 18 20 18.448 20 19Z'
				fill='black'
			/>
		</svg>
	);
}
