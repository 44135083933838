import React from "react";

export default function Share() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='26'
			height='24'
			viewBox='0 0 26 24'
			fill='none'>
			<path
				d='M21 15.63C19.8115 15.63 18.7425 16.13 17.9835 16.928L9.115 12.586C9.1455 12.382 9.1665 12.1755 9.1665 11.963C9.1665 11.756 9.1465 11.554 9.1175 11.3555L17.973 7.06098C18.7325 7.86548 19.8065 8.37048 21 8.37048C23.301 8.37048 25.1665 6.50498 25.1665 4.20398C25.1665 1.90248 23.301 0.0374756 21 0.0374756C18.699 0.0374756 16.8335 1.90248 16.8335 4.20398C16.8335 4.41098 16.8535 4.61248 16.8825 4.81148L8.027 9.10598C7.2675 8.30148 6.194 7.79648 5 7.79648C2.6985 7.79648 0.833496 9.66198 0.833496 11.963C0.833496 14.2645 2.6985 16.1295 5 16.1295C6.1885 16.1295 7.2575 15.6295 8.0165 14.8315L16.885 19.1735C16.8545 19.377 16.8335 19.584 16.8335 19.7965C16.8335 22.098 18.699 23.963 21 23.963C23.301 23.963 25.1665 22.098 25.1665 19.7965C25.1665 17.495 23.301 15.63 21 15.63Z'
				fill='white'
				fillOpacity='.8'
			/>
		</svg>
	);
}
