import React from "react";
import ProductHolder from "./ProductHolder";

export default function HomeWrapper() {
	return (
		<>
			<ProductHolder title='Brands' icon='assets/images/icon/badge.png' />
			<ProductHolder title='New Offers' />
			<ProductHolder title='Featured Products' />
			<ProductHolder title='Nearby Suppliers' />
		</>
	);
}
