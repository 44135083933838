export default function AddedBankList({ banks, empLoading, ekkLanguage }) {
	return (
		<div className='pb-5'>
			{banks?.length ? (
				banks?.map((el, i) => {
					return (
						<div className='px-2 pt-5' key={i}>
							<div
								className='bg-white rounded-[10px]'
								style={{
									boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
								}}>
								<div
									className='border-b-2 pb-3 pt-3 flex justify-center items-center px-4'
									style={{ bordreColor: "#f2f3f7" }}>
									<h3 className='text-black-1000 font-bold text-14 text-center flex items-center'>
										<img
											className='w-[26px] h-[26px] mr-[10px]'
											src={el?.bank?.image}
											alt=''
										/>
										{el?.bank?.name}
									</h3>
								</div>
								<div className='px-[10px] py-2'>
									<div className='px-2'>
										<table className='table-fixed w-full'>
											<tbody>
												<tr>
													<td className='text-black-500 text-14 font-semibold py-[7px]'>
														{ekkLanguage.addedBankList.acName}
													</td>
													<td className='text-black-1000 text-14 font-semibold py-[7px] text-right'>
														{el?.account_name}
													</td>
												</tr>
												<tr>
													<td className='text-black-500 text-14 font-semibold py-[7px]'>
														{ekkLanguage.addedBankList.acNumber}
													</td>
													<td className='text-black-1000 text-14 font-semibold py-[7px] text-right'>
														{" "}
														{el?.account_no}
													</td>
												</tr>
												<tr>
													<td className='text-black-500 text-14 font-semibold py-[7px]'>
														{ekkLanguage.addedBankList.branch}
													</td>
													<td className='text-black-1000 text-14 font-semibold py-[7px] text-right'>
														{" "}
														{el?.branch}
													</td>
												</tr>
												<tr>
													<td className='text-black-500 text-14 font-semibold py-[7px]'>
														{ekkLanguage.addedBankList.address}
													</td>
													<td className='text-black-1000 text-14 font-semibold py-[7px] text-right'>
														{" "}
														{el?.address}
													</td>
												</tr>
												<tr>
													<td className='text-black-500 text-14 font-semibold py-[7px]'>
														{ekkLanguage.addedBankList.city}
													</td>
													<td className='text-black-1000 text-14 font-semibold py-[7px] text-right'>
														{" "}
														{el?.city}
													</td>
												</tr>
												<tr>
													<td className='text-black-500 text-14 font-semibold py-[7px]'>
														{ekkLanguage.addedBankList.country}
													</td>
													<td className='text-black-1000 text-14 font-semibold py-[7px] text-right'>
														{" "}
														{el?.country}
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					);
				})
			) : (
				<div
					className='text-center flex items-center justify-center'
					style={{ height: "calc(100vh - 420px)" }}>
					<div>
						<img
							className='m-auto mb-[10px] mt-5'
							src='/assets/images/not-found-file.png'
							alt='emptyCard'
						/>
						<p className='text-16 font-normal text-222222 mb-[35px]'>
							{ekkLanguage.orderList.notFound}
						</p>
					</div>
				</div>
			)}
		</div>
	);
}
