import { createSlice } from "@reduxjs/toolkit";

const addBankSlice = createSlice({
	name: "bankSettings",
	initialState: {
		data: {},
		bankData: {},
		accountName: "",
		accountNo: "",
		branch: "",
		address: "",
		country: "",
		city: "",
	},
	reducers: {
		setAccountName: (state, action) => {
			state.accountName = action.payload;
		},
		setAccountNo: (state, action) => {
			state.accountNo = action.payload;
		},
		setBranch: (state, action) => {
			state.branch = action.payload;
		},
		setAddress: (state, action) => {
			state.address = action.payload;
		},
		setCountry: (state, action) => {
			state.country = action.payload;
		},
		setCity: (state, action) => {
			state.city = action.payload;
		},
		setBankData: (state, action) => {
			state.bankData = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = addBankSlice;

export const setBankData = (data) => async (dispatch, getState) => {
	dispatch(actions.setBankData(data));
};
export const setAccountName = (data) => async (dispatch, getState) => {
	dispatch(actions.setAccountName(data));
};
export const setAccountNo = (data) => async (dispatch, getState) => {
	dispatch(actions.setAccountNo(data));
};
export const setBranch = (data) => async (dispatch, getState) => {
	dispatch(actions.setBranch(data));
};
export const setAddress = (data) => async (dispatch, getState) => {
	dispatch(actions.setAddress(data));
};
export const setCountry = (data) => async (dispatch, getState) => {
	dispatch(actions.setCountry(data));
};
export const setCity = (data) => async (dispatch, getState) => {
	dispatch(actions.setCity(data));
};

export const setEmptyBankData = () => async (dispatch, getState) => {
	dispatch(actions.setBankData({}));
	dispatch(actions.setBankData(""));
	dispatch(actions.setAccountName(""));
	dispatch(actions.setAccountNo(""));
	dispatch(actions.setBranch(""));
	dispatch(actions.setAddress(""));
	dispatch(actions.setCountry(""));
	dispatch(actions.setCity(""));
};

// Export the reducer, either as a default or named export
export default reducer;
