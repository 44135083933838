import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BrandList from "components/mobile-components/BrandList";
import BrandBottomSheet from "components/mobile-components/BrandList/BrandBottomSheet";
import CategoryList from "components/mobile-components/Category";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Search from "components/mobile-components/Common/Search";
import DepartmentList from "components/mobile-components/DepartmentList";
import SubCategoryList from "components/mobile-components/SubCategory";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Brand from "services/Brand";
import Product from "services/Product";

export default function SubCategoriesPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [subCategoryList, setSubCategoryList] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");
	let product = useSelector((state) => state.product.data);

	let getSubCategory = async () => {
		let response = await Product.getSubCategory(product?.category.id);
		if (response.status === 200) {
			setSubCategoryList(response.results);
		}
	};
	useEffect(() => {
		getSubCategory();
	}, []);

	let singleSubCategorySearch = async () => {
		let response = await Brand.getSingleDepartment(searchQ);
		if (response.status === 200) {
			setSubCategoryList(response.results);
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			getSubCategory();
		}
	}, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.createProduct.selectSubCategory}
					/>
					<section className='pt-14'>
						<div className='px-4 py-5'>
							<Search
								placeholder={
									ekkLanguage.createProduct.searchSubCategory
								}
								search={searchQ}
								setSearch={setSearchQ}
								singleSearch={singleSubCategorySearch}
							/>
						</div>
						<SubCategoryList subCategoryList={subCategoryList} />
					</section>
					{/* <div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								lodeMoreData();
								setTimeout(() => ev.target.complete(), 1000);
							}}>
							<IonInfiniteScrollContent
								className='pb-24'
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner='bubbles'></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div> */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
