import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import PaymentMethodSubscription from "components/mobile-components/PaymentMethodSubscription";
import React from "react";
import { useSelector } from "react-redux";

const methods = [
	{
		id: 1,
		title: "Card",
		icon: "assets/images/bank-card.png",
		navigationLink: "card",
	},
	// {
	// 	id: 2,
	// 	title: "Card",
	// 	icon: "/images/card.png",
	// 	navigationLink: "card",
	// },
	// {
	// 	id: 3,
	// 	// title: "Bkash",
	// 	icon: "/images/bkash_full_logo.png",
	// 	navigationLink: "bkash",
	// },
	// {
	// 	id: 4,
	// 	// title: "Nagad",
	// 	icon: "/images/nagad_full_logo.png",
	// 	navigationLink: "nagad",
	// },
	// {
	// 	id: 5,
	// 	// title: "Upay",
	// 	icon: "/images/upay_full_logo.png",
	// 	navigationLink: "upay",
	// },
];

export default function PaymentMethodSubscriptionPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.paymentModule.paymentMethod}
					/>
					<section className='pt-14 relative w-full h-full px-4'>
						<PaymentMethodSubscription methods={methods} />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
