const malaysia = {
	login: {
		contryLabel: "NEGARA",
		contryPlaceHolder: "",
		phoneLable: "NOMBOR TELEFON",
		phonePlaceHolder: "NOMBOR TELEFON",
		validationLabel: "Sila berikan nombor telefon yang sah!",
		btnLabel: "Teruskan",
		tokenSuccessLabel: "Token anda telah dihantar ke telefon anda",
		tokenSuccessFailed: "Token anda belum dicipta, sila cuba lagi",
		langChange: "Tukar bahasa ke",
		termsAllow: "Sila benarkan syarat dan terma semasa log masuk!",
	},
	password: {
		passwordLabel: "KATA LALUAN",
		passwordPlaceHolder: "Masukkan kata laluan anda",
		forgetPass: "Lupa Kata Laluan",
		btnLabel: "Log Masuk",
		otpSendLabel: "Kod telah dihantar ke telefon bimbit anda",
		backWord: "Pergi Kembali",
		passError: "Kata laluan tidak sepadan dengan nombor telefon",
	},
	changePass: {
		passwordLabel: "KATA LALUAN BARU",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Kata laluan tidak sepadan",
		btnLabel: "Teruskan",
		successLabel: "Kata laluan anda berjaya ditukar",
		errorLabel: "Kemaskini kata laluan gagal",
		lengthErrorLabel:
			"Kata laluan mesti sekurang-kurangnya 4 aksara panjangnya",
		notMatchLabel: "Kata laluan dan pengesahan kata laluan tidak sepadan",
	},
	token: {
		tokenLabel: "TOKEN",
		tokenPlaceHolder: "Masukkan token anda",
		tokenResend: "Hantar semula token",
		tokenSuccessLabel:
			"Kod telah dihantar ke telefon bimbit anda, taip untuk teruskan",
		tokenErrorLabel: "Penghantaran token gagal",
		timerLabel: "Sila tunggu",
		second: "saat",
		btnLabel: "Teruskan",
		tokenNotMatch: "Token tidak sepadan",
		tokenVarified: "Token anda telah disahkan",
		tokenNotWrite: "Sila masukkan token anda",
	},
	registration: {
		nameLabel: "NAMA LENGKAP",
		namePlaceholder: "Masukkan nama lengkap anda",
		emailLabel: "EMEL",
		emailPlaceholder: "Masukkan emel anda",
		passwordLabel: "KATA LALUAN",
		passwordPlaceholder: "Masukkan kata laluan anda",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceholder: "Masukkan kata laluan pengesahan anda",
		validationLabel:
			"Medan input mesti sekurang-kurangnya 4 aksara panjangnya",
		passNotMatch: "Kata laluan dan kata laluan pengesahan tidak sepadan.",
		btnLabel: "Teruskan",
		successMessage: "Profil Berjaya Dicipta",
		errorMessage: "Sila periksa input yang sah",
		passLengthValidLabel:
			"Kata laluan mesti sekurang-kurangnya 4 aksara panjangnya",
		passwordLength: "Kata laluan anda adalah",
		strong: "Kuat",
		medium: "Sederhana",
		weak: "Lemah",
		gender: "JANTINA",
		male: "Lelaki",
		female: "Perempuan",
		others: "Lain-lain",
		dobLabel: "TARIKH LAHIR",
		day: "Hari",
		month: "Bulan",
		year: "Tahun",
	},
	letNav: {
		homeMenuLabel: "Laman Utama",
		orderMenuLabel: "Senarai Pesanan",
		mySupplierMenuLabel: "Pembekal Saya",
		businessProfileMenuLabel: "Tetapan Profil Perniagaan",
		dashboardProfileMenuLabel: "Papan Pemuka",
		phoneCall: "Panggilan Untuk Pesanan",
		myEmployeesMenuLabel: "Pekerja Saya",
		myCustomersMenuLabel: "Pengedar",
		product: "Produk",
	},
	home: {
		showMoreLabel: "Tunjukkan Lebih Banyak",
		brandLabel: "Jenama",
		offerLabel: "Tawaran Baru",
		mySuppliersLabel: "Pembekal Saya",
		nearSuppliersLabel: "Pembekal Berdekatan",
		recommendentLabel: "Sesuai Untuk Anda",
		searchLabel: "Cari produk",
		todayPurchaseLabel: "Pembelian Hari Ini",
		totalOrderLabel: "Produk dari",
		totalOrder: "Pesanan",
		skusLabel: "SKU",
		tillNow: "Sehingga Sekarang",
		orderDue: "Pesanan Jatuh Tempo",
		brandProductPageTitle: "Produk Jenama",
		offerProductPageTitle: "Produk Tawaran",
		offerProductNotFound: "Maaf, Tiada tawaran ditemui untuk anda.",
		mySupplierNotFound:
			"Anda tidak mempunyai mana-mana pembekal. Sila beli produk untuk menambah pembekal anda di sini.",
		account: "Akaun",
		orderNumber: "Nombor Pesanan",
		amount: "Jumlah",
		warning: `Harap maklum, transaksi akan terjejas pada 1 Mac 2024 kerana sistem sedang dikemaskini.`,
		warningHeading: "Amaran",
	},
	profile: {
		pageTitle: "Profil & Tetapan",
		contactMenuLable: "Hubungi & Maklumat Asas",
		passwordMenuLable: "Tukar Kata Laluan",
		logoutMenuLable: "Log Keluar",
		changeLanguageMenuLable: "Tukar Bahasa",
		kycMenuLable: "Maklumat KYC",
		subscriptionPlan: "pelan langganan",
	},
	changeLanguages: {
		pageTitle: "Pilih Bahasa",
		btnLabel: "Simpan",
	},
	contact: {
		pageTitle: "Maklumat Pengguna",
		emailLabel: "Emel",
		emailPlaceholder: "Masukkan emel anda",
		nidLabel: "NID",
		nidPlaceholder: "Masukkan nombor NID",
		btnLabel: "Simpan",
		successLabel: "Profil anda telah dikemaskini dengan berjaya",
		currectEmail: "sila masukkan alamat emel semasa yang betul",
	},
	otpsend: {
		pageTitle: "Sahkan Nombor",
		btnLabel: "Hantar",
		message:
			"Ketik untuk menghantar kod pengesahan ke telefon bimbit anda melalui SMS.",
		successLabel: "Kod anda telah dihantar ke telefon anda",
		errorLabel: "Kod anda belum dicipta, sila cuba lagi",
	},
	otpsubmit: {
		pageTitle: "Sahkan Nombor",
		btnLabel: "Hantar",
		title: "Kod Pengesahan",
		verifiLabel: "Sahkan & Teruskan",
		paragraph: "Kami telah menghantar kod pengesahan ke Nombor Telefon Anda",
		otpLabel: "OTP",
		otpPlaceholder: "Masukkan kod OTP anda",
		receiveYetLabel: "Belum menerima kod?",
		againRequestLabel: "Minta sekali lagi",
		timerLabel: "Sila tunggu",
		second: "saat",
		successLabel: "Kod anda telah dihantar ke telefon anda",
		errorLabel: "Kod anda belum dicipta, sila cuba lagi",
		tokenVarifiedMessage: "Kod anda telah disahkan",
		validationLabel: "Sila hantar OTP anda",
	},
	changePassword: {
		pageTitle: "Tukar Kata Laluan",
		btnLabel: "Hantar",
		passwordLabel: "KATA LALUAN",
		passwordPlaceholder: "Masukkan kata laluan anda",
		confirmPassLabel: "SAHKAN KATA LALUAN",
		confirmPassPlaceholder: "Masukkan kata laluan pengesahan anda",
		successLabel: "Kata laluan anda telah dikemaskini.",
		errorLabel: "Kemaskini kata laluan anda gagal",
		passNotMatchLabel:
			"Kata laluan dan kata laluan pengesahan tidak sepadan.",
		changePassSuccessPara: "Kata laluan anda telah berjaya ditukar.",
		changePassContinueBtn: "Teruskan",
	},
	brandDetails: {
		skusLabel: "SKU",
		descriptionLabel: "Penerangan",
		productTitleLabel: "Produk Jenama Ini",
		pageTitle: "Perincian Jenama",
		sku: "SKU",
		import: "Import",
		edit: "Edit",
		delete: "Padam",
		deleteConformationAsk: "Adakah anda pasti ingin memadam jenama ini?",
	},
	mysuppliers: {
		skusLabel: "SKU",
		productTitleLabel: "Produk Pembekal Ini",
	},
	createOrder: {
		pageTitle: "Buat Pesanan",
		buyerDetailsTitle: "Maklumat Pembeli",
		sellerDetailsTitle: "Maklumat Penjual",
		deliveryDetailsTitle: "Penghantaran",
		productItemTitle: "Item Produk",
		costSummaryTitle: "Ringkasan Kos",
		paymentDetailsTitle: "Maklumat Pembayaran",
		paymentTermsTitle: "Syarat Pembayaran",
		paymentMethodeTitle: "Kaedah Pembayaran",
		moqLabel: "Kuantiti Pesanan Minimum",
		pcsLabel: "Pcs",
		subtotal: "Jumlah Keseluruhan",
		tax: "Cukai",
		discount: "Diskaun",
		deliveryFee: "Fi Penghantaran",
		creditFee: "Fi Perkhidmatan",
		total: "Jumlah",
		immediatePayment: "Pembayaran Segera",
		pickAndPay: "Pilih dan Bayar",
		today: "Pada Malam Hari Penghantaran",
		threeDays: "Bayar dalam 3 hari",
		sevenDays: "Bayar dalam 7 hari",
		immediatePaymentText:
			"Tiada Fi Perkhidmatan yang terpakai untuk pesanan ini.",
		todayPaymentText1:
			"Untuk pembayaran hari ini, Fi Perkhidmatan tambahan sebanyak ",
		todayPaymentText2: "akan ditambah kepada pesanan ini.",
		threePaymentText1:
			"Untuk pembayaran dalam 3 hari, Fi Perkhidmatan tambahan sebanyak ",
		threePaymentText2: "akan ditambah kepada pesanan ini.",
		sevenPaymentText1:
			"Untuk pembayaran dalam 7 hari, Fi Perkhidmatan tambahan sebanyak ",
		sevenPaymentText2: "akan ditambah kepada pesanan ini.",
		cashOnDelivery: "Bayaran Tunai Semasa Penghantaran",
		cashOnDeliveryText1: "Bayar Tunai kepada",
		cashOnDeliveryText2: "apabila barang dihantar.",
		cash: "Tunai",
		cashText: "Bayar Tunai kepada",
		bankTransfer: "Pindahan Bank",
		bankTransferText1: "Pindahan Bank - Bayar ke",
		bankTransferText2: "akaun bank dengan 'Nombor Pesanan' sebagai rujukan",
		bankTransferText3: "Nama Bank",
		bankTransferText4: "Nama Akaun",
		bankTransferText5: "Nombor Akaun",
		bankTransferText6: "Nombor Laluan",
		bankTransferText7: "Cawangan",
		btnLabel: "Sahkan",
		addMoreProductLabel: "Tambah Lagi Produk",
		selectProductLabel: "Hantar",
		cosgressTitle: "Tahniah !",
		cosgressPara: "Pesanan anda telah selesai.",
		congressCancelText:
			"Jika ada sebarang masalah dengan pesanan, batalkan sebelum pukul 12 tengah hari. Anda tidak dapat membatalkan pesanan selepas pukul 12 tengah hari.",
		congressProductLabel: "Teruskan",
		orderNow: "Buat Pesanan Sekarang",
		alert: "Amaran",
		importentMessage: "Mesej Penting",
		deliveryDateMessage: "Sila Pilih Tarikh Penghantaran",
		paymentTermsMessage: "Sila Pilih Terma Pembayaran",
		paymentMethodMessage: "Sila Pilih Kaedah Pembayaran",
		backButtonMessage:
			"Troli anda mempunyai beberapa item, jika anda meninggalkan halaman ini, troli anda akan kosong.",
		okBtnLabel: "OK",
		loader: "Sila tunggu...",
		placeConformationMessage: "Adakah anda pasti ingin menempah pesanan ini?",
		lessThenMoq:
			"Kuantiti item yang dipilih kurang daripada kuantiti pesanan minimum.",
		all: "Semua",
		requestDeliveryDate: "Mohon Tarikh Penghantaran",
	},

	selectProduct: {
		pageTitle: "Pilih Produk",
		totalLabel: "Jumlah",
		btnLabel: "Hantar",
	},
	orderList: {
		pageTitle: "Cipta Pesanan",
		tabMenuRecent: "Semua",
		tabMenuPlaced: "Ditempatkan",
		tabMenuAccepted: "Diterima",
		tabMenuScDelivered: "Dirancang Untuk Penghantaran",
		tabMenuPartialDelivered: "Sebahagian Dihantar",
		tabMenuDelivered: "Dihantar",
		tabMenuReturn: "Pulangan Produk",
		tabMenuPartialPaid: "Sebahagian Dibayar",
		tabMenuPaid: "Dibayar",
		tabMenuCancel: "Batal",
		tabMenuDeliveryFailed: "Penghantaran Gagal",
		orderItem: "Item Pesanan",
		seller: "Penjual",
		buyer: "Pembeli",
		loader: "Sila tunggu...",
		createdBy: "Dicipta Oleh",
		to: "Kepada",
		for: "Untuk",
		notFound: "Tidak Ditemui",
	},

	customerList: {
		pageTitle: "Senarai Pengedar",
		searchPlaceHolder: "Cari Pengedar Anda",
		detailsPageTitle: "Butiran Pengedar",
		customerDetailsLabel: "Maklumat Pengedar",
		businessTypeLabel: "Jenis Perniagaan",
		customerNameLabel: "Nama",
		addressLabel: "Alamat",
		postalCodeLabel: "Poskod",
		cityLabel: "Bandar",
		acceptedCustomerLabel: "Pengedar",
		requestedCustomerLabel: "Senarai Pesanan",
		accept: "Terima",
		acceptConfirmation: "Adakah anda pasti ingin menerima Pengedar ini?",
		km: "km",
		createCustomer: "Cipta Pengedar Baru",
		invitedDistributor: "Senarai pengedar jemputan",
		customerNoHolder: "Nombor Telefon Pengedar",
		nextBtn: "Seterusnya",
		deleteDistributor: "Padam Pengedar",
		addCustomPrice: "Tambah Harga Produk Kustom",
		warningMsg: "Adakah anda pasti ingin memadamkan Pengedar ini?",
		yes: "Ya",
		no: "Tidak",
		successMsg: "Pengedar berjaya dipadamkan!",
		customPriceMsg:
			"Adakah anda pasti ingin menetapkan harga khusus untuk pengedar ini?",
		inputPlaceholder: "Masukkan Harga Kustom",
		priceEmptyError: "Harga Kustom tidak boleh kosong!",
		alreadySetPriceError:
			"Harga Produk ini sudah ditetapkan untuk pengedar ini",
		cancelBtn: "Batal",
		submit: "Hantar",
		modalTitle: "Tetapkan Harga Produk Kustom",
		productsSectionTitle: "Produk Harga Kustom",
		distributorCustomerList: "Senarai Pelanggan Pengedar",
		updatePriceTitle: "Kemaskini Harga Kustom",
		deletePriceTitle: "Padam Harga Produk Ini",
		productDetailTitle: "Butiran Produk",
		offerDetailsTitle: "Butiran Tawaran",
		description:
			"Adakah anda pasti ingin memadamkan produk harga kustom ini?",
		distributorCode: "Kod Pengedar",
		notSet: "Belum Ditetapkan",
		distributorCodeAdd: "Tambah Kod Pengedar",
		distributorCodeEdit: "Kemaskini Kod Pengedar",
		writeDistributorCode: "Tulis Kod Pengedar",
		successLabel: "Kod Pengedar Berjaya Ditambah",
		editSuccessLabel: "Kod Pengedar Berjaya Dikemaskini",
	},
	orderDetails: {
		createdBy: "Dicipta oleh",
		placedLabel: "Ditempatkan",
		deliveredLabel: "Dihantar",
		paidLabel: "Dibayar",
		orderItemLabel: "Item Pesanan",
		castItemLabel: "Butiran Kos",
		timelineLabel: "Garis Masa",
		subtotal: "Jumlah Keseluruhan",
		tax: "Cukai / SST",
		discount: "Diskaun",
		deliveryFee: "Fi Penghantaran",
		creditFee: "Fi Perkhidmatan",
		total: "Jumlah",
		paymentSettlementLabel: "Penyelesaian Pembayaran",
		date: "Tarikh",
		amount: "Jumlah",
		dueDate: "Tarikh Maturity",
		remark: "Catatan",
		collectedBy: "Dikumpulkan oleh",
		paymentDetailsLabel: "Butiran Pembayaran",
		paymentMethod: "Kaedah Pembayaran",
		paymentTerms: "Terma Pembayaran",
		paymentDueDate: "Tarikh Maturity Pembayaran",
		totalDue: "Jumlah Tertunggak",
		totalPaid: "Jumlah Dibayar",
		remaining: "Baki",
		deliveryDetailsLabel: "Butiran Penghantaran",
		selectTimeDate: "Pilih Tarikh & Masa Penghantaran",
		selectTime: "Pilih Masa Penghantaran",
		selectDate: "Pilih Tarikh Penghantaran",
		acceptOrder: "Terima Pesanan",
		cancelOrder: "Batal Pesanan",
		scheduleDelivery: "Jadual untuk Penghantaran",
		BuyerName: "Nama Pembeli",
		address: "Alamat",
		phoneNumber: "Nombor Telefon",
		email: "Emel",
		deliveryDate: "Tarikh Penghantaran",
		downloadInvoices: "Muat Turun Invois",
		receiptDownloads: "Cetak Resit",
		cancelConformationAsk: "Adakah anda pasti ingin Membatalkan Pesanan ini?",
		cancelConformationParagraph: `Anda tidak boleh membatalkan pesanan ini, anda hanya boleh membatalkan pesanan anda pada hari yang sama.`,
		yesBtn: "Ya",
		noBtn: "Tidak",
		withoutSettelment:
			"Maklumat pembayaran yang diselesaikan akan ditunjukkan di sini.",
		bankInfoLabel: "Maklumat Akaun Bank untuk Pindahan Pembayaran",
		assignDriver: "Tetapkan Pemandu",
		assignCollector: "Tetapkan Pemungut",
		assignedDriver: "Pemandu Ditugaskan",
		assignedCollector: "Pemungut Ditugaskan",
		notAssigned: "Belum Ditugaskan Lagi",
		addDeliveryFee: "Tambah Fi Penghantaran",
		addDiscount: "Tambah Diskaun",
		feePlaceHolder: "Tulis Jumlah Fi Penghantaran Anda",
		discountPlaceHolder: "Tulis Jumlah Diskaun Anda",
		requestDeliveryDate: "Mohon Tarikh Penghantaran",
		scheduleDeliveryDate: "Jadual Tarikh Penghantaran",
		scheduleDeliveryTime: "Jadual Masa Penghantaran",
		deliveryFailMessage: "Mesej Penghantaran Gagal",
		selectCancelOrder: "Pilih Sebab untuk Membatalkan Pesanan",
		multipleTime: "Penghantaran gagal berulang kali",
		productNotAvailable: "Produk tidak tersedia",
		cannotFindAddress: "Tidak dapat mencari alamat",
		noShop: "Alamat pendiri tetapi tiada kedai di sana",
		buyerNotAccept: "Pembeli tidak menerima harga pesanan",
		supplierNotGive: "Pembekal tidak memberi produk",
		other: "Lain-lain",
		yes: "Ya",
		no: "Tidak",
		deliveryTimeWarning:
			"Anda mesti memilih tarikh penghantaran, jika tidak kami tidak dapat memproses untuk menjadualkan penghantaran",
		deliveryDateWarning:
			"Anda mesti memilih masa penghantaran, jika tidak kami tidak dapat memproses untuk menjadualkan penghantaran",
		deliveryDateTimeWarning:
			"Anda mesti memilih tarikh dan masa penghantaran",
		orderSearchLabel: "Cari Pesanan Anda",
		close: "Tutup",
		confirmDelivery: "Penghantaran Selesai",
		instantDelivery:
			"Adakah anda pasti ingin mengesahkan Penghantaran Selesai untuk pesanan ini?",
		transactionType: "Jenis Transaksi",
		gateway: "Gateway Pembayaran",
		accountName: "Nama Akaun",
		accountNumber: "Nombor Akaun",
		transactionNumber: "Nombor Transaksi",
		receipt: "Resit",
		receiptNotProvided: "Resit Tidak Disediakan",
		sellerPaymentDone: "Pembayaran Penjual Diselesaikan",
	},
	businessCreateName: {
		pageTitle: "Nama Perniagaan",
		businessName: "Nama Perniagaan",
		businessNamePlaceholder: "Masukkan Nama Perniagaan",
		paragraph:
			"Kita tinggal 3 langkah lagi untuk mencipta perniagaan anda. Mari kita mulakan dengan nama perniagaan anda.",
		btnLabel: "Seterusnya",
		submit: "Hantar",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Masukkan nama perniagaan!",
		congratulation: "Tahniah",
		congratulationMessage: "Perniagaan anda berjaya dicipta",
		kycSubmit: "Hantar KYC Anda",
		pending:
			"Terima kasih kerana menghantar KYC. Pengesahan KYC anda sedang dalam proses, sila tunggu.",
		goBack: "Pergi Kembali",
	},
	businessType: {
		pageTitle: "Jenis Perniagaan",
		paragraph: "Mari pilih jenis perniagaan anda.",
		fmcg: "FMCG",
		miniMart: "Mini Mart",
		superMart: "Super Mart",
		pharmacy: "Farmasi",
		cart: "Kereta",
		bazaarStore: "Kedai Bazar",
		hawkerCenter: "Pusat Penjaja",
		restaurant: "Restoran",
		hotel: "Hotel",
		cosmeticsStore: "Kedai Kosmetik",
		nonFmcg: "BUKAN FMCG",
		office: "Pejabat",
		NGO: "NGO",
		entertainmentPlace: "Tempat Hiburan",
		religiousOrganization: "Organisasi Keagamaan",
		spa: "Spa",
		salon: "Salon",
		gym: "Gym",
		electronicsStore: "Kedai Elektronik",
		shoeStore: "Kedai Kasut",
		fashionStore: "Kedai Fesyen",
		laundryStore: "Kedai Dobi",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Seterusnya",
		validationLabel: "Sila Pilih jenis perniagaan",
		producer: "Pengeluar",
		reseller: "Penjual Semula",
	},
	businessLocation: {
		pageTitle: "Lokasi Perniagaan",
		paragraph: "Masukkan butiran hubungan perniagaan anda.",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat anda",
		city: "Bandar",
		cityPlaceholder: "Masukkan Nama Bandar",
		postalCode: "Poskod",
		postalCodePlaceholder: "Masukkan kod pos",
		email: "Emel",
		userName: "Nama Pengguna",
		userNamePlaceholder: "Masukkan nama pengguna anda",
		emailPlaceholder: "Masukkan emel anda",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "SIAP",
		successLabel: "Tahniah, perniagaan berjaya dicipta",
		errorLabel: "Penciptaan perniagaan gagal",
		validationLabel: "Sila isikan alamat untuk teruskan.",
		loader: "Sila tunggu...",
		validationCityLabel: "Sila isikan nama bandar.",
		validationPostCodeLabel: "Sila isikan kod pos.",
	},
	locationPicker: {
		pageTitle: "Cari Lokasi Kedai",
		btnLabel: "SAHKAN",
		paragraph: `Tukar ke keadaan penanda peta untuk memilih lokasi perniagaan anda`,
	},
	location: {
		title: "Aktifkan Lokasi Anda",
		paragraph:
			"Aplikasi ini memerlukan perkhidmatan lokasi yang dihidupkan pada peranti anda dan untuk aplikasi ini. Anda mesti mengaktifkannya di Tetapan sebelum menggunakan aplikasi ini.",
		btnLabel: "Benarkan hanya semasa menggunakan aplikasi",
	},
	businessSelect: {
		pageTitle: "Pilih Perniagaan",
	},
	pwaDownload: {
		header: "Muat Turun Aplikasi Jenama EkkBaz",
		paragraph:
			"Muat turunkan aplikasi dan pesan borong dari pembekal anda pada bila-bila masa dari sekarang.",
		cancelBtn: "Batal",
		downloadBtn: "Muat Turun",
	},
	businessProfile: {
		pageTitle: "Tetapan Profil Perniagaan",
		businessDetailsLabel: "Butiran",
		registrationNo: "Nombor Pendaftaran",
		taxNo: "Nombor Cukai/VAT",
		mobileNo: "Nombor Telefon Bimbit",
		officeNo: "Nombor Pejabat",
		email: "Emel",
		website: "Laman Web",
		branchLabel: "Senarai Cawangan",
		paymentDetailsLabel: "Butiran Pembayaran",
		bankInfoLabel: "Maklumat Bank",
		bankName: "Nama Bank",
		branchName: "Cawangan Bank",
		branchAddress: "Alamat Bank",
		swiftCode: "Kod Swift Bank",
		acName: "Nama Akaun Bank",
		acNumber: "Nombor Akaun Bank",
		bkashInfoLabel: "Maklumat bKash",
		bkashAcName: "Nama Akaun bKash",
		bkashAcNo: "Nombor Akaun bKash",
		nagadInfoLabel: "Maklumat Nagad",
		nagadAcName: "Nama Akaun Nagad",
		nagadAcNo: "Nombor Akaun Nagad",
		upayInfoLabel: "Maklumat Upay",
		upayAcName: "Nama Akaun Upay",
		upayAcNo: "Nombor Akaun Upay",
		verified: "Disahkan",
		unVerified: "Belum disahkan",
		editBusinessMenu: "Edit Perniagaan",
		addPayMenu: "Tambah Opsyen Pembayaran",
		setLocation: "Kemaskini Lokasi Perniagaan",
		addMenu: "Tambah Cawangan",
		verifyBusinessMenu: "Sahkan Perniagaan",
		bankInfoNotFound: "Maklumat bank tidak ditemui.",
		paymentTermsSettings: "Tetapan Terma Pembayaran",
		switchBusiness: "Tukar Perniagaan",
		deleteBusiness: "Padam Perniagaan",
		deleteWarning:
			"Semua pesanan, produk, pekerja, dan akaun anda akan dipadamkan. Adakah anda pasti ingin memadam perniagaan ini?",
		otpSubmit: "Hantar OTP",
		confirmDeleteWarning:
			"Sila fikirkan lagi! Semua pesanan, produk, pekerja, dan akaun anda akan dipadamkan. Adakah anda pasti ingin memadam perniagaan ini?",
		directSells: "Jualan Langsung",
		directSellsEnable: "Aktifkan jualan langsung untuk perniagaan anda?",
		directSellsDisable:
			"Jualan langsung telah diaktifkan untuk perniagaan anda. Adakah anda mahu mematikan jualan langsung?",
		yes: "Ya",
		no: "Tidak",
	},
	businessEdit: {
		pageTitle: "Edit Perniagaan",
		uploadPhoto: "Muat naik Foto",
		email: "Emel",
		emailPlaceholder: "Masukkan emel anda",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat anda",
		city: "Bandar",
		cityPlaceholder: "Masukkan Nama Bandar",
		postalCode: "Poskod",
		postalCodePlaceholder: "Masukkan kod pos",
		resigter: "Nombor Pendaftaran",
		resigterPlaceholder: "Masukkan Nombor Pendaftaran anda",
		tex: "Nombor Cukai/VAT",
		texPlaceholder: "Masukkan Nombor Cukai/VAT anda",
		mobile: "Nombor Telefon Bimbit",
		mobilePlaceholder: "Masukkan Nombor Telefon Bimbit anda",
		office: "Nombor Pejabat",
		officePlaceholder: "Masukkan Nombor Pejabat anda",
		website: "Laman Web",
		websitePlaceholder: "Masukkan laman web anda",
		btnLabel: "Simpan",
		primaryIndustry: "Primary Industry",
		successLabel: "Profil anda berjaya dikemas kini.",
		currectEmail: "Sila masukkan alamat emel semasa.",
	},
	bankDetails: {
		referenceNumber: "Nombor Rujukan",
		bankName: "Nama Bank",
		accountName: "Nama Akaun",
		accountNumber: "Nombor Akaun",
		routeNumber: "Nombor Laluan",
		branch: "Cawangan",
	},
	invoice: {
		title: "INVOIS",
		receipt: "Resit",
		buyerDetails: "Butiran Pembeli",
		sellerDetails: "Butiran Penjual",
		description: "PENERANGAN",
		qty: "KUANTITI",
		price: "HARGA",
		subtotal: "JUMLAH",
	},
	verifyBusiness: {
		numberOne: "১",
		numberTwo: "২",
		numberThree: "৩",
		numberFour: "৪",
		numberFive: "৫",
		numberSix: "৬",
	},
	receipt: {
		placed: "Diletakkan",
		name: "Nama",
		qty: "Kuantiti",
		price: "Harga",
		subtotal: "Jumlah",
		tax: "Cukai",
		discount: "Diskaun",
		creditFee: "Bayaran Perkhidmatan",
		deliveryFee: "Bayaran Penghantaran",
		total: "Jumlah Keseluruhan",
		paid: "Dibayar",
		totalDue: "Jumlah Kredit",
		paymentTerms: "Terma Pembayaran",
		days: "Hari",
		paymentMethod: "Kaunter Bayaran",
		referenceNumber: "Nombor Rujukan Bank",
		deliveryDate: "Tarikh Penghantaran",
		dueDate: "Tarikh Pembayaran Akhir",
		printTime: "Masa Cetakan",
		conditionsCreditFee: "Terma Bayaran & Yuran Perkhidmatan",
		immediatePayment: "Bayaran Segera",
		cashOnDelivery: "Bayaran Tunai Semasa Penghantaran",
		atNight: "Pada Malam Hari Penghantaran",
		payInthreeDay: "Bayar dalam 3 Hari",
		payInSevenDay: "Bayar dalam 7 Hari",
		afterSeven: "Selepas 7 Hari (tambahan untuk setiap hari tambahan)",
	},
	landing: {
		header1: "Mudah menguruskan pengedaran anda",
		header2: "penjualan saluran",
		login: "Log masuk",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malaysia: "Malay",
		retail: "Runcit",
		manufacture: "Pembekal",
		brand: "Jenama",
		hotline: "Talian panas",
		phoneNumber: "Nombor Telefon",
		phoneNumberPlaceHolder: "Masukkan Nombor Telefon",
		brandHeader: "Jenama yang bekerjasama dengan kami!",
		fAbout:
			"Satu-satunya penyelesaian adalah menjual, menjadikan penjualan lebih mudah!",
		location: "Lokasi",
		singapore: "Pejabat Singapura",
		bangladesh: "Pejabat Bangladesh",
		bOfficeAddress: "Tingkat 13, Razzak Plaza, Moghbazar Moor, Dhaka-1205",
		otherInfo: "Maklumat lain",
		partners: "Rakan-rakan Kami",
		news: "Berita",
		support: "Sokongan",
		corporate: "Korporat",
		refundPolicy: "Dasar Pulangan",
		termsCondition: "Syarat-syarat",
		privacyPolicy: "Dasar Privasi",
		contactUs: "Hubungi Kami",
		messageUs: "Hantar Mesej",
		facebook: "Facebook",
		sales: "Jualan",
		media: "Media",
		downloadBtn: "Muat turun Apl",
		backers: "Penyokong Kami",
		benefits: "Faedah Anda",
		discover: "Berita Terkini EkkBaz",
		benefitsOne: "Tingkatkan penjualan",
		benefitsDisOne: "",
		benefitsTwo: "Mudah mencapai pelanggan",
		benefitsDisOneTwo: "",
		benefitsThree: "Meningkatkan kelihatan produk",
		benefitsDisOneThree: "",
		benefitsFore: "Mendigitalkan proses jualan anda",
		benefitsDisOneFore: "",
		seeMore: "Lihat Lebih Banyak",
		downloadApp: "Muat turun Apl Sekarang!",
	},
	employees: {
		pageTitle: "Pekerja",
		createNewEmployees: "Cipta Pekerja Baru",
		searchPlaceHolder: "Cari pekerja anda",
	},
	employeesDetails: {
		pageTitle: "Butiran Pekerja",
	},
	createEmployees: {
		pageTitle: "Cipta Pekerja Baru",
		customerNoHolder: "Masukkan Nombor Telefon Bimbit",
		employeeNumber: "Nombor Pekerja",
		name: "Nama",
		namePlaceholder: "Masukkan nama pekerja",
		confirmBtn: "Sahkan",
		selectEmployees: "Pilih jawatan pekerja",
		admin: "Pentadbir",
		stuff: "Staf",
		orderManager: "Pengurus Pesanan",
		bookManager: "Pengurus Buku",
		productManager: "Pengurus Produk",
		employeeManager: "Pengurus Pekerja",
		nameError: "Sila masukkan nama pekerja",
		roleError: "Sila pilih peranan pekerja",
		edit: "Edit",
		delete: "Padam",
		editEmployee: "Edit Pekerja",
		deleteConformationAsk: "Adakah anda pasti untuk memadam pekerja ini?",
		updateEmployee: "Kemaskini Pekerja",
	},
	productList: {
		pageTitle: "Senarai Produk",
		offerPageTitle: "Senarai Tawaran",
		searchYourProduct: "Cari Produk Anda",
		brandList: "Senarai Jenama",
		createProduct: "Cipta Produk",
		createPack: "Cipta Bungkusan",
		createOffer: "Cipta Tawaran",
		product: "Produk",
		pack: "Bungkusan",
		offer: "Tawaran",
		discount: "Diskaun",
		all: "Semua",
	},
	productDetails: {
		pageTitle: "Butiran Produk",
		offerStart: "Tarikh Mula Tawaran",
		offerEnd: "Tarikh Tamat Tawaran",
		moqLabel: "MOQ",
		consumerLabel: "Harga Pengguna (MRP)",
		profitLabel: "Keuntungan",
		productDetailTitle: "Butiran Produk",
		expireData: "Tarikh Luput",
		manufactureData: "Tarikh Pengeluaran",
		productWeight: "Berat Produk",
		packingType: "Jenis Pembungkusan",
		importedBy: "Diimport Oleh",
		manufacturedBy: "Dibuat Oleh",
		country: "Negara",
		descriptionLabel: "Penerangan",
		btnLabel: "Tempah Sekarang",
		inStock: "Stok Tersedia",
		outStock: "Stok Habis",
		description: "Penerangan",
		inventory: "Inventori",
		unpublished: "Belum Diterbitkan",
		published: "Diterbitkan",
		edit: "Edit",
		delete: "Padam",
		deleteText: "Adakah anda pasti untuk memadam produk ini?",
		myProfit: "Keuntungan Saya",
		buyerProfit: "Keuntungan Pembeli",
		skuId: "ID SKU",
		searchTram: "Cari Tram",
	},
	brandList: {
		pageTitle: "Senarai Jenama Saya",
		searchBrand: "Cari Jenama Mengikut Nama",
		myBrand: "Jenama Saya",
		allBrand: "Semua Jenama",
		createNewBrand: "Cipta Jenama Baru Saya",
		importBrand: "Import Jenama Lain",
		brandList: "Senarai Jenama",
	},
	brandDetail: {
		pageTitle: "Butiran Jenama",
		sku: "SKU",
		import: "Import",
		edit: "Edit",
		delete: "Padam",
		deleteConformationAsk: "Adakah anda pasti untuk memadam jenama ini?",
	},
	createNewBrand: {
		pageTitle: "Cipta Jenama Baru",
		uploadNewLogo: "Muat naik logo baru",
		brandName: "Nama Jenama",
		brandNamePlaceHolder: "sila masukkan nama jenama anda",
		selectCountry: "Pilih Negara",
		description: "Penerangan",
		descriptionPlaceHolder: "sila masukkan penerangan jenama anda",
		createdBy: "Dicipta oleh",
		complete: "Selesai",
		congratulation: "Tahniah",
		congratulationParagraph: "Cipta Jenama Baru berjaya",
		continue: "Teruskan",
		conformModal: "Adakah anda pasti ingin mencipta jenama ini?",
		importBrandConformations:
			"Adakah anda pasti ingin mengimport jenama ini?",
		errorMessage: "Anda tidak boleh muat naik imej melebihi 1 MB",
		imageFormatError:
			"Format Imej Tidak Sah. Hanya JPEG, PNG, dan JPG dibenarkan",
		ownerName: "Pemilik sah jenama",
		ownerNamePlaceholder: "Masukkan nama pemilik sah",
		isLegalOwner: "Syarikat Saya Memiliki Sah Jenama Ini",
		ownerNumber: "Nombor Telefon",
		ownerNumberPlaceholder: "sila masukkan nombor telefon anda",
	},
	updateBrand: {
		pageTitle: "Kemaskini Jenama",
		congratulationParagraph: "Kemaskini jenama berjaya",
		conformModal: "Adakah anda pasti ingin mengemaskini jenama ini?",
	},
	createProduct: {
		pageTitle: "Cipta Produk",
		productWarning: "Tajuk Produk akan dijana secara automatik di sini.",
		productPhoto: "Foto Produk",
		productPhotoUpload: "Muat Naik Foto Produk",
		uploadPhoto: "Muat Naik",
		uploadFromGallery: "Muat Naik Dari Galeri",
		takePhoto: "Ambil Gambar",
		productName: "Nama Produk",
		productNamePlaceHolder: "masukkan nama produk anda",
		description: "Penerangan",
		descriptionPlaceHolder: "masukkan penerangan produk anda",
		selectBrand: "Pilih Jenama",
		selectDepartment: "Pilih jabatan",
		selectCategory: "Pilih kategori",
		selectSubCategory: "Pilih sub kategori",
		setPrice: "Set Harga",
		sizeDetermine: "Tentukan Saiz",
		inventoryDetermine: "Tentukan Inventori",
		manufacturingDate: "Tarikh Pengilangan",
		expireData: "Tarikh Tamat",
		ManufacturerInformation: "Maklumat Pengilang",
		save: "Simpan",
		publish: "Terbitkan",
		purchasePrice: "Harga Pengguna",
		purchasePricePlaceHolder: "tulis harga pengguna",
		sellingPrice: "Harga Jualan",
		sellingPricePlaceHolder: "tulis harga jualan",
		priceWarning: "Harga anda kosong",
		customerPrice: "Harga Pembeli",
		customerPricePlaceHolder: "tulis harga pengedar",
		taxVat: "CUKAI/VAT",
		moq: "Kuantiti Pesanan Minimum (MOQ)",
		taxVatPlaceHolder: "tulis CUKAI/VAT",
		moqPlaceHolder: "tulis Harga MOQ",
		weight: "Berat",
		grossWeight: "Berat Kotor",
		grossWeightPlaceholder: "tulis berat kotor",
		weightPlaceholder: "tulis berat",
		weightWarning: "berat kosong",
		measurement: "Ukuran",
		weightUnits: "Unit Berat",
		units: "Unit",
		packingType: "Jenis Pembungkusan",
		ok: "OK",
		manufacturedCountry: "Negara Pembuatan",
		manufacturedBy: "Dibuat Oleh",
		manufacturedByPlaceHolder: "masukkan Nama Pembuat",
		importedBy: "Diimport Oleh",
		importedByPlaceHolder: "masukkan nama pengimport",
		distributedBy: "Diagihkan Oleh",
		distributedByPlaceHolder: "masukkan nama pengedaran",
		initialInventory: "Inventori Awal",
		initialInventoryPlaceHolder: "Tulis inventori awal",
		skuPlaceholder: "Tulis bilangan SKU",
		sku: "SKU",
		empty: "Kosong",
		barCode: "Kod Bar",
		scan: "Imbas",
		width: "Lebar",
		widthPlaceholder: "Tulis lebar",
		height: "Tinggi",
		heightPlaceholder: "Tulis tinggi",
		length: "Panjang",
		lengthPlaceholder: "Tulis panjang",
		searchDepartment: "Cari Jabatan Mengikut Nama",
		searchCategory: "Cari kategori mengikut nama",
		searchSubCategory: "Cari sub kategori mengikut nama",
		conformModal: "Adakah anda pasti ingin mencipta produk ini?",
		updateConformModal: "Adakah anda pasti ingin mengemaskini produk ini?",
		saveConformModal: "Adakah anda pasti ingin menyimpan produk ini?",
		updateBtn: "Kemaskini",
		updateMessage:
			"Nama produk, harga, dan stok mesti ada untuk diterbitkan.",
		publishMessage: "Adakah anda pasti ingin menerbitkan produk ini?",
		unPublishMessage: "Adakah anda pasti ingin tidak menerbitkan produk ini?",
		englishName: "Nama Inggeris untuk Carian",
		englishNamePlaceholder: "tulis nama carian",
		productCreated: "Produk berjaya dicipta!",
		finalGrossWeight: "Berat Penghantaran Akhir",
		grossWeightUnit: "Unit Berat Keseluruhan",
		productUpdated: "Produk Berjaya Dikemaskini!",
	},
	updateProduct: {
		pageTitle: "Kemaskini Produk",
	},
	createPack: {
		pageTitle: "Cipta Pek",
		packWarning: "Tajuk Pek akan dijana secara automatik di sini.",
		packName: "Nama Pek",
		packNamePlaceHolder: "Sila tulis nama pek anda",
		description: "Penerangan",
		descriptionPlaceHolder: "Sila tulis penerangan",
		selectProduct: "Produk Dipilih",
		qtyPack: "Kuantiti dalam Pek",
		qtyPackPlaceholder: "tulis kuantiti dalam pek",
		conformModal: "Adakah anda pasti ingin mencipta pek ini?",
		saveConformModal: "Adakah anda pasti ingin menyimpan pek ini?",
		productCreated: "Pek berjaya dicipta!",
		productUpdated: "Pek berjaya dikemaskini!",
	},
	createOffer: {
		pageTitle: "Cipta Tawaran",
		offerWarning: "Tajuk Tawaran akan dijana secara automatik di sini.",
		offerName: "Nama Tawaran",
		offerNamePlaceHolder: "Sila tulis nama tawaran anda",
		description: "Penerangan",
		descriptionPlaceHolder: "Sila tulis penerangan",
		selectProduct: "Produk Dipilih",
		qtyOffer: "Kuantiti dalam Tawaran",
		qtyOfferPlaceholder: "tulis kuantiti dalam tawaran",
		conformModal: "Adakah anda pasti ingin mencipta tawaran ini?",
		saveConformModal: "Adakah anda pasti ingin menyimpan tawaran ini?",
		offerStartDate: "Tarikh Mula Tawaran",
		offerEndDate: "Tarikh Akhir Tawaran",
		selectedItem: "Produk Dipilih",
		totalProductCount: "Jumlah Produk",
		totalAmount: "Jumlah Jumlah",
		spWarnings: "Anda mesti memilih produk",
		closeButton: "Tutup",
		offerCreated: "Tawaran berjaya dicipta!",
		offerUpdated: "Tawaran berjaya dikemaskini!",
	},
	updateOffer: {
		pageTitle: "Kemaskini Tawaran",
		conformModal: "Adakah anda pasti ingin mengemaskini tawaran ini?",
	},
	dashboard: {
		statusLable: "Status Pesanan",
		today: "Hari Ini",
		yesterday: "Semalam",
		thisWeek: "Minggu Ini",
		thisMonth: "Bulan Ini",
		thisYear: "Tahun Ini",
		lastWeek: "Minggu Lalu",
		lastMonth: "Bulan Lalu",
		lastYear: "Tahun Lalu",
		custom: "Hari Kustom",
		selectDay: "Pilih Hari",
		Order: "Pesanan",
		totalOrder: "Jumlah Pesanan",
		placed: "Ditempatkan",
		accepted: "Diterima",
		scDelivered: "Dijadualkan Untuk Dihantar",
		partialDelivered: "Sebahagian Telah Dihantar",
		delivered: "Dihantar",
		return: "Pulangkan Produk",
		partialPaid: "Sebahagian Dibayar",
		paid: "Dibayar",
		totalPaid: "Jumlah Dibayar",
		cancel: "Batal",
		deliveryFailed: "Penghantaran Gagal",
		orderAmount: "Jumlah Pesanan",
		totalAmount: "Jumlah Keseluruhan",
		collectedAmount: "Jumlah Dikumpulkan",
		dueAmount: "Jumlah Tertunggak",
		totalDue: "Jumlah Tertunggak",
		directOrder: "Pesanan Langsung",
		directOrderAmount: "Jumlah Pesanan Langsung",
		ekkHeroOrder: "Pesanan EkkHero",
		ekkHeroOrderAmount: "Jumlah Pesanan EkkHero",
		cancelOrderAmount: "Jumlah Pesanan Dibatalkan",
		seeDetails: "Lihat Butiran",
		period: "Tempoh",
		perviousWeek: "Minggu Sebelumnya",
		nextWeek: "Minggu Hadapan",
		supplierOrder: "Pesanan Pembekal",
		customerOrder: "Pesanan Pelanggan",
	},
	newCustomer: {
		pageTitle: "Cipta Pengedar Baru",
		mobileNo: "Nombor Telefon Bimbit",
		customerNoHolder: "Nombor telefon bimbit pengedar",
		customerName: "Nama Pengedar",
		customerNameHolder: "Masukkan nama penuh pengedar",
		businessPhoto: "Foto Perniagaan",
		uploadPhoto: "Muat Naik Foto Perniagaan/Kedai",
		uploadPhotoTitle: "Muat Naik Foto",
		uploadFromGallery: "Muat Naik Dari Galeri",
		takePhoto: "Ambil Gambar",
		businessName: "Nama Perniagaan",
		businessNameHolder: "masukkan nama perniagaan/kedai",
		address: "Alamat",
		addressHolder: "masukkan alamat perniagaan/kedai",
		city: "Bandar",
		cityHolder: "masukkan bandar",
		postalCode: "Poskod",
		postalCodeHolder: "masukkan poskod",
		selectBusiness: "Pilih Jenis Perniagaan",
		fmcg: "FMCG",
		nonFmcg: "Bukan FMCG",
		nextBtn: "Seterusnya",
		completeBtn: "Selesai",
		customerFindText:
			"Pengedar sudah ada di sini, jika anda mahu anda boleh tambah ke “Pengedar Diterima”. Dan jika anda mahu, anda boleh mencipta Pengedar baru dengan mengklik butang hijau.",
		addBusiness: "Tambah Perniagaan",
		addBusinessInfo:
			"Adakah anda mahu menambahkan perniagaan ini sebagai Pengedar Saya?",
		noBtn: "Tidak",
		alreayExist: "Pengedar sudah wujud!",
		reseller: "Pengedar",
		businessNameWarning: "Sila Tambah Nama Perniagaan",
		distributorNameWarning: "Sila Tambah Nama Pengedar",
		businessCreationSuccess: "Perniagaan Baru Berjaya Dicipta",
		businessLocationWarning: "Sila Tambah Lokasi Perniagaan dari Peta Google",
	},
	kycVerification: {
		imageWarning: "Anda tidak boleh muat naik imej lebih daripada 1 MB",
		imageUpload: "Muat naik imej anda di sini, atau melayari",
		nidFront: "NID - Sisi Depan",
		nidBack: "NID - Sisi Belakang",
		uploadNidFront: "Muat Naik NID `Sisi Depan`",
		uploadNidBack: "Muat Naik NID `Sisi Belakang`",
		complete: "Muatnaik selesai",
		pleaseCheck: "Sila Semak",
		next: "SETERUSNYA",
		button: "butang",
		upload: "Sila, muat naik",
		nid: "NID",
		copy: "salinan",
		tradeLicense: "Lesen Dagangan",
		uploadTradeLicense: "Muat Naik “Lesen Dagangan”",
		giveLater: "Saya akan memberikan maklumat ini kemudian",
		propertyType: "Jenis Harta",
		rented: "Sewa",
		own: "Sendiri",
		rentalAgreement: "Perjanjian Sewa/ Perjanjian dengan Pemilik",
		ownAgreement: "Perjanjian/ Sijil Kepemilikan",
		rentalDocument: "Dokumen Perjanjian Sewa",
		ownDocument: "Dokumen Harta Sendiri",
		startDate: "Tarikh Mula Sewa",
		rentPerMonth: "Sewa sebulan",
		rentPerMonthPlaceholder: "tulis sewa kedai anda sebulan",
		select: "Sila, pilih",
		fillup: "& isi",
		amount: "jumlah",
		click: "Sila klik",
		submit: "HANTAR",
	},
	notification: {
		pageTitle: "Pemberitahuan",
		customer: "Pelanggan",
		supplier: "Pembekal",
		update: "Kemaskini",
		supllierPageTitle: "Pemberitahuan dari Pembekal",
	},
	paymentSettings: {
		pageTitle: "Tetapan Terma Pembayaran",
		mainTitle: "Pilih Terma Pembayaran yang Sesuai",
		subTitle:
			"Terma pembayaran yang anda pilih di sini, pelanggan hanya boleh menggunakan Terma Pembayaran ini semasa membuat pesanan.",
		btnLabel: "Simpan",
		payNow: "Bayar Sekarang",
		cashOnDelivery: "Bayar Tunai Semasa Penghantaran",
		pickAndPay: "Ambil dan Bayar",
		today: "Pada Malam Hari Penghantaran",
		threeDays: "Bayar dalam 3 hari",
		sevenDays: "Bayar dalam 7 hari",
		fourteenDays: "Bayar dalam 14 hari",
		thirtyDays: "Bayar dalam 30 hari",
	},
	orderSummary: {
		orderProductPageTitle: "Ringkasan Pesanan Produk",
		orderProductDetailsPageTitle: "Butiran Ringkasan Pesanan Produk",
		driverProductPageTitle: "Ringkasan Pesanan Produk Pemandu",
		orderDriverProductDrilldown: "Butiran Ringkasan Pesanan Produk Pemandu",
		driverDeliveryPageTitle: "Ringkasan Status Penghantaran Pemandu",
		deliveryAmountPageTitle: "Ringkasan Jumlah Penghantaran Pesanan",
		orderDeliveryAmountDrilldown: "Butiran Jumlah Penghantaran Pesanan",
		buyerSummary: "Ringkasan Pembeli",
		buyerSummaryDrillDown: "Butiran Ringkasan Pembeli",
		delayInPayment: "Lengah Dalam Pembayaran",
		delayInPaymentDetails: "Butiran Lengah Dalam Pembayaran",
		productTitle: "Tajuk Produk",
		qty: "Kuantiti",
		orderNo: "Nombor Pesanan",
		date: "Tarikh",
		status: "Status",
		product: "Produk",
		driver: "Pemandu",
		searchPlaceholder: "Cari Pemandu",
		totalAmount: "Jumlah Jumlah",
		totalPaid: "Jumlah Dibayar",
		totalDue: "Jumlah Tertunggak",
		numberOfOrders: "Jumlah Pesanan",
		deliveryFailed: "Penghantaran Gagal",
		gvmAmount: "GMV",
		numberOfFulfillment: "Pemenuhan",
		orderCancel: "Batal Pesanan",
		revenue: "Pendapatan",
		dueAmount: "Jumlah Tertunggak",
		dayPassed: "Hari Berlalu",
		storeName: "Nama Kedai",
		selectPlaceholder: "Pilih Tarikh",
		amount: "Jumlah",
		orderAmount: "Jumlah Pesanan",
	},
	autoLogOut: {
		pageTitle: "Log Keluar Automatik",
		warningMsg:
			"Log keluar automatik kerana nombor ini digunakan dalam Log Masuk lain",
		instrMsg: "Klik butang hijau di bawah untuk log masuk semula",
		btnLabel: "Log masuk",
	},
	customerOrder: {
		customerOrderAmount: "Jumlah Pesanan Pengedar",
	},
	addBankSettings: {
		bottomSheetTitle: "Tambah Akaun Bank Anda",
		pageTitle: "Tambah Akaun Bank Anda",
		accountName: "Nama Akaun",
		accountNamePlaceholder: "Masukkan nama akaun anda",
		accountNumber: "Nombor Akaun",
		accountNumberPlaceholder: "Masukkan nombor akaun anda",
		selectBank: "Pilih Bank",
		selectBankPlaceHolder: "Pilih akaun bank anda",
		branch: "Cawangan",
		branchPlaceHolder: "Masukkan cawangan bank anda",
		address: "Alamat",
		addressPlaceholder: "Masukkan alamat bank anda",
		selectCountry: "Pilih Negara",
		selectCountryPlaceHolder: "Pilih negara bank anda",
		selectCity: "Pilih Bandar",
		selectCityPlaceHolder: "Pilih bandar",
		postalCode: "Poskod",
		postalCodePlaceHolder: "Masukkan poskod bank anda",
		buttonTitle: "Simpan",
		bankNameWarning: "Sila masukkan nama akaun bank anda",
		bankNumberWarning: "Sila masukkan nombor akaun bank anda",
		selectBankWarning: "Sila pilih bank",
		branchWarning: "Sila masukkan cawangan bank anda",
		addressWarning: "Sila masukkan alamat anda",
		cityWarning: "Sila masukkan bandar anda",
		searchBank: "Cari bank anda",
	},
	addedBankList: {
		pageTitle: "Senarai Bank",
		acName: "Nama A/C",
		acNumber: "Nombor A/C",
		branch: "Cawangan",
		address: "Alamat",
		city: "Bandar",
		country: "Negara",
		addBank: "Tambah Akaun Bank Anda",
	},
	defaultPayment: {
		menuTitle: "Pembayaran Lalai",
		pageTitle: "Pembayaran Lalai",
		pageHeadline:
			"Pilih dan nyatakan untuk pengedar ini tetapan Terma Pembayaran dan Metode yang sesuai untuk Membuat Pesanan Baz AI",
		pageSubHeadline:
			"Terma pembayaran & kaedah yang anda pilih di sini, pengedar hanya boleh menggunakan terma pembayaran & kaedah ini semasa membuat pesanan dengan Baz AI",
		paymentTermLabel: "Terma Pembayaran",
		paymentTermPlaceHolder: "Pilih terma pembayaran",
		paymentMethodLabel: "Kaeddah Pembayaran",
		paymentMethodPlaceHolder: "Pilih bank",
		successMessage: "Pembayaran Lalai Berjaya Ditambah",
		successMessage2: "Pembayaran Lalai Berjaya Dikemaskini",
	},
	blackListed: {
		pageTitle: "Diblacklist",
		warningMsg: "Perniagaan Anda Diblacklist",
		instrMsg:
			"Sila minta pemilik perniagaan anda untuk menghubungi EkkBaz untuk tindakan yang diperlukan untuk mengaktifkan semula perniagaan anda.",
		btnLabel: "Log keluar",
	},
	payment: {
		pageTitle: "Bayar Ekkbaz",
		bank: "Bank",
		acName: "Nama A/C",
		acNumber: "Nombor A/C",
		branch: "Cawangan",
		address: "Alamat",
		city: "Bandar",
		postal: "Poskod",
		country: "Negara",
		selectTimeDate: "Pilih Masa & Tarikh",
		transitionId: "ID Transaksi",
		transitionIdPlaceHolder: "masukkan ID transaksi anda",
		amount: "Jumlah",
		amountPlaceHolder: "masukkan jumlah yang anda hantar",
		uploadReceipt: "Muat Naik Resit",
		senderInfo: "Maklumat Penghantar",
		receiverInfo: "Maklumat Penerima",
		senderWarning: "Sila tambah akaun bank anda dahulu",
		paymentConformationMessage:
			"Adakah anda pasti ingin menghantar pembayaran ini?",
		inputWarning: "Sila isi medan ini",
		congressPayment: "Tahniah! Pembayaran anda telah berjaya dihantar.",
		paymentRecieptImage: "Imej Resit Pembayaran",
		receiptOrBank: "Muat naik imej resit atau slip bank",
		warning: "Jumlah bayaran adalah salah",
	},
	industryType: {
		pageTitle: "Industri Bekerja",
		title: "Sila pilih industri kerja anda",
	},
	driectSales: {
		pageTitle: "Jualan Langsung",
		customer: "Senarai Pelanggan",
		orderList: "Senarai Pesanan",
		searchCustomer: "Cari Pelanggan",
		ownCustomer: "Pelanggan Sendiri",
		allCustomer: "Semua Pelanggan",
		ownCustomerSearch: "Cari Pelanggan Sendiri",
		allCustomerSearch: "Cari Semua Pelanggan",
	},
	invitedDistributor: {
		pageTitle: "Senarai Pengedar Jemputan",
		searchPlaceHolder: "tulis nama pengedar",
		invitedDistributor: "Tambah pengedar ini?",
		invited: "Dijemput",
		inviteApprove: "Jemputan berjaya diterima",
	},
	addCard: {
		pageTitle: "Tambah Kad",
		addedList: "Senarai Kad Ditambah",
		addNewCard: "Tambah Kad Baru",
		continue: "Teruskan",
	},
	paymentModule: {
		pageTitle: "Pilih Pelan Peningkatan",
		upgradeNow: "Tingkatkan Sekarang",
		monthlyPlan: "Pelan Bulanan",
		annualPlan: "Pelan Tahunan",
		card: "Kad",
		paymentMethod: "Kaedah Pembayaran",
		continue: "Teruskan",
		selectedMethod: "Sila pilih kaedah pembayaran..!",
		checkOut: "Semak Keluar",
		paymentCard: "Kad Pembayaran",
		changeCard: "Tukar Kad",
		selectPackage: "Pilih Pakej",
		orderSummary: "Ringkasan Pesanan",
		subtotal: "Jumlah Kecil",
		total: "Jumlah",
		payNow: "Bayar Sekarang",
		successTitle: "Pembayaran Berjaya",
		successText: "Terima kasih atas pembayaran anda",
		yourPlan: "Pelan Langganan Anda",
		transactionHistory: "Sejarah Transaksi",
		cardNumber: "Nombor Kad",
		status: "Status",
		invoice: "Invois",
		newCard: "Tambah kad baru",
		couponDiscount: "Diskaun kupon",
		discount: "Diskaun",
		getDiscount: "Dapatkan diskaun",
		noAvailableCard: "Tiada kad yang tersedia",
		noTransaction: "Anda tidak mempunyai transaksi sebelumnya",
		enterCouponCode: "Masukkan kod kupon",
	},
};
export default malaysia;
