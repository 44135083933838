import React from "react";

export default function Linkdin() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<path
				d='M5 18.75H1.25V6.25H5V18.75ZM18.75 18.75H15V12.0725C15 10.3325 14.38 9.46625 13.1512 9.46625C12.1775 9.46625 11.56 9.95125 11.25 10.9225C11.25 12.5 11.25 18.75 11.25 18.75H7.5C7.5 18.75 7.55 7.5 7.5 6.25H10.46L10.6887 8.75H10.7662C11.535 7.5 12.7638 6.6525 14.4487 6.6525C15.73 6.6525 16.7662 7.00875 17.5575 7.90375C18.3538 8.8 18.75 10.0025 18.75 11.6912V18.75Z'
				fill='black'
				fillOpacity='0.3'
			/>
			<path
				d='M3.12494 5C4.19499 5 5.06244 4.16053 5.06244 3.125C5.06244 2.08947 4.19499 1.25 3.12494 1.25C2.05489 1.25 1.18744 2.08947 1.18744 3.125C1.18744 4.16053 2.05489 5 3.12494 5Z'
				fill='black'
				fillOpacity='0.3'
			/>
		</svg>
	);
}
