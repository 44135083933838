import { createSlice } from "@reduxjs/toolkit";
// import { shoppingCart } from "helpers/shoppingCart";

export const dashboardSlice = createSlice({
	name: "dashboard",
	initialState: {
		dateInfo: {},
	},
	reducers: {
		getSelectedDate: (state, action) => {
			state.dateInfo = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { getSelectedDate } = dashboardSlice.actions;
export const { actions, reducer } = dashboardSlice;
export default reducer;
