import {
	IonApp,
	IonButton,
	IonCol,
	IonContent,
	IonGrid,
	IonPage,
} from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function PaymentSuccessPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<IonGrid>
						<IonCol size='12'>
							<div className='flex items-center justify-center text-center h-screen'>
								<div>
									<img
										src='/assets/images/winner.png'
										alt='congratulation'
										className='inharit m-auto'
									/>
									<h2 className='text-30 text-success font-bold mb-2 pt-3'>
										{ekkLanguage.paymentModule?.successTitle}
									</h2>
									<p className='text-20 text-black-500 font-semibold mb-5'>
										{ekkLanguage.paymentModule?.successText}
									</p>

									<Link to='/dashboard' className='w-full '>
										<IonButton
											className='rounded-[10px] h-12 mt-20 w-full text-white font-bold bg-success '
											style={{
												boxShadow:
													"0px 5px 15px rgba(0, 0, 0, 0.2)",
											}}>
											{ekkLanguage.createOrder?.congressProductLabel}
										</IonButton>
									</Link>
								</div>
							</div>
						</IonCol>
					</IonGrid>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
