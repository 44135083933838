import React from "react";

export default function Minus() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='2'
			viewBox='0 0 12 2'
			fill='none'>
			<path
				d='M10.4133 0.125H1.58672C1.12461 0.125 0.75 0.516016 0.75 1C0.75 1.48398 1.12461 1.875 1.58672 1.875H10.4133C10.8754 1.875 11.25 1.48398 11.25 1C11.25 0.516016 10.8754 0.125 10.4133 0.125Z'
				fill='white'
			/>
		</svg>
	);
}
