import { IonApp, IonContent, IonPage } from "@ionic/react";
import LocationEnabled from "components/mobile-components/Business/LocationEnabled";
import React from "react";

export default function LocationPage() {
	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<LocationEnabled />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
