import React from "react";

export default function Car() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='14'
			viewBox='0 0 14 14'
			fill='none'>
			<path
				d='M8.75 10.5H2.33333C1.71449 10.5 1.121 10.2542 0.683418 9.81658C0.245833 9.37899 0 8.7855 0 8.16666V3.49999C0 2.72645 0.307291 1.98458 0.854272 1.4376C1.40125 0.890619 2.14312 0.583328 2.91667 0.583328H5.83333C6.21636 0.583328 6.59563 0.65877 6.94949 0.805346C7.30336 0.951922 7.62489 1.16676 7.89573 1.4376C8.16657 1.70844 8.38141 2.02997 8.52798 2.38383C8.67456 2.7377 8.75 3.11697 8.75 3.49999V10.5ZM14 6.41666V5.83333C14 5.45031 13.9246 5.07103 13.778 4.71717C13.6314 4.3633 13.4166 4.04177 13.1457 3.77093C12.8749 3.5001 12.5534 3.28526 12.1995 3.13868C11.8456 2.9921 11.4664 2.91666 11.0833 2.91666H9.91667V6.41666H14ZM9.91667 7.58333V10.5H11.6667C12.2855 10.5 12.879 10.2542 13.3166 9.81658C13.7542 9.37899 14 8.7855 14 8.16666V7.58333H9.91667ZM2.36717 11.6667C2.3458 11.7624 2.33446 11.8602 2.33333 11.9583C2.33333 12.3451 2.48698 12.716 2.76047 12.9895C3.03396 13.263 3.40489 13.4167 3.79167 13.4167C4.17844 13.4167 4.54937 13.263 4.82286 12.9895C5.09635 12.716 5.25 12.3451 5.25 11.9583C5.24887 11.8602 5.23754 11.7624 5.21617 11.6667H2.36717ZM8.78383 11.6667C8.76246 11.7624 8.75113 11.8602 8.75 11.9583C8.75 12.3451 8.90365 12.716 9.17714 12.9895C9.45063 13.263 9.82156 13.4167 10.2083 13.4167C10.5951 13.4167 10.966 13.263 11.2395 12.9895C11.513 12.716 11.6667 12.3451 11.6667 11.9583C11.6655 11.8602 11.6542 11.7624 11.6328 11.6667H8.78383Z'
				fill='#808080'
			/>
		</svg>
	);
}
