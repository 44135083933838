import React from "react";

export default function Menu({ color = "white" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='30'
			height='33'
			viewBox='0 0 30 33'
			style={{ margin: "auto" }}
			fill='none'>
			<path
				d='M25.5377 15.8109H3.71271C3.31921 15.8109 3.00021 16.1033 3.00021 16.464V17.2202C3.00021 17.5809 3.31921 17.8733 3.71271 17.8733H25.5377C25.9312 17.8733 26.2502 17.5809 26.2502 17.2202V16.464C26.2502 16.1033 25.9312 15.8109 25.5377 15.8109Z'
				fill={color}
			/>
			<path
				d='M25.5377 22.6855H3.71271C3.31921 22.6855 3.00021 22.9779 3.00021 23.3386V24.0948C3.00021 24.4555 3.31921 24.7479 3.71271 24.7479H25.5377C25.9312 24.7479 26.2502 24.4555 26.2502 24.0948V23.3386C26.2502 22.9779 25.9312 22.6855 25.5377 22.6855Z'
				fill={color}
			/>
			<path
				d='M25.5377 8.24925H3.71271C3.31921 8.24925 3.00021 8.54164 3.00021 8.90233V9.65852C3.00021 10.0192 3.31921 10.3116 3.71271 10.3116H25.5377C25.9312 10.3116 26.2502 10.0192 26.2502 9.65852V8.90233C26.2502 8.54164 25.9312 8.24925 25.5377 8.24925Z'
				fill={color}
			/>
		</svg>
	);
}
