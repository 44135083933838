import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function NagadDetails() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='pt-5'>
			<Card title={ekkLanguage.businessProfile.nagadInfoLabel}>
				<div className='px-2'>
					<table className='table-fixed w-full'>
						<tbody>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.businessProfile.nagadAcName}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									MD ZAHIDUL HOSSAIN
								</td>
							</tr>
							<tr>
								<td className='text-000080 text-12 font-medium py-[7px]'>
									{ekkLanguage.businessProfile.nagadAcNo}
								</td>
								<td className='text-000080 text-12 font-medium py-[7px] text-right'>
									01725634879
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Card>
		</div>
	);
}
