import React from "react";

export default function BpPayment() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'>
			<circle cx='20' cy='20' r='20' fill='#36A94D' fillOpacity='0.15' />
			<path
				d='M25.0487 28.6361C23.2716 28.6057 21.5064 28.3409 19.7987 27.8486C17.5984 27.1803 15.2848 26.9689 12.9999 27.2273C11.8795 27.3637 10.769 27.5712 9.6749 27.8486C9.54576 27.8813 9.41084 27.8842 9.28043 27.8569C9.15002 27.8296 9.02756 27.7729 8.9224 27.6911C8.82451 27.6052 8.74701 27.4986 8.69554 27.3791C8.64408 27.2595 8.61994 27.1299 8.6249 26.9998V12.9998C8.61908 12.7999 8.68189 12.6041 8.80289 12.4449C8.92389 12.2857 9.09576 12.1727 9.2899 12.1248C10.5662 11.8051 11.8649 11.5828 13.1749 11.4598C15.531 11.2373 17.9079 11.472 20.1749 12.1511C22.2744 12.7817 24.4764 12.9987 26.6587 12.7898C27.884 12.6592 29.0978 12.4369 30.2899 12.1248C30.419 12.092 30.554 12.0892 30.6844 12.1165C30.8148 12.1438 30.9372 12.2005 31.0424 12.2823C31.1503 12.3674 31.2366 12.4767 31.2943 12.6014C31.3521 12.726 31.3797 12.8625 31.3749 12.9998V26.9998C31.3807 27.1997 31.3179 27.3955 31.1969 27.5547C31.0759 27.714 30.904 27.8269 30.7099 27.8748C29.5418 28.1732 28.355 28.3925 27.1574 28.5311C26.4571 28.6061 25.753 28.6412 25.0487 28.6361ZM14.9512 25.3636C16.7282 25.3939 18.4934 25.6587 20.2012 26.1511C22.3875 26.8134 24.6856 27.0247 26.9562 26.7723C27.8394 26.6686 28.7159 26.5138 29.5812 26.3086V14.1023C28.6713 14.2977 27.7511 14.4408 26.8249 14.5311C24.4566 14.7673 22.065 14.5445 19.7812 13.8748C17.6964 13.2453 15.5095 13.0255 13.3412 13.2273C12.3432 13.3162 11.3523 13.4711 10.3749 13.6911V25.9411C11.19 25.765 12.0136 25.6307 12.8424 25.5386C13.5414 25.4401 14.2455 25.3817 14.9512 25.3636Z'
				fill='#36A94D'
			/>
			<path
				d='M9.82363 17.3748H9.42988C9.21016 17.3572 9.00518 17.2572 8.85594 17.095C8.70669 16.9328 8.62418 16.7202 8.62488 16.4998V12.9998C8.61906 12.7999 8.68187 12.6041 8.80287 12.4449C8.92387 12.2856 9.09574 12.1727 9.28988 12.1248C10.5662 11.8051 11.8649 11.5828 13.1749 11.4598C13.3849 11.4391 13.5953 11.495 13.7674 11.6172C13.9394 11.7394 14.0615 11.9197 14.1111 12.1248C14.1721 12.4124 14.2014 12.7058 14.1986 12.9998C14.1986 14.1601 13.7377 15.2729 12.9172 16.0934C12.0968 16.9138 10.984 17.3748 9.82363 17.3748ZM10.3749 13.691V15.5635C10.9103 15.4487 11.3963 15.1693 11.765 14.7645C12.1338 14.3597 12.3667 13.8498 12.4311 13.306C11.7924 13.4023 11.1099 13.5248 10.3749 13.691Z'
				fill='#36A94D'
			/>
			<path
				d='M30.2198 17.375C29.2098 17.3773 28.23 17.0299 27.4469 16.3919C26.6638 15.754 26.1256 14.8647 25.9236 13.875C25.8995 13.7453 25.9051 13.6117 25.94 13.4845C25.9749 13.3572 26.038 13.2394 26.1248 13.14C26.1971 13.0424 26.2888 12.9608 26.3943 12.9006C26.4997 12.8403 26.6165 12.8027 26.7373 12.79C27.9362 12.6556 29.1235 12.4333 30.2898 12.125C30.419 12.0923 30.5539 12.0894 30.6843 12.1167C30.8147 12.144 30.9372 12.2007 31.0423 12.2825C31.1502 12.3676 31.2365 12.4769 31.2943 12.6016C31.352 12.7262 31.3796 12.8627 31.3748 13V16.5C31.3779 16.7085 31.3064 16.9112 31.1732 17.0716C31.0401 17.232 30.854 17.3396 30.6486 17.375C30.5062 17.3926 30.3622 17.3926 30.2198 17.375ZM27.9973 14.4C28.3666 14.9843 28.9514 15.3993 29.6248 15.555V14.1025C29.0648 14.2163 28.5223 14.3213 27.9973 14.4Z'
				fill='#36A94D'
			/>
			<path
				d='M9.5 27.875C9.26794 27.875 9.04538 27.7828 8.88128 27.6187C8.71719 27.4546 8.625 27.2321 8.625 27V23.5C8.625 23.2679 8.71719 23.0454 8.88128 22.8813C9.04538 22.7172 9.26794 22.625 9.5 22.625C10.5212 22.6263 11.5098 22.9849 12.2945 23.6385C13.0791 24.2921 13.6104 25.1996 13.7962 26.2038C13.8184 26.3222 13.8159 26.444 13.7888 26.5614C13.7617 26.6789 13.7106 26.7895 13.6387 26.8863C13.5667 26.9813 13.476 27.0606 13.3721 27.1192C13.2683 27.1779 13.1536 27.2148 13.035 27.2275C11.9146 27.3639 10.8041 27.5714 9.71 27.8487C9.64132 27.866 9.5708 27.8748 9.5 27.875ZM10.375 24.5238V25.8975C10.8475 25.8012 11.3025 25.7138 11.7488 25.6438C11.4345 25.1224 10.9489 24.7265 10.375 24.5238Z'
				fill='#36A94D'
			/>
			<path
				d='M27.0611 28.5137C26.8588 28.5187 26.6611 28.4534 26.5016 28.329C26.342 28.2045 26.2306 28.0286 26.1861 27.8313C26.1377 27.5569 26.1172 27.2784 26.1249 27C26.1249 25.8397 26.5858 24.7269 27.4063 23.9064C28.2267 23.0859 29.3395 22.625 30.4999 22.625C30.7319 22.625 30.9545 22.7172 31.1186 22.8813C31.2827 23.0454 31.3749 23.2679 31.3749 23.5V27C31.3807 27.1999 31.3179 27.3957 31.1969 27.5549C31.0759 27.7141 30.904 27.8271 30.7099 27.875C29.5418 28.1734 28.3549 28.3926 27.1574 28.5312L27.0611 28.5137ZM29.6249 24.5238C29.166 24.6806 28.7597 24.9615 28.4508 25.3353C28.1419 25.7091 27.9425 26.1611 27.8749 26.6413C28.4261 26.5538 29.0036 26.4487 29.6249 26.3087V24.5238Z'
				fill='#36A94D'
			/>
			<path
				d='M21.3125 23.5H18.25C18.0179 23.5 17.7954 23.4078 17.6313 23.2437C17.4672 23.0796 17.375 22.8571 17.375 22.625C17.375 22.3929 17.4672 22.1704 17.6313 22.0063C17.7954 21.8422 18.0179 21.75 18.25 21.75H21.3125C21.4285 21.75 21.5398 21.7039 21.6219 21.6219C21.7039 21.5398 21.75 21.4285 21.75 21.3125C21.75 21.1965 21.7039 21.0852 21.6219 21.0031C21.5398 20.9211 21.4285 20.875 21.3125 20.875H18.6875C18.1073 20.875 17.5509 20.6445 17.1407 20.2343C16.7305 19.8241 16.5 19.2677 16.5 18.6875C16.5 18.1073 16.7305 17.5509 17.1407 17.1407C17.5509 16.7305 18.1073 16.5 18.6875 16.5H21.75C21.9821 16.5 22.2046 16.5922 22.3687 16.7563C22.5328 16.9204 22.625 17.1429 22.625 17.375C22.625 17.6071 22.5328 17.8296 22.3687 17.9937C22.2046 18.1578 21.9821 18.25 21.75 18.25H18.6875C18.5715 18.25 18.4602 18.2961 18.3781 18.3781C18.2961 18.4602 18.25 18.5715 18.25 18.6875C18.25 18.8035 18.2961 18.9148 18.3781 18.9969C18.4602 19.0789 18.5715 19.125 18.6875 19.125H21.3125C21.8927 19.125 22.4491 19.3555 22.8593 19.7657C23.2695 20.1759 23.5 20.7323 23.5 21.3125C23.5 21.8927 23.2695 22.4491 22.8593 22.8593C22.4491 23.2695 21.8927 23.5 21.3125 23.5Z'
				fill='#36A94D'
			/>
			<path
				d='M20 18.25C19.7679 18.25 19.5454 18.1578 19.3813 17.9937C19.2172 17.8296 19.125 17.6071 19.125 17.375V16.5C19.125 16.2679 19.2172 16.0454 19.3813 15.8813C19.5454 15.7172 19.7679 15.625 20 15.625C20.2321 15.625 20.4546 15.7172 20.6187 15.8813C20.7828 16.0454 20.875 16.2679 20.875 16.5V17.375C20.875 17.6071 20.7828 17.8296 20.6187 17.9937C20.4546 18.1578 20.2321 18.25 20 18.25Z'
				fill='#36A94D'
			/>
			<path
				d='M20 24.375C19.7679 24.375 19.5454 24.2828 19.3813 24.1187C19.2172 23.9546 19.125 23.7321 19.125 23.5V22.625C19.125 22.3929 19.2172 22.1704 19.3813 22.0063C19.5454 21.8422 19.7679 21.75 20 21.75C20.2321 21.75 20.4546 21.8422 20.6187 22.0063C20.7828 22.1704 20.875 22.3929 20.875 22.625V23.5C20.875 23.7321 20.7828 23.9546 20.6187 24.1187C20.4546 24.2828 20.2321 24.375 20 24.375Z'
				fill='#36A94D'
			/>
		</svg>
	);
}
