// import Trash from "assets/images/icon/svg/Trash";
import React from "react";
import { useSelector } from "react-redux";
import "./style.scss";
import { useDropzone } from "react-dropzone";
import Pen from "assets/images/icon/svg/Pen";

export default function ImageUpload({
	uploadImage,
	setUploadImage,
	setErrorMessage,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const { getRootProps, getInputProps } = useDropzone({
		// accept: "image/jpeg, image/png, image/jpg",
		onDrop: (acceptedFiles) => {
			const file = acceptedFiles[0];

			if (
				file.type === "image/jpeg" ||
				file.type === "image/png" ||
				file.type === "image/jpg"
			) {
				if (file.size <= 1048576) {
					// 1MB = 1048576 bytes
					setUploadImage(
						acceptedFiles.map((file) =>
							Object.assign(file, {
								preview: URL.createObjectURL(file),
							})
						)
					);
					setErrorMessage(""); // Clear error message if previously shown
				} else {
					setErrorMessage(ekkLanguage.createNewBrand.errorMessage);
				}
			} else {
				setErrorMessage(ekkLanguage.createNewBrand.imageFormatError);
			}
		},
	});

	return (
		<div className='pt-4'>
			<div className='text-left  overflow-hidden'>
				<div className=' flex items-center justify-center bg-E6E6E6 h-[270px] relative'>
					{uploadImage[0]?.preview ? (
						<>
							<img src={uploadImage[0]?.preview} alt='avatar' />
							<div {...getRootProps({ className: "dropzone" })}>
								<input {...getInputProps()} />
								<span
									className='absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-2 top-2 border-4 border-white'
									style={{
										background: "#5A7EFF",
										lineHeight: "28px",
									}}
								>
									<Pen />
								</span>
							</div>
						</>
					) : (
						<div {...getRootProps({ className: "dropzone" })}>
							<input {...getInputProps()} />
							<div
								className='text-center rounded-[5px] inline-block bg-white px-9 py-5 cursor-pointer'
								style={{
									boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.07)",
								}}
							>
								<img
									className='m-auto pb-[10px]'
									src='/assets/images/Icon/add-image.png'
									alt='brand'
								/>
								<p className='text-14 pt-1 text-black-1000'>
									{ekkLanguage.createNewBrand.uploadNewLogo}
								</p>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
