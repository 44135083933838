import React from 'react'
import { useSelector } from 'react-redux';

export default function AuthLayout(props) {
    // const user = useSelector(state=>state.auth.data)
    // const theme = user?.profile?.theme;

    return (
        <>
            <section className="sign-in-page flex items-center justify-center bg-grey-dark h-screen relative" 
                style={{background:`url(/assets/images/authBg.png)`, 
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center', 
                height:'100vh',
                willChange: 'transform, opacity',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
                    }}
                >
                {props.children}
            </section>

        </>
    )
}