import React from "react";

export default function RightArrow() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='7'
			height='12'
			viewBox='0 0 7 12'
			fill='none'>
			<path
				d='M0.706 12L0 11.294L4.9405 6.35348C5.03424 6.25972 5.08689 6.13257 5.08689 5.99998C5.08689 5.8674 5.03424 5.74025 4.9405 5.64648L0.0085001 0.715484L0.7155 0.00848389L5.6465 4.93948C5.92771 5.22077 6.08568 5.60224 6.08568 5.99998C6.08568 6.39773 5.92771 6.77919 5.6465 7.06048L0.706 12Z'
				fill='#222222'
			/>
		</svg>
	);
}
