import { IonIcon, IonItem, IonTextarea } from "@ionic/react";
import Image from "assets/images/icon/svg/Image";
import Send from "assets/images/icon/svg/Send";
import React, { useState } from "react";

export default function TimeLineTextBox({ order }) {
	const [text, setText] = useState();
	return (
		<div className='pb-5 pt-3 px-4 bg-white timeline-textarea'>
			<div className='flex items-center'>
				<div className='w-10/12'>
					<IonItem lines='none'>
						<IonTextarea
							value={text}
							placeholder='Ac'
							onIonChange={(e) => setText(e.detail.value)}></IonTextarea>
						<IonIcon name='image' color='primary' slot='end'></IonIcon>
					</IonItem>
				</div>
				<div className='self-center w-2/12'>
					<Send />
				</div>
			</div>
		</div>
	);
}
