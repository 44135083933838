import React from "react";

export default function Twitter() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<path
				d='M19.5996 3.68276C18.8897 3.9983 18.1257 4.21115 17.3242 4.3064C18.1426 3.81643 18.7701 3.04032 19.0661 2.11447C18.3006 2.56877 17.4531 2.89842 16.5504 3.07638C15.828 2.30654 14.7983 1.8252 13.6588 1.8252C11.4712 1.8252 9.6975 3.59888 9.6975 5.78688C9.6975 6.09694 9.73239 6.39915 9.8002 6.6896C6.50761 6.52419 3.58819 4.94727 1.63419 2.55035C1.29317 3.13517 1.09797 3.81564 1.09797 4.54236C1.09797 5.91663 1.79725 7.1294 2.86029 7.83966C2.21118 7.81888 1.60009 7.64053 1.06583 7.34381C1.06544 7.36027 1.06544 7.37713 1.06544 7.39398C1.06544 9.31309 2.43108 10.9139 4.24357 11.2784C3.91117 11.3686 3.56114 11.4172 3.19974 11.4172C2.94417 11.4172 2.69605 11.3925 2.4542 11.3459C2.95867 12.9196 4.42152 14.0654 6.15484 14.0971C4.799 15.1598 3.09116 15.7932 1.23477 15.7932C0.91531 15.7932 0.599771 15.7744 0.289719 15.7376C2.04341 16.8621 4.12558 17.5175 6.36258 17.5175C13.6498 17.5175 17.6342 11.4811 17.6342 6.24589C17.6342 6.0742 17.6307 5.90291 17.6232 5.73279C18.397 5.1754 19.0688 4.4773 19.5996 3.68276Z'
				fill='black'
				fillOpacity='0.3'
			/>
		</svg>
	);
}
