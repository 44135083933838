import React from "react";

export default function Facebook() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='17'
			viewBox='0 0 20 17'
			fill='none'>
			<path
				d='M14.1859 5.57957H11.5035V3.90823C11.5035 3.28057 11.9414 3.13423 12.2498 3.13423C12.5575 3.13423 14.1428 3.13423 14.1428 3.13423V0.374901L11.5358 0.365234C8.64173 0.365234 7.98313 2.42323 7.98313 3.74023V5.57957H6.30945V8.4229H7.98313C7.98313 12.0719 7.98313 16.4686 7.98313 16.4686H11.5035C11.5035 16.4686 11.5035 12.0286 11.5035 8.4229H13.8789L14.1859 5.57957Z'
				fill='black'
				fillOpacity='0.3'
			/>
		</svg>
	);
}
