import React, { useEffect } from "react";
// import Footer from "./Footer";
// import NavBar from "./../global-components/nav/NavBar";
import ProgressBar from "@badrap/bar-of-progress";
import LeftSidebar from "./Navigation/LeftSidebar";
import './style.scss'

export default function AppLayout({ children }) {
  const progress = new ProgressBar({
    size: 2,
    color: "#fff",
    className: "bar-of-progress",
    delay: 50,
  });
  if (typeof window !== "undefined") {
    progress.start();
    progress.finish();
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className={`wrapper`}>
     
      <LeftSidebar/>
      <div id="content-page" className="content-page">
        {children}
      </div>
      {/* <Footer /> */}
    </div>
  );
}
