import { createSlice } from "@reduxjs/toolkit";

const orderDetailsSlice = createSlice({
	name: "orderDetails",
	initialState: {
		userOrderType: "",
	},
	reducers: {
		setUserOrderType: (state, action) => {
			state.userOrderType = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = orderDetailsSlice;

export const setUserOrderType = (data) => async (dispatch) => {
	dispatch(actions.setUserOrderType(data));
};
// Export the reducer, either as a default or named export
export default reducer;
