import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";
import Add from "assets/images/icon/svg/Add";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function AddMoreProduct() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='px-[6px]'>
			<IonGrid>
				<IonRow>
					<IonCol size='5' className='p-0'></IonCol>
					<IonCol size='7' className='p-0'>
						<div>
							<Link
								to='/select-product'
								className='custom-btn text-right block'>
								<IonButton className='h-10 w-full bg-white border border-primary rounded-[10px] font-bold text-12 text-primary'>
									{ekkLanguage.createOrder.addMoreProductLabel}
									<span className='pl-2'>
										<Add />
									</span>
								</IonButton>
							</Link>
						</div>
					</IonCol>
				</IonRow>
			</IonGrid>
		</div>
	);
}
