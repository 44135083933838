import React from "react";

export default function Minus2({ color = "#FF564C" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='2'
			viewBox='0 0 14 2'
			fill='none'>
			<path
				d='M13.125 1.875H0.875C0.391754 1.875 0 1.48325 0 1C0 0.516754 0.391754 0.125 0.875 0.125H13.125C13.6082 0.125 14 0.516754 14 1C14 1.48325 13.6082 1.875 13.125 1.875Z'
				fill={color}
			/>
		</svg>
	);
}
