import React from "react";

export default function Envelope() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'>
			<g clipPath='url(#clip0_429_824)'>
				<path
					d='M23.954 5.54201L15.536 13.96C14.5974 14.8963 13.3257 15.422 12 15.422C10.6743 15.422 9.40263 14.8963 8.464 13.96L0.046 5.54201C0.032 5.70001 0 5.84301 0 6.00001V18C0.00158786 19.3256 0.528882 20.5965 1.46622 21.5338C2.40356 22.4711 3.67441 22.9984 5 23H19C20.3256 22.9984 21.5964 22.4711 22.5338 21.5338C23.4711 20.5965 23.9984 19.3256 24 18V6.00001C24 5.84301 23.968 5.70001 23.954 5.54201Z'
					fill='#222222'
				/>
				<path
					d='M14.1221 12.546L23.2561 3.411C22.8137 2.67732 22.1896 2.07004 21.4441 1.64773C20.6986 1.22542 19.8569 1.00234 19.0001 1H5.00014C4.14336 1.00234 3.30164 1.22542 2.55617 1.64773C1.81069 2.07004 1.18662 2.67732 0.744141 3.411L9.87814 12.546C10.4417 13.1073 11.2047 13.4225 12.0001 13.4225C12.7956 13.4225 13.5586 13.1073 14.1221 12.546Z'
					fill='#222222'
				/>
			</g>
			<defs>
				<clipPath id='clip0_429_824'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
