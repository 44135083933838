import React from "react";

export default function Lock() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='18'
			viewBox='0 0 16 18'
			fill='none'>
			<path
				d='M13.25 6.318V5.24999C13.25 2.35051 10.8995 0 7.99999 0C5.10048 0 2.75 2.35051 2.75 5.24999V6.318C1.38502 6.91372 0.501969 8.2607 0.5 9.74999V14.25C0.502461 16.32 2.17994 17.9975 4.24998 18H11.75C13.82 17.9975 15.4975 16.32 15.5 14.25V9.74999C15.498 8.2607 14.615 6.91372 13.25 6.318ZM8.74998 12.75C8.74998 13.1642 8.4142 13.5 7.99999 13.5C7.58578 13.5 7.25 13.1642 7.25 12.75V11.25C7.25 10.8358 7.58578 10.5 7.99999 10.5C8.4142 10.5 8.74998 10.8358 8.74998 11.25V12.75ZM11.75 6.00001H4.24998V5.25002C4.24998 3.17897 5.9289 1.50001 7.99999 1.50001C10.0711 1.50001 11.75 3.17893 11.75 5.25002V6.00001Z'
				fill='#222222'
			/>
		</svg>
	);
}
