import {
	IonApp,
	IonContent,
	IonLabel,
	IonPage,
	IonButton,
	IonCol,
	IonSpinner,
} from "@ionic/react";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomCheckBox from "components/global-components/common/CustomCheckBox";
import DatePicker from "react-datepicker";
import Calendar from "assets/images/icon/svg/Calendar";
import moment from "moment";
import { getSelectedDate } from "redux/container/dashboardSlice";
import Select from "react-select";
import {
	dateTimeFormatter,
	digitChanger,
	numberWithCommas,
} from "helpers/helper";
import DashBoard from "services/Dashboard";
import { setDashboardBottomSheet } from "redux/container/userSlice";
import RightArrow from "assets/images/icon/svg/RightArrow";
import {
	setDriverSummery,
	setProductSummery,
	setSummaryType,
} from "redux/container/ProductSummerySlice";
import Add from "assets/images/icon/svg/Add";

export default function OrderDeliveryAmountSummary() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	// const [selectedDate, setSelectedDate] = useState(moment(new Date()));
	const [selectedDate, setSelectedDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [status, setStatus] = useState(null);
	const dateInfo = useSelector((state) => state.dashboard.dateInfo);
	const driverInfo = useSelector((state) => state.driver.driverData);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let [loading, setLoading] = useState(false);
	const [orderList, setOrderList] = useState({});
	let viewDate = moment(selectedDate).format("DD-MM-yyyy");

	const OfferStartDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white h-10 w-10 rounded-[5px] ml-1 flex items-center justify-center`}
			onClick={onClick}>
			<Calendar></Calendar>
		</div>
	));
	const getOrderStatusResult = async (date, driverId) => {
		let res = await DashBoard.getDeliveryAmountSummary(
			moment(date).format("DD-MM-yyyy"),
			driverId
		);
		if (res.status === 200) {
			setLoading(false);
			setOrderList(res?.data);
			console.log(res.data);
		} else {
			setLoading(false);
			setOrderList([]);
		}
	};

	dispatch(setDashboardBottomSheet(false));
	{
	}
	useEffect(() => {
		let interval = setTimeout(() => {
			let dateVal = dateInfo?.date;
			let status = dateInfo?.status;
			setSelectedDate(dateVal);
			setStatus(status);
			if (dateVal) {
				getOrderStatusResult(dateVal, driverInfo.id);
			}
		}, 500);
		return () => clearTimeout(interval);
	}, [selectedDate, status, dispatch]);
	// useEffect(() => {

	//     if (!selectedDate) {
	//         setSelectedDate(moment(new Date()));
	//     }

	//     getOrderStatusResult(status, selectedDate);
	//     dispatch(setDashboardBottomSheet(false));
	// }, [selectedDate, status, dispatch]);

	// const onChange = (dates) => {
	//     const [start, endDate] = dates;
	//     const end = endDate
	//         ? moment(endDate).endOf("day")._d
	//         : moment(start).endOf("day")._d;

	//     setStartDate(start);
	//     setEndDate(endDate);
	//     let dateTime = { start, end, };
	//     // dispatch(getSelectedDate(dateTime));
	//     let stringDateTime = JSON.stringify(dateTime);
	//     setSelectedDate(stringDateTime);
	//     getOrderStatusResult(status, startDate)
	// };

	const selectStatusChanger = (e) => {
		setStatus(e.value);
		getOrderStatusResult(e.value, selectedDate);
		let dateStatus = {
			status: e.value,
			date: selectedDate,
		};
		dispatch(getSelectedDate(dateStatus));
	};
	const dateChanger = (date) => {
		setSelectedDate(date);
		getOrderStatusResult(date, driverInfo.id);
		let dateStatus = {
			status: status,
			date: date,
		};
		dispatch(getSelectedDate(dateStatus));
	};
	const handleNavigate = () => {
		let data = {
			driverId: driverInfo.id,
			date: selectedDate,
			driver: driverInfo.name,
		};

		dispatch(setProductSummery(data));
		navigate(`/order-delivery-amount-summary/details`);
	};
	const handleNavigateSummary = () => {
		dispatch(setSummaryType("DeliveryAmountSummary"));
		navigate("/driver-list");
	};
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-40 h-10 border p-2.5 rounded relative
								${
									selectedDate
										? "border-[#00B224] font-bold text-[#00B224]"
										: "border-[#FF564C]"
								} custom-placeholder-style`}
			onClick={onClick}>
			<div className='flex'>
				<span className=''>
					{selectedDate ? (
						<Calendar color={"#00B224"}></Calendar>
					) : (
						<Calendar color={"#FF564C"}></Calendar>
					)}
				</span>
				<span>
					{selectedDate ? (
						<h1 className='text-center pl-2.5 text-12 font-bold text-[#00B224] pt-0.5 '>
							{viewDate}
						</h1>
					) : (
						<h1 className='text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 '>
							Select Date
						</h1>
					)}
				</span>
				<span className='absolute right-0 mr-2.5'>
					{selectedDate ? (
						<Add color={"#00B224"}></Add>
					) : (
						<Add color={"#FF564C"}></Add>
					)}
				</span>
			</div>
		</div>
	));
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className='pt-20'>
						<div className='flex justify-around'>
							<div
								className={`flex text-center text-12 w-40 relative rounded items-center 
                          `}>
								<DatePicker
									onFocus={(e) => (e.target.readOnly = true)}
									placeholderText='Select Date'
									selected={selectedDate}
									onChange={(date) => dateChanger(date)}
									customInput={<ExampleCustomInput />}
									withPortal
								/>
							</div>
							<div
								className={`bg-white flex px-2 w-40 rounded border  ${
									driverInfo.name
										? "border-[#00B224]"
										: "border-[#FF564C]"
								}`}>
								{driverInfo.name ? (
									<div
										onClick={handleNavigateSummary}
										className='text-center flex text-[#00B224] text-12 font-semibold items-center justify-between w-full '>
										<span className='mr-2 '>{driverInfo.name}</span>{" "}
										<RightArrow />
									</div>
								) : (
									<div
										onClick={handleNavigateSummary}
										className='text-center flex text-[#FF564C] text-12 font-semibold items-center justify-between w-full'>
										<span className='mr-2 '>Select Driver</span>{" "}
										<RightArrow />
									</div>
								)}
							</div>
						</div>

						<div className='mt-10'>
							<table
								className='table-auto w-full bg-white'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='border p-1 font-bold text-12'>
											S/N
										</th>
										<th className='border  font-bold text-12'>
											{ekkLanguage.orderSummary.totalAmount}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.totalPaid}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.totalDue}
										</th>
									</tr>
								</thead>
								<tbody>
									{orderList?.detail?.length
										? orderList?.detail.map((e, i) => {
												// console.log(e.item);
												return (
													<tr
														onClick={() => handleNavigate()}
														className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
														key={i}>
														<td className='border text-[#0099CC] font-semibold p-2 text-10'>
															{digitChanger(i + 1)}
														</td>
														<td className='border text-[#0099CC] font-semibold p-2 text-10'>
															৳
															{digitChanger(
																numberWithCommas(
																	e.item.totalAmount
																)
															)}
														</td>

														<td className='border text-[#0099CC] font-semibold p-2 text-10'>
															৳
															{digitChanger(
																numberWithCommas(
																	e.item.totalPaid
																)
															)}
														</td>
														<td className='border text-[#0099CC] font-semibold p-2 text-10'>
															৳
															{digitChanger(
																numberWithCommas(
																	e.item.totalDue
																)
															)}
														</td>
														{/* <td className='border  font-semibold text-12'>
    
                                                                    {digitChanger(e.orderId)}
    
                                                                </td> */}
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									{" "}
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								""
							)}
							{orderList?.length === 0 && (
								<>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</>
							)}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
