import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BrandList from "components/mobile-components/BrandList";
import BrandBottomSheet from "components/mobile-components/BrandList/BrandBottomSheet";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Search from "components/mobile-components/Common/Search";
import DepartmentList from "components/mobile-components/DepartmentList";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Brand from "services/Brand";
import Product from "services/Product";

export default function DepartmentListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [departmentList, setDepartmentList] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");

	let getDepartment = async () => {
		let response = await Product.getDepartment();
		if (response.status === 200) {
			setDepartmentList(response.results);
		}
	};
	useEffect(() => {
		getDepartment();
	}, []);
	// let lodeMoreData = async () => {
	// 	if (searchQ) {
	// 		if (myBrand) {
	// 			const response = await Brand.getMyBrandListSearch(
	// 				searchQ,
	// 				pageCount
	// 			);
	// 			if (response.status === 200) {
	// 				setPageCount((prev) => prev + 1);
	// 				setBrandList([...brandList, ...response.results]);
	// 			}
	// 		} else {
	// 			const response = await Brand.getAllBrandListSearch(
	// 				searchQ,
	// 				pageCount
	// 			);
	// 			if (response.status === 200) {
	// 				setPageCount((prev) => prev + 1);
	// 				setBrandList([...brandList, ...response.results]);
	// 			}
	// 		}
	// 	} else {
	// 		if (myBrand) {
	// 			const response = await Brand.getMyBrandList(pageCount);
	// 			if (response.status === 200) {
	// 				setPageCount((prev) => prev + 1);
	// 				setBrandList([...brandList, ...response.results]);
	// 			}
	// 		} else {
	// 			const response = await Brand.getAllBrandList(pageCount);
	// 			if (response.status === 200) {
	// 				setPageCount((prev) => prev + 1);
	// 				setBrandList([...brandList, ...response.results]);
	// 			}
	// 		}
	// 	}
	// };

	let singleDepartmentSearch = async () => {
		let response = await Brand.getSingleDepartment(searchQ);
		if (response.status === 200) {
			setDepartmentList(response.results);
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			getDepartment();
		}
	}, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.createProduct.selectDepartment}
					/>
					<section className='pt-14'>
						<div className='px-4 py-5'>
							<Search
								placeholder={ekkLanguage.createProduct.searchDepartment}
								search={searchQ}
								setSearch={setSearchQ}
								singleSearch={singleDepartmentSearch}
							/>
						</div>
						<DepartmentList departmentList={departmentList} />
					</section>
					{/* <div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								lodeMoreData();
								setTimeout(() => ev.target.complete(), 1000);
							}}>
							<IonInfiniteScrollContent
								className='pb-24'
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner='bubbles'></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div> */}
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
