import { digitChanger, numberWithCommas } from "helpers/helper";
import React from "react";

export default function SingleAMountBox({ withComma, name, value }) {
	return (
		<div
			className='p-[10px] bg-white rounded-[7px] cursor-pointer'
			style={{
				boxShadow: " 0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
			}}>
			<h4 className='font-semibold text-14 text-black-500 pb-4'>{name}</h4>
			<h2 className='font-bold text-18 text-center text-black-1000 pb-5'>
				{withComma
					? digitChanger(numberWithCommas(value ?? 0))
					: digitChanger(value ?? 0)}
			</h2>
		</div>
	);
}
