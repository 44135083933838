import React from "react";

export default function LocationPin({ color = "#F94B41" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='31'
			height='35'
			viewBox='0 0 31 35'
			style={{ margin: "auto" }}
			fill='none'>
			<path
				d='M15.4993 0C11.6329 0.00424608 7.92605 1.54206 5.19206 4.27605C2.45808 7.01003 0.920262 10.7169 0.916016 14.5833C0.916016 22.2862 13.166 32.8606 14.5573 34.0419L15.4993 34.8381L16.4414 34.0419C17.8327 32.8606 30.0827 22.2862 30.0827 14.5833C30.0784 10.7169 28.5406 7.01003 25.8066 4.27605C23.0726 1.54206 19.3658 0.00424608 15.4993 0V0ZM15.4993 21.875C14.0572 21.875 12.6474 21.4473 11.4483 20.6461C10.2492 19.8449 9.31462 18.7061 8.76273 17.3737C8.21084 16.0414 8.06644 14.5752 8.34779 13.1608C8.62914 11.7464 9.3236 10.4471 10.3434 9.42735C11.3631 8.40759 12.6624 7.71312 14.0768 7.43177C15.4913 7.15042 16.9574 7.29482 18.2897 7.84671C19.6221 8.3986 20.7609 9.33319 21.5621 10.5323C22.3634 11.7314 22.791 13.1412 22.791 14.5833C22.7887 16.5165 22.0197 18.3698 20.6528 19.7368C19.2858 21.1037 17.4325 21.8727 15.4993 21.875Z'
				fill={color}
			/>
		</svg>
	);
}
