import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { productCreate } from "redux/container/productSlice";

export default function SingleSubCategory({ item }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let product = useSelector((state) => state.product.data);

	return (
		<div className='items-center justify-between'>
			<div
				className='flex items-center ease-in duration-100 hover:bg-e6e6e6 mb-[5px] py-[5px] px-4 cursor-pointer'
				onClick={() => {
					dispatch(
						productCreate({
							...product,
							subcategory: item,
						})
					);
					navigate(`/create-product`);
				}}>
				{Array.isArray(item?.images) && item?.images?.length ? (
					<div className='h-10 w-10 rounded-full overflow-hidden'>
						<img src={item?.images[0]} alt='logo'></img>
					</div>
				) : (
					<div className='h-10 w-10 flex justify-center items-center bg-black-333 rounded-full'>
						<h3 className='font-bold text-24 text-white'>
							{item?.title && item?.title[0].toUpperCase()}
						</h3>
					</div>
				)}

				<div className='ml-4'>
					<h4 className='text-14 text-black-1000 font-bold pb-[2px]'>
						{item?.title}
					</h4>
					<p className='text-10 text-black-500'>{item?.owner_name}</p>
				</div>
			</div>
		</div>
	);
}
