import React from "react";

export default function LeftArrow({ color = "#222222" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='21'
			viewBox='0 0 12 21'
			fill={color}>
			<path d='M10.2744 20.4L0 10.2L10.2744 0L11.7635 1.48905L2.9781 10.2L11.7635 18.9109L10.2744 20.4Z' />
		</svg>
	);
}
