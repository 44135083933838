import React from "react";

export default function Location() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'>
			<g clipPath='url(#clip0_145_101)'>
				<path
					d='M9 4.5C8.40666 4.5 7.82664 4.67595 7.33329 5.00559C6.83994 5.33524 6.45543 5.80377 6.22836 6.35195C6.0013 6.90013 5.94189 7.50333 6.05765 8.08527C6.1734 8.66721 6.45912 9.20176 6.87868 9.62132C7.29824 10.0409 7.83279 10.3266 8.41473 10.4424C8.99667 10.5581 9.59987 10.4987 10.1481 10.2716C10.6962 10.0446 11.1648 9.66006 11.4944 9.16671C11.8241 8.67336 12 8.09334 12 7.5C12 6.70435 11.6839 5.94129 11.1213 5.37868C10.5587 4.81607 9.79565 4.5 9 4.5ZM9 9C8.70333 9 8.41332 8.91203 8.16665 8.7472C7.91997 8.58238 7.72771 8.34811 7.61418 8.07403C7.50065 7.79994 7.47095 7.49834 7.52882 7.20736C7.5867 6.91639 7.72956 6.64912 7.93934 6.43934C8.14912 6.22956 8.41639 6.0867 8.70737 6.02882C8.99834 5.97094 9.29994 6.00065 9.57403 6.11418C9.84812 6.22771 10.0824 6.41997 10.2472 6.66665C10.412 6.91332 10.5 7.20333 10.5 7.5C10.5 7.89783 10.342 8.27936 10.0607 8.56066C9.77936 8.84196 9.39783 9 9 9Z'
					fill='#222222'
				/>
				<path
					d='M8.99999 18C8.36845 18.0032 7.74532 17.8551 7.18279 17.568C6.62026 17.281 6.13469 16.8633 5.76674 16.35C2.90849 12.4073 1.45874 9.44326 1.45874 7.53976C1.45874 5.53969 2.25326 3.62155 3.66752 2.20729C5.08178 0.793027 6.99993 -0.00149536 8.99999 -0.00149536C11.0001 -0.00149536 12.9182 0.793027 14.3325 2.20729C15.7467 3.62155 16.5412 5.53969 16.5412 7.53976C16.5412 9.44326 15.0915 12.4073 12.2332 16.35C11.8653 16.8633 11.3797 17.281 10.8172 17.568C10.2547 17.8551 9.63153 18.0032 8.99999 18ZM8.99999 1.63575C7.4343 1.63754 5.93325 2.2603 4.82614 3.36741C3.71904 4.47452 3.09628 5.97557 3.09449 7.54126C3.09449 9.04876 4.51424 11.8365 7.09124 15.3908C7.31001 15.6921 7.59702 15.9373 7.92878 16.1065C8.26053 16.2756 8.62762 16.3637 8.99999 16.3637C9.37236 16.3637 9.73945 16.2756 10.0712 16.1065C10.403 15.9373 10.69 15.6921 10.9087 15.3908C13.4857 11.8365 14.9055 9.04876 14.9055 7.54126C14.9037 5.97557 14.2809 4.47452 13.1738 3.36741C12.0667 2.2603 10.5657 1.63754 8.99999 1.63575Z'
					fill='#222222'
				/>
			</g>
			<defs>
				<clipPath id='clip0_145_101'>
					<rect width='18' height='18' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
