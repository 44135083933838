import LocationEnabled from "components/mobile-components/Business/LocationEnabled";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setLocation } from "redux/container/userSlice";

export default function withLocation(OrginalComponent) {
	function GeneratedComponent() {
		let dispatch = useDispatch();
		let location = useSelector((state) => state.user.location);

		useEffect(() => {
			if (navigator.geolocation) {
				let location = navigator.geolocation;
				if (location && Object.keys(location).length === 0) {
					navigator.geolocation.getCurrentPosition((p) => {
						dispatch(
							setLocation({
								type: "Point",
								coordinates: [p.coords.longitude, p.coords.latitude],
							})
						);
					});
				}
			} else {
				toast.er("Geolocation is not supported by this browser.");
			}
		}, []);

		return location ? <OrginalComponent /> : <LocationEnabled />;
	}

	return GeneratedComponent;
}
