import React, { useState } from "react";
import SingleMethodCard from "./SingleMethodCard";
import { IonButton } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PaymentMethodSubscription({ methods }) {
	const [selected, setSelected] = useState({});
	const [alert, setAlert] = useState("");
	const navigate = useNavigate();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<>
			<div className='grid grid-cols-3 gap-3 mt-5'>
				{methods.map((method) => {
					return (
						<SingleMethodCard
							key={method?.id}
							method={method}
							selected={selected}
							setSelected={setSelected}
							setAlert={setAlert}
						/>
					);
				})}
			</div>
			{alert && <p className='my-5 text-primary font-semibold'>{alert}</p>}

			{/* Upgrade Button  */}
			<IonButton
				onClick={() => {
					if (selected?.id) {
						setAlert("");
						navigate(selected?.navigationLink);
					} else {
						setAlert(ekkLanguage?.paymentModule.selectedMethod);
					}
				}}
				type='submit'
				color='success'
				className='font-bold h-12 text-20 rounde-lg w-[95%] absolute bottom-5 left-2 right-2.5'>
				<span className='text-white'>
					{ekkLanguage?.paymentModule.continue}
				</span>
			</IonButton>
		</>
	);
}
