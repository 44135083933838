import { IonButton } from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Check from "assets/images/icon/svg/Check";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageUploader from "./ImageUploader";
import User from "services/User";
import { setTradeLicense } from "redux/container/businessSettingsSlice";

export default function TradLicense() {
	const [files, setFiles] = useState("");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	const nextHandler = async () => {
		let formData = new FormData();
		formData.append("key", files[0]);

		if (files.length !== 0) {
			let response = await User.toBucketImage(formData);
			if (response.status === 201) {
				let data = {
					tradeLicense_image: response.data[0],
				};
				dispatch(setTradeLicense(data));
				navigate("/vbnid");
			}
		}
	};
	return (
		<div className='pt-14 verifyBusiness-content'>
			{/* <VerifyBusinessImage /> */}
			<div className='py-5 px-4'>
				<ImageUploader
					files={files}
					setFiles={setFiles}
					bHeight={360}
					placeHolder='Upload Image'
				/>
			</div>

			<div className='px-4'>
				{files.length ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}>
							<Check></Check> Upload Complete.
						</p>
						<p
							className='font-normal text-14'
							style={{ color: "#808080" }}>
							Please click "
							<span style={{ color: "#00B224" }}>NEXT</span>" button
						</p>
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal p-[14px] text-14'
							style={{ color: "#808080" }}>
							Please, upload your{" "}
							<b className='text-primary font-semibold'>
								“Trade License”
							</b>{" "}
							copy.
						</p>
					</div>
				)}

				<div className='pt-24 text-center pb-10'>
					<IonButton
						color='primary'
						className='font-bold h-10'
						onClick={nextHandler}>
						{ekkLanguage.businessCreateName.btnLabel}
						<span className='pl-2'>
							<ArrayRight />
						</span>
					</IonButton>
				</div>
				<div className='business-step text-center pb-10'>
					<ul className='flex justify-between items-center'>
						<li className='w-full relative'>
							<div className='w-7 h-7 border-2 border-primary rounded-full text-white bg-primary z-10 relative'>
								{ekkLanguage.verifyBusiness.numberOne}
							</div>
						</li>
						<li className='w-full relative'>
							<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-F94B41-200 z-10 relative bg-white'>
								{ekkLanguage.verifyBusiness.numberTwo}
							</div>
						</li>
						<li className='w-full relative'>
							<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-F94B41-200 z-10 relative bg-white'>
								{ekkLanguage.verifyBusiness.numberThree}
							</div>
						</li>
						<li className='relative'>
							<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-F94B41-200 z-10 relative bg-white'>
								{ekkLanguage.verifyBusiness.numberFour}
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
