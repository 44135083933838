import React from "react";

export default function Search({ color = "white" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'>
			<path
				d='M23.7068 22.293L17.7378 16.324C19.3644 14.3346 20.1642 11.7961 19.9716 9.23356C19.7791 6.67103 18.609 4.28053 16.7034 2.55652C14.7977 0.832506 12.3024 -0.0931122 9.73342 -0.0288803C7.16447 0.0353515 4.71848 1.08452 2.9014 2.90161C1.08431 4.7187 0.0351378 7.16469 -0.029094 9.73364C-0.0933258 12.3026 0.832292 14.798 2.5563 16.7036C4.28031 18.6092 6.67081 19.7793 9.23334 19.9718C11.7959 20.1644 14.3344 19.3646 16.3238 17.738L22.2928 23.707C22.4814 23.8892 22.734 23.99 22.9962 23.9877C23.2584 23.9854 23.5092 23.8802 23.6946 23.6948C23.88 23.5094 23.9852 23.2586 23.9875 22.9964C23.9897 22.7342 23.8889 22.4816 23.7068 22.293ZM9.99978 18C8.41753 18 6.87081 17.5308 5.55522 16.6518C4.23963 15.7727 3.21425 14.5233 2.60875 13.0615C2.00324 11.5997 1.84482 9.99112 2.1535 8.43927C2.46218 6.88743 3.22411 5.46196 4.34293 4.34314C5.46175 3.22432 6.88721 2.46239 8.43906 2.15371C9.99091 1.84503 11.5994 2.00346 13.0612 2.60896C14.5231 3.21446 15.7725 4.23984 16.6515 5.55543C17.5306 6.87103 17.9998 8.41775 17.9998 10C17.9974 12.121 17.1538 14.1544 15.654 15.6542C14.1542 17.154 12.1208 17.9976 9.99978 18Z'
				fill={color}
			/>
		</svg>
	);
}
