import React, { useEffect, useState } from "react";
import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useSelector } from "react-redux";
import CreateEmployees from "components/mobile-components/CreateEmployees";
import { useParams } from "react-router-dom";
import Business from "services/Business";

export default function UpdateEmployeePage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let { id } = useParams();
	const [employees, setEmployees] = useState({});
	const [empLoading, setEmpLoading] = useState(false);

	const getEmployees = async () => {
		setEmpLoading(true);
		let response = await Business.getEmployees(id);
		if (response.status === 200) {
			setEmployees(response.results);
			setEmpLoading(false);
		} else {
			setEmpLoading(false);
		}
	};

	useEffect(() => {
		getEmployees();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.createEmployees.updateEmployee}
					/>
					<CreateEmployees employees={employees} />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
