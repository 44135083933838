import {
	IonApp,
	IonContent,
	IonLabel,
	IonPage,
	IonButton,
	IonCol,
	IonSpinner,
} from "@ionic/react";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Calendar from "assets/images/icon/svg/Calendar";
import moment from "moment";
import { getSelectedDate } from "redux/container/dashboardSlice";
import Select from "react-select";
import {
	dateTimeFormatter,
	digitChanger,
	numberWithCommas,
} from "helpers/helper";
import DashBoard from "services/Dashboard";
import { setDashboardBottomSheet } from "redux/container/userSlice";
import { setProductSummery } from "redux/container/ProductSummerySlice";
import Card from "../Common/Card";

export default function BuyerSummary({ businessData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [selectedDate, setSelectedDate] = useState(null);
	const dayInfo = useSelector((state) => state.dashboard?.dateInfo.day);
	const [status, setStatus] = useState(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let [loading, setLoading] = useState(false);
	const [orderList, setOrderList] = useState({});
	let viewDate = moment(selectedDate).format("DD-MM-yyyy");

	const options = [
		{ value: "today", label: ekkLanguage.dashboard.today },
		{ value: "yesterday", label: ekkLanguage.dashboard.yesterday },
		{ value: "week", label: ekkLanguage.dashboard.thisWeek },
		{ value: "month", label: ekkLanguage.dashboard.thisMonth },
		{ value: "year", label: ekkLanguage.dashboard.thisYear },
		{ value: "lastWeek", label: ekkLanguage.dashboard.lastWeek },
		{ value: "lastMonth", label: ekkLanguage.dashboard.lastMonth },
		{ value: "lastYear", label: ekkLanguage.dashboard.lastYear },
	];
	const OfferStartDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white h-10 w-10 rounded-[5px] ml-1 flex items-center justify-center`}
			onClick={onClick}>
			<Calendar></Calendar>
		</div>
	));

	dispatch(setDashboardBottomSheet(false));
	useEffect(() => {
		let interval = setTimeout(() => {}, 500);
		return () => clearTimeout(interval);
	}, [selectedDate, status, dispatch]);

	const handleBuyerSummary = async (start, end) => {
		setLoading(true);
		let res = await DashBoard.getBuyerSummary(start, end);
		if (res.status === 200) {
			setLoading(false);
			setOrderList(res?.data);
		} else {
			setLoading(false);
			setOrderList([]);
		}
	};

	const dateChanger = (e) => {
		let timeDate = dateTimeFormatter(e.value);
		handleBuyerSummary(timeDate.start, timeDate.end);
		let dateTime = {
			start: timeDate.start,
			end: timeDate.end,
			level: e.value,
		};
		setSelectedDate(dateTime);
		dispatch(getSelectedDate(dateTime));
	};
	const handleNavigate = (item) => {
		let data = {
			item,
			date: selectedDate,
		};
		dispatch(setProductSummery(data));
		navigate(`/buyer-summary/${item._id}`);
	};
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className='pt-20'>
						<div className='flex justify-center'>
							<Select
								onFocus={(e) => (e.target.readOnly = true)}
								defaultValue={options.find(
									(el) => el.value === dayInfo
								)}
								options={options}
								onChange={dateChanger}
								placeholder={`${ekkLanguage.orderSummary.selectPlaceholder}`}
								className='custom-select'
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										width: 328,
										height: 40,
										border: dayInfo
											? "0.5px solid #00B224"
											: "1px solid #FF564C",
										outLine: "none",
										fontWeight: 500,
										fontSize: 12,
										borderRadius: 5,
									}),
									placeholder: (baseStyles, state) => ({
										...baseStyles,
										color: "#FF564C",
									}),
									indicatorSeparator: (baseStyles, state) => ({
										...baseStyles,
										display: "none",
									}),
									dropdownIndicator: (baseStyles, state) => ({
										...baseStyles,
										color: "#FF564C",
										color: dayInfo ? "#00B224" : "#FF564C",
									}),
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 5,
									colors: {
										...theme.colors,
										primary25: "rgb(204, 255, 214)",
										primary: "#00FF33",
									},
								})}
							/>
							<span></span>
						</div>

						<div className='mt-5'>
							<table
								className='table-auto w-full bg-white'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='border p-2 w-14 font-bold text-12'>
											S/N
										</th>
										<th className='border py-2 w-[185px] font-bold text-12'>
											{ekkLanguage.orderSummary.storeName}
										</th>
										<th className='border p-1 w-32 font-bold text-12'>
											{ekkLanguage.orderSummary.orderAmount}
										</th>
									</tr>
								</thead>
								<tbody>
									{orderList?.length
										? orderList?.map((el, i) => {
												return (
													<tr
														className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
														key={i}>
														<td className='border font-semibold text-10'>
															{digitChanger(i + 1)}
														</td>
														<td
															className='border text-[#2493c7] font-semibold p-2 text-10'
															onClick={() => handleNavigate(el)}>
															{digitChanger(el?.buyerName)}
														</td>
														<td className='border font-semibold text-10'>
															৳
															{digitChanger(
																numberWithCommas(el?.totalSum)
															)}
														</td>
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									{" "}
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								<div>
									{orderList?.length === 0 && (
										<>
											<IonCol size='2'></IonCol>
											<IonCol size='8'>
												<div
													className='text-center flex items-center justify-center'
													style={{
														height: "calc(100vh - 420px)",
													}}>
													<div>
														<img
															className='m-auto mb-[10px] mt-5'
															src='/assets/images/not-found-file.png'
															alt='emptyCard'
														/>
														<p className='text-16 font-normal text-222222 mb-[35px]'>
															{ekkLanguage.orderList.notFound}
														</p>
													</div>
												</div>
											</IonCol>
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
