import { IonCol, IonRow } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";

export default function BranchList() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div>
			<h4 className='text-20 text-black-1000 font-bold mb-[10px] mt-5'>
				{ekkLanguage.businessProfile.branchLabel}
			</h4>
			<IonRow>
				<IonCol size='6'>
					<div
						className='bg-white rounded-[7px] mb-2 p-[10px]'
						style={{ boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.05)" }}>
						<h6 className='text-12 text-222222 font-semibold'>
							EkkaBaz Bangladesh
						</h6>
						<p className='text-10 text-black-500 font-normal'>
							Mohammadpur
						</p>
					</div>
				</IonCol>
				<IonCol size='6'>
					<div
						className='bg-white rounded-[7px] mb-2 p-[10px]'
						style={{ boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.05)" }}>
						<h6 className='text-12 text-222222 font-semibold'>
							EkkaBaz Bangladesh
						</h6>
						<p className='text-10 text-black-500 font-normal'>
							Mohammadpur
						</p>
					</div>
				</IonCol>
				<IonCol size='6'>
					<div
						className='bg-white rounded-[7px] mb-2 p-[10px]'
						style={{ boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.05)" }}>
						<h6 className='text-12 text-222222 font-semibold'>
							EkkaBaz Bangladesh
						</h6>
						<p className='text-10 text-black-500 font-normal'>
							Mohammadpur
						</p>
					</div>
				</IonCol>
				<IonCol size='6'>
					<div
						className='bg-white rounded-[7px] mb-2 p-[10px]'
						style={{ boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.05)" }}>
						<h6 className='text-12 text-222222 font-semibold'>
							EkkaBaz Bangladesh
						</h6>
						<p className='text-10 text-black-500 font-normal'>
							Mohammadpur
						</p>
					</div>
				</IonCol>
			</IonRow>
		</div>
	);
}
