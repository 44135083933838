import React from "react";

export default function Card({ color }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill={color}>
			<g clipPath='url(#clip0_216_570)'>
				<path
					d='M14.25 16.5H3.75C2.7558 16.4988 1.80267 16.1033 1.09966 15.4003C0.396661 14.6973 0.00119089 13.7442 0 12.75L0 5.25C0.00119089 4.2558 0.396661 3.30267 1.09966 2.59967C1.80267 1.89666 2.7558 1.50119 3.75 1.5H14.25C15.2442 1.50119 16.1973 1.89666 16.9003 2.59967C17.6033 3.30267 17.9988 4.2558 18 5.25V12.75C17.9988 13.7442 17.6033 14.6973 16.9003 15.4003C16.1973 16.1033 15.2442 16.4988 14.25 16.5ZM3.75 3C3.15326 3 2.58097 3.23705 2.15901 3.65901C1.73705 4.08097 1.5 4.65326 1.5 5.25V12.75C1.5 13.3467 1.73705 13.919 2.15901 14.341C2.58097 14.7629 3.15326 15 3.75 15H14.25C14.8467 15 15.419 14.7629 15.841 14.341C16.2629 13.919 16.5 13.3467 16.5 12.75V5.25C16.5 4.65326 16.2629 4.08097 15.841 3.65901C15.419 3.23705 14.8467 3 14.25 3H3.75Z'
					fill={color}
				/>
				<path
					d='M14.25 9.75H8.25C8.05109 9.75 7.86032 9.67098 7.71967 9.53033C7.57902 9.38968 7.5 9.19891 7.5 9C7.5 8.80109 7.57902 8.61032 7.71967 8.46967C7.86032 8.32902 8.05109 8.25 8.25 8.25H14.25C14.4489 8.25 14.6397 8.32902 14.7803 8.46967C14.921 8.61032 15 8.80109 15 9C15 9.19891 14.921 9.38968 14.7803 9.53033C14.6397 9.67098 14.4489 9.75 14.25 9.75Z'
					fill={color}
				/>
				<path
					d='M5.25 9.75H3.75C3.55109 9.75 3.36032 9.67098 3.21967 9.53033C3.07902 9.38968 3 9.19891 3 9C3 8.80109 3.07902 8.61032 3.21967 8.46967C3.36032 8.32902 3.55109 8.25 3.75 8.25H5.25C5.44891 8.25 5.63968 8.32902 5.78033 8.46967C5.92098 8.61032 6 8.80109 6 9C6 9.19891 5.92098 9.38968 5.78033 9.53033C5.63968 9.67098 5.44891 9.75 5.25 9.75Z'
					fill={color}
				/>
				<path
					d='M9.75 13.5H3.75C3.55109 13.5 3.36032 13.421 3.21967 13.2803C3.07902 13.1397 3 12.9489 3 12.75C3 12.5511 3.07902 12.3603 3.21967 12.2197C3.36032 12.079 3.55109 12 3.75 12H9.75C9.94891 12 10.1397 12.079 10.2803 12.2197C10.421 12.3603 10.5 12.5511 10.5 12.75C10.5 12.9489 10.421 13.1397 10.2803 13.2803C10.1397 13.421 9.94891 13.5 9.75 13.5Z'
					fill={color}
				/>
				<path
					d='M14.25 13.5H12.75C12.5511 13.5 12.3603 13.421 12.2197 13.2803C12.079 13.1397 12 12.9489 12 12.75C12 12.5511 12.079 12.3603 12.2197 12.2197C12.3603 12.079 12.5511 12 12.75 12H14.25C14.4489 12 14.6397 12.079 14.7803 12.2197C14.921 12.3603 15 12.5511 15 12.75C15 12.9489 14.921 13.1397 14.7803 13.2803C14.6397 13.421 14.4489 13.5 14.25 13.5Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_216_570'>
					<rect width='18' height='18' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
