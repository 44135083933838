import React from "react";

export default function Delivery({ color = "#00000033" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='28'
			height='26'
			viewBox='0 0 28 26'
			style={{ margin: "auto" }}
			fill='none'>
			<path
				d='M22.1667 4.83332H19.8333V3.66666C19.8333 2.7384 19.4646 1.84816 18.8082 1.19178C18.1518 0.535405 17.2616 0.166656 16.3333 0.166656H3.5C2.57174 0.166656 1.6815 0.535405 1.02513 1.19178C0.368749 1.84816 0 2.7384 0 3.66666L0 21.1667H2.38117C2.35115 21.3597 2.33516 21.5546 2.33333 21.75C2.33333 22.833 2.76354 23.8716 3.52931 24.6373C4.29509 25.4031 5.3337 25.8333 6.41667 25.8333C7.49963 25.8333 8.53825 25.4031 9.30402 24.6373C10.0698 23.8716 10.5 22.833 10.5 21.75C10.4982 21.5546 10.4822 21.3597 10.4522 21.1667H17.5478C17.5178 21.3597 17.5018 21.5546 17.5 21.75C17.5 22.833 17.9302 23.8716 18.696 24.6373C19.4618 25.4031 20.5004 25.8333 21.5833 25.8333C22.6663 25.8333 23.7049 25.4031 24.4707 24.6373C25.2365 23.8716 25.6667 22.833 25.6667 21.75C25.6648 21.5546 25.6488 21.3597 25.6188 21.1667H28V10.6667C27.9981 9.12013 27.383 7.63747 26.2894 6.54391C25.1959 5.45035 23.7132 4.83518 22.1667 4.83332ZM22.1667 7.16666C23.0949 7.16666 23.9852 7.53541 24.6415 8.19178C25.2979 8.84816 25.6667 9.7384 25.6667 10.6667V11.8333H19.8333V7.16666H22.1667ZM8.16667 21.75C8.16667 22.2141 7.98229 22.6592 7.6541 22.9874C7.32591 23.3156 6.8808 23.5 6.41667 23.5C5.95254 23.5 5.50742 23.3156 5.17923 22.9874C4.85104 22.6592 4.66667 22.2141 4.66667 21.75C4.66738 21.5506 4.70414 21.353 4.77517 21.1667H8.05817C8.12919 21.353 8.16595 21.5506 8.16667 21.75ZM17.5 18.8333H2.33333V3.66666C2.33333 3.35724 2.45625 3.06049 2.67504 2.8417C2.89383 2.62291 3.19058 2.49999 3.5 2.49999H16.3333C16.6428 2.49999 16.9395 2.62291 17.1583 2.8417C17.3771 3.06049 17.5 3.35724 17.5 3.66666V18.8333ZM23.3333 21.75C23.3333 22.2141 23.149 22.6592 22.8208 22.9874C22.4926 23.3156 22.0475 23.5 21.5833 23.5C21.1192 23.5 20.6741 23.3156 20.3459 22.9874C20.0177 22.6592 19.8333 22.2141 19.8333 21.75C19.8338 21.5506 19.8706 21.3529 19.9418 21.1667H23.2248C23.2961 21.3529 23.3328 21.5506 23.3333 21.75ZM19.8333 18.8333V14.1667H25.6667V18.8333H19.8333Z'
				fill={color}
			/>
		</svg>
	);
}
