import React from "react";
import { useSelector } from "react-redux";

export default function SubscriptionPlan({ selected }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<div className='pt-5 px-4'>
			<div>
				<h4 className='font-bold text-18 mb-3 text-white'>
					{ekkLanguage.paymentModule?.yourPlan}
				</h4>
				<div
					className={`relative border-2 rounded-[10px] border-white $ bg-white mb-5 p-2 cursor-pointer`}>
					<h6 className={`font-bold text-18 $`}>{selected?.title}</h6>
					<div className='flex items-center'>
						<h6 className={`font-bold text-24`}>
							{selected?.country?.currency_symbol_native}
							{selected?.amount}
						</h6>
						<p className={`font-medium text-12 $`}>
							({selected?.plan_type})
						</p>
					</div>

					<img
						className='absolute top-1 right-1 w-9 h-9'
						src='/assets/images/checked.png'
						alt='check'
					/>
				</div>
			</div>

			<div className='mt-8 pb-8'>
				{selected?.description?.map((benefit, i) => {
					return (
						<div key={i} className='flex my-2'>
							<img
								className='w-[17px] h-[17px] mr-2'
								src='/assets/images/tic.png'
								alt=''
							/>
							<p className='font-semibold text-16 leading-5 text-white'>
								{benefit}
							</p>
						</div>
					);
				})}
			</div>
		</div>
	);
}
