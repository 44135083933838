import React from "react";
import { useDispatch } from "react-redux";
import { setUpgradePlan } from "redux/container/SubscriptionSlice";

export default function UpgradeSingleCard({ subscription, selected }) {
	const dispatch = useDispatch();

	return (
		<div
			onClick={() => {
				dispatch(setUpgradePlan(subscription));
			}}
			className={`relative border-2 rounded-[10px] border-white ${
				selected?.id === subscription?.id ? "bg-white" : "bg-transparent"
			} mb-5 p-2 cursor-pointer`}>
			<h6
				className={`font-bold text-18 ${
					selected?.id === subscription?.id ? "" : "text-white"
				} `}>
				{subscription?.title}
			</h6>
			<div className='flex items-center'>
				<h6
					className={`font-bold text-24 ${
						selected?.id === subscription?.id ? "" : "text-white"
					}`}>
					{subscription?.country?.currency_symbol_native}
					{subscription?.amount}
				</h6>
				<p
					className={`font-medium text-12 ${
						selected?.id === subscription?.id ? "" : "text-white"
					}`}>
					({subscription?.plan_type})
				</p>
			</div>
			{/* <div className='flex justify-between items-center'>
				<div>
					<p
						style={{
							color:
								selected?.id === subscription?.id
									? "rgba(0, 0, 0, 0.5)"
									: "rgba(255, 255, 255, 0.8)",
						}}
						className='font-semibold text-14 leading-5'>
						{subscription?.description}
					</p>
					{subscription?.discount && (
						<p
							style={{
								color:
									selected?.id === subscription?.id
										? "rgba(0, 0, 0, 0.5)"
										: "rgba(255, 255, 255, 0.8)",
							}}
							className='text-[10px] font-medium leading-3'>
							Cancel anytime. Everywhere.
						</p>
					)}
				</div>
				{subscription?.discount && (
					<h6
						className={`font-semibold text-14 border py-0.5 px-1.5 rounded-full ${
							selected?.id === subscription?.id
								? "text-[#00B224] border-[#00B22480] bg-[#DEFFFA]"
								: "text-white border-white"
						}`}>
						{subscription?.discount}
					</h6>
				)}
			</div> */}
			{selected?.id === subscription?.id && (
				<img
					className='absolute top-1 right-1 w-9 h-9'
					src='/assets/images/checked.png'
					alt='check'
				/>
			)}
		</div>
	);
}
