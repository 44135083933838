import React from "react";

export default function ArrowUp({ color }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<g id='fi-rr-arrow-up' clipPath='url(#clip0_3257_723)'>
				<path
					id='Vector'
					d='M7.93845 4.52636C7.93878 4.63643 7.98265 4.74191 8.06049 4.81974C8.13833 4.89758 8.2438 4.94145 8.35388 4.94178L10.4811 4.92999L4.46478 10.9463C4.38664 11.0244 4.34275 11.1304 4.34275 11.2409C4.34275 11.3514 4.38664 11.4574 4.46478 11.5356C4.54292 11.6137 4.6489 11.6576 4.75941 11.6576C4.86992 11.6576 4.9759 11.6137 5.05404 11.5356L11.0674 5.5222L11.0674 7.6553C11.0672 7.71031 11.0778 7.76481 11.0988 7.81567C11.1197 7.86653 11.1505 7.91274 11.1894 7.95164C11.2283 7.99053 11.2745 8.02134 11.3254 8.04229C11.3763 8.06323 11.4308 8.0739 11.4858 8.07367C11.5958 8.07335 11.7013 8.02947 11.7792 7.95164C11.857 7.8738 11.9009 7.76833 11.9012 7.65825L11.9041 5.35131C11.9039 5.02006 11.7723 4.70244 11.538 4.46821C11.3038 4.23398 10.9862 4.1023 10.6549 4.10209L8.35388 4.11093C8.2438 4.11126 8.13833 4.15513 8.06049 4.23297C7.98265 4.3108 7.93878 4.41628 7.93845 4.52636Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_3257_723'>
					<rect
						width='10'
						height='10'
						fill='white'
						transform='translate(8 0.928955) rotate(45)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
