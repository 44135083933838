import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButton from "components/mobile-components/Common/BackButton";
import MySuppliersDetails from "components/mobile-components/MySuppliersDetails/MySuppliersDetails";
import Banner from "components/mobile-components/ProductDetails/Banner";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Supplier from "services/Supplier";
import Product from "services/Product";
import { debounce } from "lodash";

export default function MySuppliersDetailsPage() {
	let { id } = useParams();
	const [product, setProduct] = useState([]);
	const [supplierInfo, setSupplierInfo] = useState([]);
	const [initHeight] = useState(72);
	const [initStickyClass, setInitStikyClass] = useState("");
	const [productCount, setProductCount] = useState("");
	const [productBrands, setProductBrands] = useState([]);
	const [searchQ, setSearchQ] = useState("");
	const [preloader, setPreloader] = useState(true);
	const [selectedBrand, setSelectedBrand] = useState("");

	// get brand data
	let mysupplierData = async () => {
		setPreloader(true);
		let response = await Supplier.singleMySupplierDetails(id);
		if (response.status === 200) {
			setSupplierInfo(response.data);
			setPreloader(false);
		} else {
			setPreloader(false);
		}
	};
	useEffect(() => {
		mysupplierData();
	}, []);

	//get suppliers data
	let productData = async () => {
		let response = await Supplier.singleMySupplierProductDetails(id);
		if (response.status === 200) {
			setProduct(response.data);
			setProductCount(response?.meta);
		} else {
		}
	};
	useEffect(() => {
		productData();
	}, []);

	const contentRef = useRef();
	let settinges = {
		nameShow: false,
		titleShow: true,
		skuShow: false,
		priceShow: true,
		oldPriceShow: false,
		distShow: false,
		profitShow: false,
		sellPriceShow: false,
		supplierNameShow: false,
		link: "product-details",
	};
	let slideData = supplierInfo?.images?.length
		? [...supplierInfo.images]
		: ["/assets/images/supplier_big_default.png"];

	async function getProductBrands() {
		try {
			const response = await Product.productBrands(id);
			if (response.status === 200) {
				let brandArray = Object.values(response);
				setProductBrands(brandArray);
			}
		} catch (error) {
			console.log(error);
		}
	}
	async function getProductsFromBrands(Bid) {
		// setPreloader(true);
		setSelectedBrand(Bid);
		try {
			const response = await Product.singleBrandProducts(id, Bid);
			if (response.status === 200) {
				// setPreloader(false);
				setProduct(response?.data);
			} else {
				// setPreloader(false);
			}
		} catch (error) {
			console.log(error);
		}
	}
	useEffect(() => {
		getProductBrands();
	}, []);

	async function singleProductSearch() {
		// setPreloader(true);
		try {
			const response = await Product.singleProductSearch(
				id,
				searchQ,
				selectedBrand
			);
			if (response.status === 200) {
				// setPreloader(false);
				setProduct(response.data);
			} else {
				// setPreloader(false);
			}
		} catch (error) {
			console.log(error);
		}
	}

	// const delayedQuery = useCallback(debounce(singleProductSearch, 500), [
	// 	searchQ,
	// ]);

	// useEffect(() => {
	// 	if (searchQ === "") {
	// 		productData();
	// 	} else {
	// 		delayedQuery();
	// 	}
	// }, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					ref={contentRef}
					onIonScroll={async (ev) => {
						const scrollPosition = ev.detail.scrollTop;
						if (scrollPosition > initHeight) {
							setInitStikyClass("issticky");
						} else {
							setInitStikyClass("");
						}
					}}>
					<BackButton sticky={initStickyClass} />
					<Banner slideData={slideData} />
					<MySuppliersDetails
						supplierInfo={supplierInfo}
						product={product}
						settinges={settinges}
						productCount={productCount}
						productBrands={productBrands}
						getProductBrands={productData}
						getProductsFromBrands={getProductsFromBrands}
						setSearchQ={setSearchQ}
						searchQ={searchQ}
						preloader={preloader}
					/>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
