import React from "react";
import SingleCategory from "./SingleCategory";

export default function CategoryList({ categoryList }) {
	return (
		<div>
			{categoryList.length > 0 &&
				categoryList.map((el, i) => {
					return <SingleCategory item={el} key={i} />;
				})}
		</div>
	);
}
