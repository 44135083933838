import { IonGrid } from "@ionic/react";
import Products from "components/global-components/common/Products";
import moment from "moment";
import React from "react";

export default function BrandDetails({ brandInfo = {}, product, settings }) {
	return (
		<div className='pt-5'>
			<div className='px-4 pb-4'>
				<h5 className='font-bold text-18 text-black-1000 pb-[10px]'>
					{brandInfo?.brand_name}
				</h5>
				<h4 className='font-bold text-24 text-primary'>
					SKU {brandInfo?.sku}
				</h4>
			</div>
			<div>
				<div className='flex items-center bg-white mb-[5px] py-[10px] px-4 cursor-pointer'>
					{brandInfo?.images?.length ? (
						<img
							className='h-10 w-10 rounded-full'
							src={brandInfo?.images[0]}
							alt='icon'></img>
					) : (
						<div className='h-10 w-10 flex justify-center items-center bg-black-333 rounded-full'>
							<h3 className='font-bold text-24 text-white'>
								{brandInfo?.brand_name &&
									brandInfo?.brand_name[0].toUpperCase()}
							</h3>
						</div>
					)}

					<div className='ml-4'>
						<h4 className='text-14 text-black-1000 font-bold pb-[2px]'>
							{brandInfo?.seller_name}
						</h4>
						<p className='text-10 text-black-500'>
							Joined on{" "}
							{brandInfo?.seller_created_at &&
								moment(brandInfo?.seller_created_at).format(
									"DD-MM-YYYY"
								)}
						</p>
					</div>
				</div>
			</div>
			<IonGrid>
				<Products recommendData={product} settings={settings} />
			</IonGrid>
		</div>
	);
}
