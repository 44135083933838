import React from "react";

export default function HeartOutline() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='22'
			viewBox='0 0 24 22'
			fill='none'>
			<path
				d='M12 21.45L11.6 21.1C2.49998 13.7 0.0499878 11.1 0.0499878 6.84998C0.0499878 3.34998 2.89998 0.549988 6.34998 0.549988C9.24998 0.549988 10.9 2.19998 12 3.44998C13.1 2.19998 14.75 0.549988 17.65 0.549988C21.15 0.549988 23.95 3.39998 23.95 6.84998C23.95 11.1 21.5 13.7 12.4 21.1L12 21.45ZM6.34998 1.84998C3.59998 1.84998 1.34998 4.09998 1.34998 6.84998C1.34998 10.5 3.64998 12.9 12 19.75C20.35 12.9 22.65 10.5 22.65 6.84998C22.65 4.09998 20.4 1.84998 17.65 1.84998C15.15 1.84998 13.8 3.34999 12.75 4.54999L12 5.39999L11.25 4.54999C10.2 3.34999 8.84998 1.84998 6.34998 1.84998Z'
				fill='white'
				fillOpacity='1'
			/>
		</svg>
	);
}
