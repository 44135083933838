import React from "react";

export default function Insta() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<g clipPath='url(#clip0_127_151)'>
				<path
					d='M9.99999 13.0769C11.6993 13.0769 13.0769 11.6994 13.0769 10C13.0769 8.30068 11.6993 6.9231 9.99999 6.9231C8.30065 6.9231 6.92307 8.30068 6.92307 10C6.92307 11.6994 8.30065 13.0769 9.99999 13.0769Z'
					fill='black'
					fillOpacity='0.3'
				/>
				<path
					d='M13.8454 0.769287H6.15307C3.18461 0.769287 0.769226 3.18544 0.769226 6.15467V13.847C0.769226 16.8154 3.18538 19.2308 6.15461 19.2308H13.8469C16.8154 19.2308 19.2308 16.8147 19.2308 13.8454V6.15313C19.2308 3.18467 16.8146 0.769287 13.8454 0.769287ZM10 14.6154C7.45461 14.6154 5.38461 12.5454 5.38461 10.0001C5.38461 7.45467 7.45461 5.38467 10 5.38467C12.5454 5.38467 14.6154 7.45467 14.6154 10.0001C14.6154 12.5454 12.5454 14.6154 10 14.6154ZM15.3846 5.38467C14.96 5.38467 14.6154 5.04006 14.6154 4.61544C14.6154 4.19083 14.96 3.84621 15.3846 3.84621C15.8092 3.84621 16.1538 4.19083 16.1538 4.61544C16.1538 5.04006 15.8092 5.38467 15.3846 5.38467Z'
					fill='black'
					fillOpacity='0.3'
				/>
			</g>
			<defs>
				<clipPath id='clip0_127_151'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
