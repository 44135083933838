import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import VerifyNumberSubmit from "components/mobile-components/Profile/VerifyNumberSubmit";
import React from "react";
import { useSelector } from "react-redux";

export default function ProfileVarifyOtpPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.otpsubmit.pageTitle} />
					<VerifyNumberSubmit />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
