import { IonCol, IonCheckbox } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { digitChanger, numberWithCommas } from "helpers/helper";
import { useNavigate, useParams } from "react-router-dom";
import {
	productOfferCreate,
	productOfferUpdate,
	productPackCreate,
} from "redux/container/productSlice";

export default function ProductList({
	productList,
	setProductList,
	productCartList,
	setProductCartList,
	loading,
	published,
}) {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let pack = useSelector((state) => state.product.productPack);
	let offer = useSelector((state) => state.product.productOffer);
	let singleOffer = useSelector((state) => state.product.singleProductOffer);

	let { type } = useParams();

	return (
		<div className='px-4' style={{ paddingBottom: 130 }}>
			{productList.length > 0 ? (
				productList.map((el, i) => {
					let checkedChecker = offer?.productItems?.find(
						(item) => item.id === el.id
					);
					let singleOfferChecker = singleOffer?.productItems?.find(
						(item) => item.product.id === el.id
					);

					let newItem = {
						product: { ...el, orderQuantity: 1 },
						quantity: 1,
					};

					return (
						<div
							className='p-[10px] bg-white rounded-[5px] mb-4 relative overflow-hidden cursor-pointer'
							style={{
								boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)",
							}}
							onClick={() => {
								if (type === "pack") {
									dispatch(
										productPackCreate({
											...pack,
											productItem: el,
										})
									);
								} else if (type === "offer") {
									let prevItemsMatch = offer?.productItems?.find(
										(item) => item?.id === el?.id
									);
									let prevItems = offer?.productItems?.length
										? offer?.productItems
										: [];

									if (!prevItemsMatch) {
										if (!el.orderQuantity) {
											el.orderQuantity = 1;
										}
										dispatch(
											productOfferCreate({
												...offer,
												productItems: [...prevItems, el],
											})
										);
									} else {
										let updateItems =
											prevItems.length &&
											prevItems?.filter(
												(item) => item?.id !== el.id
											);
										dispatch(
											productOfferCreate({
												...offer,
												productItems: updateItems,
											})
										);
									}
								} else if (type === "update-offer") {
									let prevItemsMatch = singleOffer?.productItems?.find(
										(item) => item?.product.id === el?.id
									);

									let prevItems = singleOffer?.productItems?.length
										? singleOffer?.productItems
										: [];
									if (!prevItemsMatch) {
										if (!el.orderQuantity) {
											el.orderQuantity = 1;
										}
										dispatch(
											productOfferUpdate({
												...singleOffer,
												productItems: [...prevItems, newItem],
											})
										);
									} else {
										let updateItems =
											prevItems.length &&
											prevItems?.filter(
												(item) => item?.product.id !== el.id
											);
										dispatch(
											productOfferUpdate({
												...singleOffer,
												productItems: updateItems,
											})
										);
									}
								}
							}}>
							{type === "offer" && checkedChecker && (
								<span
									className={`absolute flex justify-center items-center top-0 right-0`}
									style={{ borderBottomLeftRadius: 5 }}>
									<IonCheckbox
										checked={true}
										color='success'
										size='small'
									/>
								</span>
							)}
							{type === "pack" && pack?.productItem?.id === el.id && (
								<span
									className={`absolute flex justify-center items-center top-0 right-0`}
									style={{ borderBottomLeftRadius: 5 }}>
									<IonCheckbox
										checked={true}
										color='success'
										size='small'
									/>
								</span>
							)}
							{type === "update-offer" &&
								singleOfferChecker?.product.id === el.id && (
									<span
										className={`absolute flex justify-center items-center top-0 right-0`}
										style={{ borderBottomLeftRadius: 5 }}>
										<IonCheckbox
											checked={true}
											color='success'
											size='small'
										/>
									</span>
								)}

							<h4 className='pr-10 font-medium text-12 text-black-1000 pb-[7px]'>
								{el?.title}
							</h4>
							<div>
								<ul className='flex justify-between items-center'>
									<li className='flex'>
										<img
											src='/assets/images/package-box.png'
											alt='icon'
										/>
										<span className='font-bold text-12 text-primary pl-1'>
											{el?.country?.currency_symbol_native}
											{el?.base_price}
										</span>
									</li>
									<li className='flex'>
										<img
											src='/assets/images/warehouse.png'
											alt='icon'
										/>
										<span className='font-bold text-12 text-primary pl-1'>
											{el?.inventory_quantity}
										</span>
									</li>
									<li className='flex'>
										<img
											src='/assets/images/profits.png'
											alt='icon'
										/>
										<span className='font-bold text-12 text-paid pl-1'>
											{el?.country?.currency_symbol_native}
											{digitChanger(numberWithCommas(el?.profit))}
										</span>
									</li>
								</ul>
							</div>
						</div>
					);
				})
			) : (
				<>
					<IonCol size='2'></IonCol>
					<IonCol size='8'>
						<div
							className='text-center flex items-center justify-center'
							style={{ height: "calc(100vh - 420px)" }}>
							<div>
								<img
									className='m-auto mb-[10px] mt-5'
									src='/assets/images/not-found-file.png'
									alt='emptyCard'
								/>
								<p className='text-16 font-normal text-222222 mb-[35px]'>
									{ekkLanguage.orderList.notFound}
								</p>
							</div>
						</div>
					</IonCol>
				</>
			)}
		</div>
	);
}
