import React from "react";
import { Link } from "react-router-dom";
import { IonButton, IonIcon, IonContent } from "@ionic/react";
function Nav() {
  return (
    <>
      <IonButton color="primary">
        <Link to="/product/home">Products</Link>
      </IonButton>
      <IonButton color="secondary">Secondary</IonButton>
      <IonButton color="tertiary">Tertiary</IonButton>
      <IonButton color="success">Success</IonButton>
      <IonButton color="warning">Warning</IonButton>
      <IonButton color="danger">Danger</IonButton>
      <IonButton color="light">Light</IonButton>
      <IonButton color="medium">Medium</IonButton>
      <IonButton color="dark">Dark</IonButton>
    </>
  );
}

export default Nav;
