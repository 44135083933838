import React from "react";
import "./style.scss";

export default function SingleProduct({ img, name, star, qty, upShare }) {
	return (
		<div className='single-product-card mb-2 rounded overflow-hidden drop-shadow-md mb-4'>
			<div className='rounded-xl overflow-hidden'>
				<img src='assets/images/product1.jpeg' alt='' />
			</div>

			<div className='footer-box py-4 px-4'>
				<h5 className='font-bold pb-3 lg:text-md xl:text-lg sm:text-sm'>
					J Tarding Pvt. Ltd
				</h5>
				<div className='pb-3'>
					<i className='material-icons'>star</i>
					<i className='material-icons'>star</i>
					<i className='material-icons'>star</i>
					<i className='material-icons'>star</i>
					<i className='material-icons'>star</i>
				</div>
				<span>125 SKUs</span>
			</div>
		</div>
	);
}
