import React from "react";

export default function Payment({ color }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill={color}>
			<g clipPath='url(#clip0_216_651)'>
				<path
					d='M9 7.5C8.40666 7.5 7.82664 7.67595 7.33329 8.00559C6.83994 8.33524 6.45543 8.80377 6.22836 9.35195C6.0013 9.90013 5.94189 10.5033 6.05765 11.0853C6.1734 11.6672 6.45912 12.2018 6.87868 12.6213C7.29824 13.0409 7.83279 13.3266 8.41473 13.4424C8.99667 13.5581 9.59987 13.4987 10.1481 13.2716C10.6962 13.0446 11.1648 12.6601 11.4944 12.1667C11.8241 11.6734 12 11.0933 12 10.5C12 9.70435 11.6839 8.94129 11.1213 8.37868C10.5587 7.81607 9.79565 7.5 9 7.5ZM9 12C8.70333 12 8.41332 11.912 8.16665 11.7472C7.91997 11.5824 7.72771 11.3481 7.61418 11.074C7.50065 10.7999 7.47095 10.4983 7.52882 10.2074C7.5867 9.91639 7.72956 9.64912 7.93934 9.43934C8.14912 9.22956 8.41639 9.0867 8.70737 9.02882C8.99834 8.97094 9.29994 9.00065 9.57403 9.11418C9.84812 9.22771 10.0824 9.41997 10.2472 9.66664C10.412 9.91332 10.5 10.2033 10.5 10.5C10.5 10.8978 10.342 11.2794 10.0607 11.5607C9.77936 11.842 9.39783 12 9 12Z'
					fill={color}
				/>
				<path
					d='M16.902 3.09139L14.9085 1.09789C14.5611 0.74865 14.148 0.471777 13.6929 0.283304C13.2378 0.0948314 12.7498 -0.00149799 12.2573 -0.000107223H3.75C2.7558 0.00108367 1.80267 0.396554 1.09967 1.09956C0.396662 1.80256 0.00119089 2.7557 0 3.74989L0 14.2499C0.00119089 15.2441 0.396662 16.1972 1.09967 16.9002C1.80267 17.6032 2.7558 17.9987 3.75 17.9999H14.25C15.2442 17.9987 16.1973 17.6032 16.9003 16.9002C17.6033 16.1972 17.9988 15.2441 18 14.2499V5.74264C18.0014 5.25007 17.9051 4.76212 17.7166 4.30703C17.5281 3.85194 17.2512 3.43875 16.902 3.09139ZM12.75 1.55989V2.24989C12.75 2.84663 12.5129 3.41893 12.091 3.84088C11.669 4.26284 11.0967 4.49989 10.5 4.49989H7.5C6.90326 4.49989 6.33097 4.26284 5.90901 3.84088C5.48705 3.41893 5.25 2.84663 5.25 2.24989V1.49989H12.2573C12.4232 1.50136 12.5885 1.52149 12.75 1.55989ZM16.5 14.2499C16.5 14.8466 16.2629 15.4189 15.841 15.8409C15.419 16.2628 14.8467 16.4999 14.25 16.4999H3.75C3.15326 16.4999 2.58097 16.2628 2.15901 15.8409C1.73705 15.4189 1.5 14.8466 1.5 14.2499V3.74989C1.5 3.15316 1.73705 2.58086 2.15901 2.1589C2.58097 1.73695 3.15326 1.49989 3.75 1.49989V2.24989C3.75119 3.24409 4.14666 4.19723 4.84967 4.90023C5.55267 5.60323 6.5058 5.9987 7.5 5.99989H10.5C11.4431 5.99697 12.3504 5.63814 13.0403 4.99517C13.7303 4.35219 14.1522 3.47246 14.2215 2.53189L15.8415 4.15189C16.2618 4.5748 16.4984 5.14639 16.5 5.74264V14.2499Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_216_651'>
					<rect width='18' height='18' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
