import React from "react";

export default function AddBusiness() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='46'
			height='46'
			viewBox='0 0 46 46'
			fill='none'>
			<circle cx='23' cy='23' r='23' fill='#00B224' fill-opacity='0.15' />
			<circle cx='23' cy='23' r='16' fill='#00B224' fill-opacity='0.2' />
			<g clipPath='url(#clip0_113_164)'>
				<path
					d='M30 22H24V16C24 15.4477 23.5523 15 23 15C22.4477 15 22 15.4477 22 16V22H16C15.4477 22 15 22.4477 15 23C15 23.5523 15.4477 24 16 24H22V30C22 30.5523 22.4477 31 23 31C23.5523 31 24 30.5523 24 30V24H30C30.5523 24 31 23.5523 31 23C31 22.4477 30.5523 22 30 22Z'
					fill='#00B224'
				/>
			</g>
			<defs>
				<clipPath id='clip0_113_164'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(15 15)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
