import React from "react";

export default function ArrayRight({ color = "#ffffff" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='13'
			height='10'
			viewBox='0 0 13 10'
			fill='none'>
			<path
				d='M8.76652 9.75832L12.3415 6.17499C12.6519 5.86272 12.8262 5.4403 12.8262 4.99999C12.8262 4.55968 12.6519 4.13726 12.3415 3.82499L8.76652 0.241655C8.61038 0.0864464 8.39917 -0.00067158 8.17902 -0.00067159C7.95886 -0.000671599 7.74765 0.0864463 7.59152 0.241655C7.51341 0.319125 7.45142 0.411292 7.40911 0.512841C7.3668 0.614391 7.34502 0.723312 7.34502 0.833322C7.34502 0.943332 7.3668 1.05225 7.40911 1.1538C7.45142 1.25535 7.51341 1.34752 7.59152 1.42499L10.3332 4.16666L1.16652 4.16666C0.945502 4.16666 0.73354 4.25445 0.57726 4.41073C0.420979 4.56701 0.333183 4.77897 0.333183 4.99999C0.333183 5.221 0.420979 5.43296 0.57726 5.58924C0.73354 5.74552 0.945502 5.83332 1.16652 5.83332L10.3332 5.83332L7.59152 8.57499C7.4346 8.7308 7.346 8.94257 7.34522 9.16371C7.34444 9.38484 7.43153 9.59724 7.58735 9.75415C7.74316 9.91108 7.95493 9.99967 8.17607 10.0005C8.39721 10.0012 8.6096 9.91414 8.76652 9.75832Z'
				fill={color}
			/>
		</svg>
	);
}
