import React from "react";

export default function Settings() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<g id='Iconly/Light/Setting'>
				<g id='Setting'>
					<path
						id='Path_33946'
						fillRule='evenodd'
						clipRule='evenodd'
						d='M17.3372 6.35299L16.8185 5.45292C16.3797 4.69131 15.4073 4.42858 14.6446 4.86558V4.86558C14.2816 5.07943 13.8484 5.14011 13.4406 5.03422C13.0328 4.92833 12.6838 4.66458 12.4707 4.30112C12.3336 4.0701 12.2599 3.80698 12.2571 3.53835V3.53835C12.2695 3.10766 12.107 2.69031 11.8067 2.38137C11.5064 2.07243 11.0938 1.8982 10.6629 1.89838H9.61791C9.1958 1.89837 8.79109 2.06657 8.49333 2.36577C8.19557 2.66496 8.02931 3.07047 8.03134 3.49258V3.49258C8.01883 4.36408 7.30873 5.06399 6.43714 5.0639C6.16851 5.06111 5.90538 4.98743 5.67436 4.85032V4.85032C4.91172 4.41332 3.93926 4.67606 3.50045 5.43766L2.94362 6.35299C2.50534 7.11364 2.7645 8.08549 3.52333 8.5269V8.5269C4.01658 8.81168 4.32044 9.33797 4.32044 9.90753C4.32044 10.4771 4.01658 11.0034 3.52333 11.2882V11.2882C2.76546 11.7266 2.50602 12.6961 2.94362 13.4544V13.4544L3.46994 14.3621C3.67554 14.7331 4.0205 15.0069 4.42849 15.1228C4.83648 15.2388 5.27386 15.1874 5.64385 14.98V14.98C6.00757 14.7678 6.44099 14.7096 6.84778 14.8185C7.25457 14.9273 7.60103 15.1942 7.81013 15.5597C7.94725 15.7907 8.02092 16.0539 8.02371 16.3225V16.3225C8.02371 17.2029 8.73746 17.9167 9.61791 17.9167H10.6629C11.5404 17.9167 12.2529 17.2076 12.2571 16.3301V16.3301C12.2551 15.9067 12.4224 15.5 12.7218 15.2006C13.0212 14.9012 13.4279 14.7339 13.8513 14.7359C14.1193 14.7431 14.3814 14.8165 14.6141 14.9495V14.9495C15.3748 15.3878 16.3466 15.1286 16.788 14.3698V14.3698L17.3372 13.4544C17.5498 13.0896 17.6081 12.655 17.4993 12.247C17.3905 11.8389 17.1235 11.4911 16.7575 11.2805V11.2805C16.3915 11.0699 16.1245 10.7221 16.0157 10.3141C15.9069 9.90607 15.9652 9.4715 16.1778 9.10662C16.316 8.86526 16.5161 8.66514 16.7575 8.5269V8.5269C17.5118 8.08572 17.7703 7.11956 17.3372 6.36062V6.36062V6.35299Z'
						stroke='#130F26'
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
					<circle
						id='Ellipse_737'
						cx='10.146'
						cy='9.90755'
						r='2.1968'
						stroke='#130F26'
						strokeWidth='1.5'
						strokeLinecap='round'
						strokeLinejoin='round'
					/>
				</g>
			</g>
		</svg>
	);
}
