import { getLanguage } from "config/language";
import React from "react";

export default function TextBannerArea({ ekkLanguage }) {
  return (
    <div className="w-1/2 hidden lg:block">
      <div className="w-full ">
        <h1
          className={`  ${
            getLanguage() === "en"
              ? "lg:text-32 text-22 md:text-32"
              : "lg:text-32 text-20 md:text-32"
          } font-bold lg:text-262626-1000 pr-[0px] pb-4 text-262626-1000 text-center `}
        >
          {ekkLanguage.landing.header1}
        </h1>
        <h1
          className={`  ${
            getLanguage() === "en"
              ? "lg:text-32 text-18 md:text-32"
              : "lg:text-32 text-20 md:text-32"
          } font-extrabold lg:text-262626-1000 lg:text-right text-262626-1000 text-center flex flex-wrap justify-center`}
        >
          {ekkLanguage.landing.header2}{" "}
        </h1>
      </div>
    </div>
  );
}
