import { IonApp, IonButton, IonContent, IonPage } from "@ionic/react";
import CardBottomSheet from "components/mobile-components/AddCard/CardBottomSheet";
import CardDetails from "components/mobile-components/AddCard/CardDetails";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { getUserBusiness } from "config/user";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Payment from "services/Payment";

export default function CardAddPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const { methodType } = useParams();
	const [cardList, setCardList] = useState(null);
	const [cardLoading, setCardLoading] = useState(false);
	const business = getUserBusiness();
	const [selectedCard, setSelectedCard] = useState(null);
	const navigate = useNavigate();

	const getCardList = async () => {
		setCardLoading(true);
		let response = await Payment.getCards(business?.id);
		if (response.status === 200) {
			setCardList(response?.card);
			setCardLoading(false);
		} else {
			setCardList(null);
			setCardLoading(false);
		}
	};

	useEffect(() => {
		getCardList();
	}, []);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage?.addCard?.pageTitle} />
					<section className='pt-14 relative w-full h-full px-4'>
						<CardDetails
							cardList={cardList}
							cardLoading={cardLoading}
							getCardList={getCardList}
							setSelectedCard={setSelectedCard}
						/>
					</section>

					{/* Upgrade Button  */}
					<IonButton
						onClick={() => navigate(-1)}
						color='success'
						className='font-bold h-12 text-20 rounde-lg w-[95%] absolute bottom-5 left-2 right-2.5'>
						<span className='text-white'>
							{ekkLanguage?.addCard?.continue}
						</span>
					</IonButton>
					<CardBottomSheet
						selectedCard={selectedCard}
						getCardList={getCardList}
					/>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
