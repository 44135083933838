import React from "react";

export default function Bug({ color = "#F94B41" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='26'
			height='26'
			viewBox='0 0 26 26'
			fill='none'
			style={{ margin: "auto" }}>
			<path
				d='M22.75 6.5H19.5C19.5 4.77609 18.8152 3.12279 17.5962 1.90381C16.3772 0.684819 14.7239 0 13 0C11.2761 0 9.62279 0.684819 8.40381 1.90381C7.18482 3.12279 6.5 4.77609 6.5 6.5H3.25C2.38805 6.5 1.5614 6.84241 0.951903 7.4519C0.34241 8.0614 0 8.88805 0 9.75L0 20.5833C0.00172018 22.0194 0.572955 23.3961 1.5884 24.4116C2.60385 25.427 3.98061 25.9983 5.41667 26H20.5833C22.0194 25.9983 23.3961 25.427 24.4116 24.4116C25.427 23.3961 25.9983 22.0194 26 20.5833V9.75C26 8.88805 25.6576 8.0614 25.0481 7.4519C24.4386 6.84241 23.612 6.5 22.75 6.5ZM13 2.16667C14.1493 2.16667 15.2515 2.62321 16.0641 3.43587C16.8768 4.24853 17.3333 5.35073 17.3333 6.5H8.66667C8.66667 5.35073 9.12321 4.24853 9.93587 3.43587C10.7485 2.62321 11.8507 2.16667 13 2.16667ZM23.8333 20.5833C23.8333 21.4453 23.4909 22.2719 22.8814 22.8814C22.2719 23.4909 21.4453 23.8333 20.5833 23.8333H5.41667C4.55471 23.8333 3.72806 23.4909 3.11857 22.8814C2.50908 22.2719 2.16667 21.4453 2.16667 20.5833V9.75C2.16667 9.46268 2.2808 9.18713 2.48397 8.98397C2.68713 8.7808 2.96268 8.66667 3.25 8.66667H6.5V10.8333C6.5 11.1207 6.61414 11.3962 6.8173 11.5994C7.02047 11.8025 7.29602 11.9167 7.58333 11.9167C7.87065 11.9167 8.1462 11.8025 8.34937 11.5994C8.55253 11.3962 8.66667 11.1207 8.66667 10.8333V8.66667H17.3333V10.8333C17.3333 11.1207 17.4475 11.3962 17.6506 11.5994C17.8538 11.8025 18.1293 11.9167 18.4167 11.9167C18.704 11.9167 18.9795 11.8025 19.1827 11.5994C19.3859 11.3962 19.5 11.1207 19.5 10.8333V8.66667H22.75C23.0373 8.66667 23.3129 8.7808 23.516 8.98397C23.7192 9.18713 23.8333 9.46268 23.8333 9.75V20.5833Z'
				fill={color}
			/>
		</svg>
	);
}
