import React from "react";

export default function BpBranch() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'>
			<circle cx='20' cy='20' r='20' fill='#36A94D' fillOpacity='0.15' />
			<path
				d='M28.8125 19.3125H21.6875V12.1875C21.6875 11.5317 21.1558 11 20.5 11C19.8442 11 19.3125 11.5317 19.3125 12.1875V19.3125H12.1875C11.5317 19.3125 11 19.8442 11 20.5C11 21.1558 11.5317 21.6875 12.1875 21.6875H19.3125V28.8125C19.3125 29.4683 19.8442 30 20.5 30C21.1558 30 21.6875 29.4683 21.6875 28.8125V21.6875H28.8125C29.4683 21.6875 30 21.1558 30 20.5C30 19.8442 29.4683 19.3125 28.8125 19.3125Z'
				fill='#36A94D'
			/>
		</svg>
	);
}
