import AppLayout from 'components/global-components/AppLayout'
import TopBar from 'components/global-components/Navigation/TopBar'
import React from 'react'

export default function Users() {
  return (
    <AppLayout>
       <TopBar pageName="Users Data"/>
       <div></div>
    </AppLayout>
  )
}
