import { IonLoading } from "@ionic/react";
import React from "react";
import { useSelector } from "react-redux";
import MyOrderStatusBtn from "./MyOrderStatusBtn";
import MyOrderStatusCard from "./MyOrderStatusCard";

export default function MyOrderStatus({
	setOrderStatus,
	loading,
	buyer,
	setReachedEnd,
	setHasMoreData,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<>
			<MyOrderStatusBtn
				buyer={buyer}
				setOrderStatus={setOrderStatus}
				setReachedEnd={setReachedEnd}
				setHasMoreData={setHasMoreData}
			/>
			{loading ? (
				<IonLoading
					isOpen={true}
					message={ekkLanguage.orderList?.loader}></IonLoading>
			) : (
				<MyOrderStatusCard buyer={buyer} />
			)}
		</>
	);
}
