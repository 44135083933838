import React from "react";
import UpgradeSingleCard from "./UpgradeSingleCard";
import UpgrationBenefits from "./UpgrationBenefits";
import { IonButton } from "@ionic/react";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { clearSubscription } from "redux/container/SubscriptionSlice";

export default function UpgradePlan({ selected, subscriptions }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	return (
		<div className='px-4 pt-8 subscribe-plan pb-10 '>
			{/* Cards  */}
			<Tabs>
				<TabList>
					<Tab>
						<button>{ekkLanguage.paymentModule.annualPlan}</button>
					</Tab>
					<Tab>
						<button>{ekkLanguage.paymentModule.monthlyPlan}</button>
					</Tab>
				</TabList>

				<TabPanel>
					{subscriptions?.map((subscription) => {
						if (subscription.plan_type === "Yearly") {
							return (
								<UpgradeSingleCard
									key={subscription?.id}
									subscription={subscription}
									selected={selected}
								/>
							);
						}
					})}
				</TabPanel>
				<TabPanel>
					{subscriptions?.map((subscription) => {
						if (subscription.plan_type === "Monthly") {
							return (
								<UpgradeSingleCard
									key={subscription?.id}
									subscription={subscription}
									selected={selected}
								/>
							);
						}
					})}
				</TabPanel>
			</Tabs>

			{/* BenefitCard */}
			{selected?.id && <UpgrationBenefits selected={selected} />}

			{/* Upgrade Button  */}
			<IonButton
				onClick={() => {
					navigate("/select-payment-method-for-subscription");
				}}
				type='submit'
				color='success'
				className='font-bold h-[50px] text-20 rounded-lg w-[95%] left-2 right-2.5 absolute bottom-3'>
				<span className='text-white'>
					{ekkLanguage.paymentModule.upgradeNow}
				</span>
			</IonButton>
		</div>
	);
}
