import {
	IonApp,
	IonContent,
	IonLabel,
	IonPage,
	IonButton,
	IonCol,
	IonSpinner,
} from "@ionic/react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomCheckBox from "components/global-components/common/CustomCheckBox";
import DatePicker from "react-datepicker";
import Calendar from "assets/images/icon/svg/Calendar";
import moment from "moment";
import { getSelectedDate } from "redux/container/dashboardSlice";
import Select from "react-select";
import { dateTimeFormatter, digitChanger } from "helpers/helper";
import DashBoard from "services/Dashboard";
import { setDashboardBottomSheet } from "redux/container/userSlice";
import { setProductSummery } from "redux/container/ProductSummerySlice";
import Add from "assets/images/icon/svg/Add";

export default function OrderProductSummary({ businessData }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	// const [selectedDate, setSelectedDate] = useState(moment(new Date()));
	const [selectedDate, setSelectedDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [status, setStatus] = useState(null);
	const dateInfo = useSelector((state) => state.dashboard.dateInfo);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let [loading, setLoading] = useState(false);
	const [orderList, setOrderList] = useState({});
	let viewDate = moment(selectedDate).format("DD-MM-yyyy");

	const options = [
		// { value: "All", label: ekkLanguage.orderProductSummary.all },
		{ value: "Placed", label: ekkLanguage.orderList.tabMenuPlaced },
		{ value: "Accepted", label: ekkLanguage.orderList.tabMenuAccepted },
		{
			value: "Scheduled For Delivery",
			label: ekkLanguage.orderList.tabMenuScDelivered,
		},
		{
			value: "Delivery Failed",
			label: ekkLanguage.orderList.tabMenuDeliveryFailed,
		},
		{
			value: "Partially_Delivered",
			label: ekkLanguage.orderList.tabMenuPartialDelivered,
		},
		{ value: "Delivered", label: ekkLanguage.orderList.tabMenuDelivered },
		{ value: "Returned_Items", label: ekkLanguage.orderList.tabMenuReturn },
		{
			value: "Partially_Paid",
			label: ekkLanguage.orderList.tabMenuPartialPaid,
		},
		{ value: "Paid", label: ekkLanguage.orderList.tabMenuPaid },
		{ value: "Cancel", label: ekkLanguage.orderList.tabMenuCancel },
	];
	const OfferStartDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white h-10 w-10 rounded-[5px] ml-1 flex items-center justify-center`}
			onClick={onClick}>
			<Calendar></Calendar>
		</div>
	));
	const datePickerRef = useRef(null);
	const getOrderStatusResult = async (status, date) => {
		let res = await DashBoard.getOrderProductSummary(status, date);
		if (res.status === 200) {
			setLoading(false);
			setOrderList(res?.data);
		} else {
			setLoading(false);
			setOrderList([]);
		}
	};

	dispatch(setDashboardBottomSheet(false));
	{
	}
	useEffect(() => {
		let interval = setTimeout(() => {
			let dateVal = dateInfo?.date;
			let status = dateInfo?.status;
			setSelectedDate(dateVal);
			setStatus(status);
			if (dateVal) {
				getOrderStatusResult(status, dateVal);
			}
		}, 500);
		return () => clearTimeout(interval);
	}, [selectedDate, status, dispatch]);

	const selectStatusChanger = (e) => {
		setStatus(e.value);
		if (selectedDate) {
			getOrderStatusResult(e.value, selectedDate);
		}
		let dateStatus = {
			status: e.value,
			date: selectedDate,
		};
		dispatch(getSelectedDate(dateStatus));
	};
	const dateChanger = (date) => {
		setSelectedDate(date);
		getOrderStatusResult(status, date);
		let dateStatus = {
			status: status,
			date: date,
		};
		dispatch(getSelectedDate(dateStatus));
	};
	const handleNavigate = (product, catId) => {
		// localStorage.setItem("catId",catId)
		console.log(selectedDate);
		let data = {
			product,
			catId,
			status: status,
			date: selectedDate,
		};

		dispatch(setProductSummery(data));
		navigate(`/order-product-summary-details/${product}`);
	};
	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<div
			ref={ref}
			className={`w-40 h-10 border p-2.5 rounded relative
								${selectedDate
					? "border-[#00B224] font-bold text-[#00B224]"
					: "border-[#FF564C]"
				} custom-placeholder-style`}
			onClick={onClick}
		>
			<div className="flex">
				<span className="">
					{selectedDate ?
						<Calendar color={'#00B224'}></Calendar>
						: <Calendar color={'#FF564C'}></Calendar>
					}
				</span>
				<span>
					{selectedDate ?
						<h1 className="text-center pl-2.5 text-12 font-bold text-[#00B224] pt-0.5 ">{viewDate}</h1> : <h1 className="text-center pl-2.5 text-12 font-normal text-[#FF564C] pt-0.5 ">Select Date</h1>
					}

				</span>
				<span className="absolute right-0 mr-2.5">
					{selectedDate ?
						<Add color={'#00B224'}></Add>
						: <Add color={'#FF564C'}></Add>
					}
				</span>
			</div>

		</div>
	));

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className='pt-20'>
						<div className='flex justify-around'>
							<div
								className={`flex text-center text-12 w-40 relative rounded items-center 
                          `}>
								<DatePicker
									onFocus={(e) => (e.target.readOnly = true)}

									placeholderText='Select Date'
									selected={selectedDate}
									onChange={(date) => dateChanger(date)}
									customInput={<ExampleCustomInput />}
									withPortal
								/>
							</div>
							<div>
								<Select
									onFocus={(e) => (e.target.readOnly = true)}
									defaultValue={options.filter(
										(el) => el.value === dateInfo.status
									)}
									options={options}
									onChange={selectStatusChanger}
									placeholder="Select Status"
									styles={{
										control: (baseStyles, state) => ({
											...baseStyles,
											width: 156,
											height: 40,
											border: status ? "0.5px solid #00B224" : "1px solid #FF564C",
											outLine: "none",
											fontWeight: 500,
											fontSize: 12,
											color: 'blue',
											borderRadius: 5,


										}),
										placeholder: (baseStyles, state) => ({
											...baseStyles,
											color: "#FF564C",
										}),
										singleValue: (baseStyles, state) => ({
											...baseStyles,
											color: '#00B224',
											fontWeight: 700,
										}),
										indicatorSeparator: (baseStyles, state) => ({
											...baseStyles,
											display: "none",
										}),
										dropdownIndicator: (baseStyles, state) => ({
											...baseStyles,
											color: status ? "#00B224" : "#FF564C",
										}),
									}}
									theme={(theme) => ({
										...theme,
										borderRadius: 5,
										colors: {
											...theme.colors,
											primary25: "rgb(204, 255, 214)",
											primary: "#00FF33",
										},
									})}
								/>
							</div>
						</div>

						<div className='mt-10'>
							<table
								className='table-auto w-full bg-white'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='border p-1 font-bold text-12'>
											S/N
										</th>
										<th className='border  font-bold text-12'>
											{ekkLanguage.orderSummary.productTitle}
										</th>
										<th className='border p-1 font-bold text-12'>
											{ekkLanguage.orderSummary.qty}
										</th>
										{/* <th className='border p-1 font-bold text-14'>
                                            {ekkLanguage.orderSummary.orderNo}
                                        </th> */}
									</tr>
								</thead>
								<tbody>
									{orderList?.length
										? orderList?.map((el) => {
											return el?.item.map((e, i) => {
												return (
													<tr
														className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
													>
														<td className='border p-2 w-14 font-semibold text-10'>
															{digitChanger(i + 1)}
														</td>
														<td onClick={() =>
															handleNavigate(
																e.productTitle,
																e.catalogId
															)
														} className='border text-[#0099CC] font-semibold p-2 text-10'>
															{e.productTitle}
														</td>
														<td className='border font-semibold p-2 text-10'>
															{digitChanger(e.quantity)}
														</td>
													</tr>
												);
											});
										})
										: ""}
								</tbody>
							</table>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									{" "}
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								""
							)}
							{orderList?.length === 0 && (
								<>
									<IonCol size='2'></IonCol>
									<IonCol size='8'>
										<div
											className='text-center flex items-center justify-center'
											style={{ height: "calc(100vh - 420px)" }}>
											<div>
												<img
													className='m-auto mb-[10px] mt-5'
													src='/assets/images/not-found-file.png'
													alt='emptyCard'
												/>
												<p className='text-16 font-normal text-222222 mb-[35px]'>
													{ekkLanguage.orderList.notFound}
												</p>
											</div>
										</div>
									</IonCol>
								</>
							)}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
