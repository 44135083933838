import React from "react";

export default function Tax() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'>
			<g clipPath='url(#clip0_1426_531)'>
				<path
					d='M9 17V19C9 19.552 8.552 20 8 20C7.448 20 7 19.552 7 19V17C7 16.448 7.448 16 8 16C8.552 16 9 16.448 9 17ZM16 14C15.447 14 15 14.448 15 15V19C15 19.552 15.447 20 16 20C16.553 20 17 19.552 17 19V15C17 14.448 16.553 14 16 14ZM12 11C11.448 11 11 11.448 11 12V19C11 19.552 11.448 20 12 20C12.552 20 13 19.552 13 19V12C13 11.448 12.552 11 12 11ZM22 10.485V19C22 21.757 19.757 24 17 24H7C4.243 24 2 21.757 2 19V5C2 2.243 4.243 0 7 0H11.515C13.384 0 15.142 0.728 16.465 2.05L19.949 5.536C21.271 6.857 22 8.615 22 10.486V10.485ZM15.051 3.464C14.733 3.146 14.38 2.877 14 2.659V7C14 7.551 14.448 8 15 8H19.341C19.123 7.621 18.853 7.268 18.536 6.95L15.052 3.464H15.051ZM20 10.485C20 10.322 19.992 10.16 19.977 10H15C13.346 10 12 8.654 12 7V2.023C11.84 2.008 11.678 2 11.515 2H7C5.346 2 4 3.346 4 5V19C4 20.654 5.346 22 7 22H17C18.654 22 20 20.654 20 19V10.485Z'
					fill='black'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1426_531'>
					<rect width='24' height='24' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
