import { IonApp, IonContent, IonLabel, IonPage, IonButton, IonCol, IonSpinner } from "@ionic/react";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { dateTimeFormatter, digitChanger } from "helpers/helper";
import DashBoard from "services/Dashboard";
import { getLanguage } from "config/language";

export default function OrderProductSummaryDetails() {
    const ekkLanguage = useSelector((state) => state.lan.lan);
    const { catId, date, status } = useSelector(state => state.productSummery.productData)
    const { product } = useParams()
    let [loading, setLoading] = useState(false);
    const [orderList, setOrderList] = useState({});
    let viewDate = moment(date).format("DD-MM-yyyy")
    let localTime = moment(date).utc()
    const language = getLanguage()


    const getOrderStatusResult = async (status, date, catId) => {
        let res = await DashBoard.getProductSummaryDrilldown(
            status, date, catId
        );
        if (res.status === 200) {
            setLoading(false);
            setOrderList(res?.data);
        } else {
            setLoading(false);
            setOrderList([]);
        }
    }

    useEffect(() => {
        getOrderStatusResult(status, localTime._d, catId);
    }, []);


    return (
        <IonApp>
            <IonPage>
                <IonContent>
                    <div className="pt-20">
                        <div className="flex border mx-3 py-1  justify-around items-center flex-col">
                           

                            <span className="text-center"><span className="font-bold">{ekkLanguage.orderSummary.date} :</span> {digitChanger(viewDate)}</span>   <span className="text-center"><span className="font-bold">{ekkLanguage.orderSummary.status}  :</span> {
                                (status === "Placed") ? ekkLanguage.orderList.tabMenuPlaced :
                                    (status === "Accepted") ? ekkLanguage.orderList.tabMenuAccepted :
                                        (status === "Scheduled For Delivery") ? ekkLanguage.orderList.tabMenuScDelivered :
                                            (status === "Delivery Failed") ? ekkLanguage.orderList.tabMenuDeliveryFailed :
                                                (status === "Partially_Delivered") ? ekkLanguage.orderList.tabMenuPartialDelivered :
                                                    (status === "Delivered") ? ekkLanguage.orderList.tabMenuDelivered :
                                                        (status === "Returned_Items") ? ekkLanguage.orderList.tabMenuReturn :
                                                            (status === "Partially_Paid") ? ekkLanguage.orderList.tabMenuPartialPaid :
                                                                (status === "Paid") ? ekkLanguage.orderList.tabMenuPaid :
                                                                    ekkLanguage.orderList.tabMenuCancel
                            }</span>
                            <span className=" text-center"><span className="font-bold">{ekkLanguage.orderSummary.product}  :</span> {product}</span>

                            {/* <Select
                                defaultValue={options.filter(
                                    (el) => el.value === dateInfo.level
                                )}
                                options={options}
                                onChange={selectStatusChanger}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: 120,
                                        height: 40,
                                        border: "none",
                                        outLine: "none",
                                        fontWeight: 600,
                                        fontSize: 12,
                                        borderRadius: 5,
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 5,
                                    colors: {
                                        ...theme.colors,
                                        primary25: "rgb(204, 255, 214)",
                                        primary: "#00FF33",
                                    },
                                })}
                            /> */}
                        </div>

                        <div className="mt-10">
                            <table
                                className='table-auto w-full bg-white'
                                style={{ boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)" }}>
                                <thead>
                                    <tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
                                        <th className='border p-1 font-bold text-14'>S/N</th>

                                        <th className='border p-1 font-bold text-14'>
                                            {ekkLanguage.orderSummary.qty}
                                        </th>
                                        <th className='border p-1 font-bold text-14'>
                                            {ekkLanguage.orderSummary.orderNo}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orderList?.length
                                        ? orderList?.map((el, i) => {
                                            return (
                                                <tr
                                                    className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
                                                    key={i}>

                                                    <td className='border  font-semibold text-12'>
                                                        {digitChanger(i + 1)}
                                                    </td>


                                                    <td className='border  font-semibold text-12'>

                                                        {digitChanger(el.quantity)}

                                                    </td>
                                                    <td className='border  font-semibold text-12'>

                                                        {digitChanger(el.orderId)}

                                                    </td>


                                                </tr>
                                            );
                                        })
                                        : ""}
                                </tbody>
                            </table>
                            {loading ? (
                                <div className='flex items-center justify-center h-[156px] m-auto'>
                                    {" "}
                                    <IonSpinner name='crescent'></IonSpinner>
                                </div>
                            ) : (
                                ""
                            )}
                            {orderList?.length === 0 && (
                                <>
                                    <IonCol size='2'></IonCol>
                                    <IonCol size='8'>
                                        <div
                                            className='text-center flex items-center justify-center'
                                            style={{ height: "calc(100vh - 420px)" }}>
                                            <div>
                                                <img
                                                    className='m-auto mb-[10px] mt-5'
                                                    src='/assets/images/not-found-file.png'
                                                    alt='emptyCard'
                                                />
                                                <p className='text-16 font-normal text-222222 mb-[35px]'>
                                                    {ekkLanguage.orderList.notFound}
                                                </p>
                                            </div>
                                        </div>
                                    </IonCol>
                                </>
                            )}
                        </div>
                    </div>
                </IonContent>
            </IonPage>
        </IonApp>
    );
}
