import React from "react";

export default function LeftArrow2() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='10'
			height='8'
			viewBox='0 0 10 8'
			fill='none'>
			<path
				d='M8.66667 3.33337H2L4.19333 1.14004C4.25582 1.07806 4.30541 1.00433 4.33926 0.92309C4.37311 0.841851 4.39053 0.754714 4.39053 0.666706C4.39053 0.578698 4.37311 0.491561 4.33926 0.410322C4.30541 0.329082 4.25582 0.255348 4.19333 0.193373C4.06842 0.069206 3.89946 -0.000488281 3.72333 -0.000488281C3.54721 -0.000488281 3.37824 0.069206 3.25333 0.193373L0.393333 3.06004C0.142942 3.30894 0.00148853 3.64698 0 4.00004V4.00004C0.00324439 4.35077 0.144563 4.6861 0.393333 4.93337L3.25333 7.80003C3.31549 7.86175 3.3892 7.91063 3.47025 7.94386C3.55129 7.9771 3.63809 7.99404 3.72569 7.99373C3.81329 7.99343 3.89996 7.97586 3.98077 7.94206C4.06159 7.90825 4.13495 7.85886 4.19667 7.7967C4.25839 7.73454 4.30726 7.66083 4.3405 7.57978C4.37373 7.49874 4.39068 7.41194 4.39037 7.32434C4.39006 7.23674 4.3725 7.15007 4.33869 7.06926C4.30489 6.98845 4.25549 6.91509 4.19333 6.85337L2 4.6667H8.66667C8.84348 4.6667 9.01305 4.59646 9.13807 4.47144C9.2631 4.34641 9.33333 4.17685 9.33333 4.00004C9.33333 3.82322 9.2631 3.65366 9.13807 3.52863C9.01305 3.40361 8.84348 3.33337 8.66667 3.33337Z'
				fill='black'
			/>
		</svg>
	);
}
