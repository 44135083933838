import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function PaymentDetails() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div>
			<h4 className='text-20 text-black-1000 font-bold mb-[10px] pt-4'>
				{ekkLanguage.businessProfile.paymentDetailsLabel}
			</h4>
			<div>
				<Card title={ekkLanguage.businessProfile.bankInfoLabel}>
					<div className='px-2'>
						<p className='pt-3 text-12'>
							{ekkLanguage.businessProfile.bankInfoNotFound}
						</p>
						{/* <table className='table-fixed w-full'>
							<tbody>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.bankName}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										Islami Bank Limited Bangladesh
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.branchName}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										Krishi Market
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.branchAddress}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										Mohammadpur, Dhaka-1207
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.swiftCode}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										{digitChanger(Number(42345455))}
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.acName}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										MD ZAHIDUL HOSSAIN
									</td>
								</tr>
								<tr>
									<td className='text-000080 text-12 font-medium py-[7px]'>
										{ekkLanguage.businessProfile.acNumber}
									</td>
									<td className='text-000080 text-12 font-medium py-[7px] text-right'>
										{digitChanger(Number(42345455))}
									</td>
								</tr>
							</tbody>
						</table> */}
					</div>
				</Card>
			</div>
		</div>
	);
}
