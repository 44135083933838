import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    singleNotification: {},
    data: [],
    customerUnreadCount: 0,
    supplierUnreadCount: 0,
    totalUnreadCount: 0,
  },
  reducers: {
    singleNotification: (state, action) => {
      state.singleNotification = action.payload;
    },
    setCustomerUnreadCount: (state, action) => {
      state.customerUnreadCount = action.payload;
    },
    setSupplierUnreadCount: (state, action) => {
      state.supplierUnreadCount = action.payload;
    },
    setTotalUnreadCount: (state) => {
      state.totalUnreadCount =
        state.supplierUnreadCount + state.customerUnreadCount;
    },
    setNotification: (state, action) => {
      state.data = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  singleNotification,
  setTotalUnreadCount,
  setCustomerUnreadCount,
  setSupplierUnreadCount,
  setNotification,
} = notificationSlice.actions;

export const { actions, reducer } = notificationSlice;

export const selectTotalUnreadCount = (state) =>
  state.notification.supplierUnreadCount +
  state.notification.customerUnreadCount;

export default reducer;
