import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import { addStatusClass, digitChanger, statusTranslator } from "helpers/helper";
import moment from "moment";

export default function OrderMetaBar({ order }) {
	return (
		<div
			className={`p-4 bg-${addStatusClass(
				order?.order_status
			)} fixed left-0 top-14 w-full z-50`}>
			<ul className='flex justify-between'>
				<li>
					<p className='text-14 text-white font-bold'>
						{digitChanger(order?.order_id)}
					</p>
				</li>
				<li>
					<p className='text-14 text-white font-bold'>
						{statusTranslator(order?.order_status)}
						{/* {order?.order_status} */}
					</p>
				</li>
				<li>
					<p className='text-14 text-white font-bold'>
						{digitChanger(moment(order?.createdAt).format("DD/MM/YYYY"))}
					</p>
				</li>
			</ul>
		</div>
	);
}
