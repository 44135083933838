import React from "react";

export default function PostalCode() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='18'
			viewBox='0 0 24 18'
			fill='none'>
			<path
				d='M18 -0.000187202H9.883C11.227 1.31381 12 3.11781 12 4.99981V14.9998C12 16.1298 11.609 17.1618 10.974 17.9998H21C22.654 17.9998 24 16.6538 24 14.9998V5.99981C24 2.69081 21.309 -0.000187202 18 -0.000187202ZM21 8.99981C20.994 10.3078 19.005 10.3068 19 8.99981V7.99981H16C14.692 7.99381 14.693 6.00481 16 5.99981H20C20.553 5.99981 21 6.44781 21 6.99981V8.99981ZM4.501 0.0238127C1.903 0.277813 0 2.61181 0 5.22181V14.9998C0 16.6568 1.343 17.9998 3 17.9998H7C8.657 17.9998 10 16.6568 10 14.9998V4.99981C10 2.07781 7.481 -0.266187 4.501 0.0238127ZM6 7.99981H4C2.692 7.99381 2.693 6.00581 4 5.99981H6C7.308 6.00581 7.307 7.99381 6 7.99981Z'
				fill='black'
			/>
		</svg>
	);
}
