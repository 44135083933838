import React from "react";
import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useSelector } from "react-redux";
import CreateEmployees from "components/mobile-components/CreateEmployees";

export default function CreateEmployeePage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.createEmployees.pageTitle} />
					<CreateEmployees />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
