import React from "react";
import { useSelector } from "react-redux";

export default function SingleTransaction({ item }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div
			className='rounded-[10px] bg-white mb-4 py-2 px-2'
			style={{ boxShadow: "1px 2px 6px rgba(0,0,0,.2)" }}>
			<div className='flex justify-between items-center'>
				<div>
					<p className='font-14'>
						{ekkLanguage.paymentModule.card}:{" "}
						<span className='font-bold text-16'>
							{item?.card?.card?.brand}
						</span>
					</p>
					<p>
						{ekkLanguage.paymentModule.cardNumber}:{" "}
						<span className='font-bold text-12'>
							**** {item?.card?.card?.last4}
						</span>
					</p>
					<p>
						{ekkLanguage.paymentModule.status}:{" "}
						<span className='text-success font-bold text-12'>
							{item?.payment_status}
						</span>
					</p>
				</div>
				<div>
					{" "}
					<h4 className='font-bold text-success text-center'>
						{item?.country?.currency_symbol_native}
						{item?.amount}
					</h4>
					<a
						target='_blank'
						className='font-bold border border-success text-12 text-center mt-2 py-1 px-3 rounded-[5px] block'
						href={item?.ekkbaz_invoice}>
						{ekkLanguage.paymentModule.invoice}
					</a>
				</div>
			</div>
		</div>
	);
}
