import AppLayout from "components/global-components/AppLayout";
import TopBar from "components/global-components/Navigation/TopBar";
import HomeWrapper from "components/pages-components/Home/HomeWrapper";
import React, { useEffect } from "react";
import { getLocalUser } from "config/user";

export default function Home() {
	function checkBusiness() {
		const business = getLocalUser();
		console.log(business?.associated_businesses);
	}

	useEffect(() => {
		checkBusiness();
	}, []);
	return (
		<AppLayout>
			<section className='py-3 px-3'>
				<TopBar pageName='Dashboard' />
				<HomeWrapper />
			</section>
		</AppLayout>
	);
}
