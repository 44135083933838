import React from "react";

export default function Admin() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='15'
			height='18'
			viewBox='0 0 15 18'
			fill='none'>
			<path
				d='M9.10714 10.6875H5.89286C2.63839 10.6875 0 13.4578 0 16.875C0 17.4962 0.479799 18 1.07143 18H13.9286C14.5202 18 15 17.4962 15 16.875C15 13.4578 12.3616 10.6875 9.10714 10.6875ZM1.64029 16.3125C1.9048 14.0941 3.70982 12.375 5.89286 12.375H9.10714C11.2888 12.375 13.0949 14.0959 13.3594 16.3125H1.64029ZM7.5 9C9.86685 9 11.7857 6.9852 11.7857 4.5C11.7857 2.0148 9.86685 0 7.5 0C5.13315 0 3.21429 2.0148 3.21429 4.5C3.21429 6.98555 5.13281 9 7.5 9ZM7.5 1.6875C8.9769 1.6875 10.1786 2.94926 10.1786 4.5C10.1786 6.05074 8.9769 7.3125 7.5 7.3125C6.0231 7.3125 4.82143 6.05039 4.82143 4.5C4.82143 2.94926 6.02344 1.6875 7.5 1.6875Z'
				fill='black'
			/>
		</svg>
	);
}
