import React from "react";

export default function Youtube() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='26'
			height='21'
			viewBox='0 0 26 21'
			fill='none'>
			<g clipPath='url(#clip0_127_155)'>
				<path
					d='M25.2417 5.01659C25.2417 4.78325 24.9167 2.91659 24.1583 2.09992C23.1833 0.933252 22.1 0.816585 21.5583 0.816585H21.45C18.0917 0.583252 13.1083 0.583252 13 0.583252C13 0.583252 7.90832 0.583252 4.54999 0.816585H4.44166C3.89999 0.816585 2.81666 0.933252 1.84166 2.09992C1.08332 3.03325 0.758323 4.89992 0.758323 5.13325C0.758323 5.24992 0.541656 7.34992 0.541656 9.56659V11.5499C0.541656 13.7666 0.758323 15.8666 0.758323 15.9833C0.758323 16.2166 1.08332 18.0833 1.84166 18.8999C2.70832 19.9499 3.79166 20.0666 4.44166 20.1833C4.54999 20.1833 4.65832 20.1833 4.76666 20.1833C6.71666 20.4166 12.675 20.4166 12.8917 20.4166C12.8917 20.4166 17.9833 20.4166 21.3417 20.1833H21.45C21.9917 20.0666 23.075 19.9499 24.05 18.8999C24.8083 17.9666 25.1333 16.0999 25.1333 15.8666C25.1333 15.7499 25.35 13.6499 25.35 11.4333V9.44992C25.4583 7.34992 25.2417 5.13325 25.2417 5.01659ZM17.225 10.7333L10.725 14.4666C10.6167 14.4666 10.6167 14.5833 10.5083 14.5833C10.4 14.5833 10.2917 14.5833 10.2917 14.4666C10.1833 14.3499 10.075 14.2333 10.075 13.9999V6.41659C10.075 6.18325 10.1833 6.06659 10.2917 5.94992C10.4 5.83325 10.6167 5.83325 10.8333 5.94992L17.3333 9.68325C17.55 9.79992 17.6583 9.91659 17.6583 10.1499C17.6583 10.3833 17.4417 10.6166 17.225 10.7333Z'
					fill='black'
					fillOpacity='0.3'
				/>
			</g>
			<defs>
				<clipPath id='clip0_127_155'>
					<rect width='26' height='21' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
