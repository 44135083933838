import Nav from "components/mobile-components/Nav";
import React from "react";

function Dashboard() {
  return (
    <>
      <Nav />
    </>
  );
}

export default Dashboard;
