import { createSlice } from "@reduxjs/toolkit";
// import { shoppingCart } from "helpers/shoppingCart";

export const shoppingCartSlice = createSlice({
	name: "shoppingCart",
	initialState: {
		shopCart: [],
		total: 0,
		subtotal: 0,
	},
	reducers: {
		getCartList: (state, action) => {
			state.shopCart = action.payload;
		},

		addToCart: (state, action) => {
			// state.value -= 1;
			console.log(action);
		},
		totalAmount: (state, action) => {
			state.total = action.payload;
		},
		subtotalAmount: (state, action) => {
			state.subtotal = action.payload;
		},

		// incrementByAmount: (state, action) => {
		//   state.value += action.payload;
		// },
	},
});

// Action creators are generated for each case reducer function
export const { addToCart, getCartList, totalAmount, subtotalAmount } =
	shoppingCartSlice.actions;
export const { actions, reducer } = shoppingCartSlice;
export default reducer;
