import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import ChangePassword from "components/mobile-components/Profile/ChangePassword";
import React from "react";
import { useSelector } from "react-redux";

export default function ChangePasswordPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.changePassword.pageTitle} />
					<ChangePassword />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
