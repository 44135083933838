import React from "react";

export default function VerifyCheck() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'>
			<rect width='24' height='24' rx='12' fill='white' />
			<g clipPath='url(#clip0_1491_65)'>
				<path
					d='M9.87459 16.3316C9.53396 16.3317 9.20728 16.1963 8.96662 15.9553L6.22153 13.2112C5.92616 12.9157 5.92616 12.4368 6.22153 12.1413C6.517 11.8459 6.99595 11.8459 7.29142 12.1413L9.87459 14.7245L16.7086 7.89048C17.004 7.5951 17.483 7.5951 17.7785 7.89048C18.0738 8.18594 18.0738 8.6649 17.7785 8.96037L10.7826 15.9553C10.5419 16.1963 10.2152 16.3317 9.87459 16.3316Z'
					fill='#00B224'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1491_65'>
					<rect
						width='12'
						height='12'
						fill='white'
						transform='translate(6 6)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
