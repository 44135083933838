import React from "react";

export default function Profit() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<path
				d='M4.40259 0.918091C2.34128 0.918091 0.665771 2.58855 0.665771 4.64463C0.665771 5.86714 1.26703 6.94564 2.18042 7.62708H1.57983V8.0035V9.50643H0.451904V9.88441V12.1358H1.57983V13.2609H0.451904V13.6388V15.8902H7.22827V14.0152H8.35767V11.3873H7.22827V10.2549H8.35767V7.62708H6.62769C7.54267 6.94564 8.14526 5.86714 8.14526 4.64463C8.14526 2.58855 6.4639 0.918091 4.40259 0.918091ZM4.02759 2.59822H4.78345V2.97473V3.10783H5.20972H5.58765V3.85643H5.20972H3.97339V4.27237H5.58765V6.18551H4.78345V6.3187V6.69512H4.02759V6.3187V6.18551H3.59985H3.22339V5.43699H3.59985H4.83765V5.02097H3.22339V3.10783H4.02759V2.97473V2.59822ZM2.32983 8.3814H7.60767V9.50643H7.22827H2.32983V8.3814ZM2.32983 12.1358H7.22827H7.60767V13.2609H7.22827H2.32983V12.1358Z'
				fill='black'
			/>
			<path
				d='M12.3122 0.109802L9.07629 4.08617H9.86581H10.9611L11 12.5103H13.6299V4.08617H15.5481L12.3122 0.109802ZM12.3122 1.30216L13.9746 3.33748H12.8793V11.7616H11.7451L11.7062 3.33748H10.6553L12.3122 1.30216Z'
				fill='black'
			/>
			<path
				d='M11 13.2645V14.0132H11.3781H13.2462H13.6243V13.2645H13.2462H11.3781H11Z'
				fill='black'
			/>
			<path
				d='M11 14.7675V15.5161H11.3781H13.2462H13.6243V14.7675H13.2462H11.3781H11Z'
				fill='black'
			/>
		</svg>
	);
}
