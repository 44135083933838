import React from "react";

export default function Plus() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='12'
			height='12'
			viewBox='0 0 12 12'
			fill='none'>
			<path
				d='M10.4133 5.125H6.875V1.58672C6.875 1.12461 6.48398 0.75 6 0.75C5.51602 0.75 5.125 1.12461 5.125 1.58672V5.125H1.58672C1.12461 5.125 0.75 5.51602 0.75 6C0.75 6.48398 1.12461 6.875 1.58672 6.875H5.125V10.4133C5.125 10.8754 5.51602 11.25 6 11.25C6.48398 11.25 6.875 10.8754 6.875 10.4133V6.875H10.4133C10.8754 6.875 11.25 6.48398 11.25 6C11.25 5.51602 10.8754 5.125 10.4133 5.125Z'
				fill='white'
			/>
		</svg>
	);
}
