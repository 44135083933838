import {
    IonApp,
    IonButton,
    IonCol,
    IonContent,
    IonPage,
    IonRow,
  } from "@ionic/react";
  import React from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { Link } from "react-router-dom";
  import { logout } from "redux/container/authSlice";
  
  export default function BlackListedPage() {
    const ekkLanguage = useSelector((state) => state.lan.lan);
    const dispatch = useDispatch();
  
    // handle navigation
    const handleNavigate = () => {
      localStorage.clear();
      window.location.reload(false);
      window.location.href = "/";
    };
  
    return (
      <IonApp>
        <IonPage className="business-location">
          <IonContent className="h-screen relative">
            <div className="pt-9 pb-4 shadow-sm h-20 bg-[#FF564C]">
              <h1 className="text-center text-[#FFF] text-20 font-bold">
                {ekkLanguage.blackListed.pageTitle}
              </h1>
            </div>
            <div className="pt-10 flex justify-center">
              <img
                className="h-60 w-60"
                src="assets/images/black-listed.png"
                alt=""
              />
            </div>
            <h2 className="px-5 pt-5 font-bold text-16 text-[#222] text-center">
              {ekkLanguage.blackListed.warningMsg}
            </h2>
            <h4 className="px-7 pt-8 text-[#FF564CCC] font-semibold text-14 text-[#222] text-center">
              {ekkLanguage.blackListed.instrMsg}
            </h4>
            <div className="submit-button-wrapper mt-48">
              <IonRow>
                <IonCol size="12">
                  {/* <Link
                    className="text-center"
                    onClick={() => {
                    //   dispatch(logout());
                    }}
                    to="/#"
                  > */}
                    <IonButton
                    onClick={handleNavigate}
                      expand="full"
                      className="bg-primary rounded-[7px] font-extrabold text-12 h-10"
                    >
                      {ekkLanguage.blackListed.btnLabel}
                    </IonButton>
                  {/* </Link> */}
                </IonCol>
              </IonRow>
            </div>
          </IonContent>
        </IonPage>
      </IonApp>
    );
  }