import React from "react";

export default function Print() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'>
			<circle cx='20' cy='20' r='20' fill='#36A94D' fillOpacity='0.15' />
			<path
				d='M22.8087 10.55H14.3387C13.9605 10.55 13.5977 10.7003 13.3302 10.9678C13.0628 11.2353 12.9125 11.598 12.9125 11.9763V20.7963C12.6861 20.8667 12.4878 21.0071 12.3463 21.1974C12.2048 21.3877 12.1273 21.6179 12.125 21.855V25.355C12.1273 25.5922 12.2048 25.8224 12.3463 26.0127C12.4878 26.203 12.6861 26.3434 12.9125 26.4138V28.05C12.9194 28.4237 13.0727 28.7798 13.3394 29.0416C13.6061 29.3035 13.965 29.4501 14.3387 29.45H26.4488C26.827 29.45 27.1898 29.2998 27.4573 29.0323C27.7247 28.7648 27.875 28.4021 27.875 28.0238V15.625L22.8087 10.55ZM13.245 25.6613C13.1568 25.6613 13.0722 25.6263 13.0099 25.5639C12.9475 25.5016 12.9125 25.417 12.9125 25.3288V21.8288C12.9125 21.7406 12.9475 21.656 13.0099 21.5937C13.0722 21.5313 13.1568 21.4963 13.245 21.4963H24.375C24.464 21.4963 24.5495 21.531 24.6133 21.5931C24.677 21.6553 24.714 21.7398 24.7162 21.8288V25.3288C24.714 25.4178 24.677 25.5023 24.6133 25.5645C24.5495 25.6266 24.464 25.6613 24.375 25.6613H13.245ZM27.0875 28.0238C27.0875 28.1932 27.0202 28.3557 26.9004 28.4755C26.7806 28.5952 26.6182 28.6625 26.4488 28.6625H14.3387C14.1693 28.6625 14.0069 28.5952 13.8871 28.4755C13.7673 28.3557 13.7 28.1932 13.7 28.0238V26.4488H24.375C24.5228 26.45 24.6694 26.4218 24.8063 26.3661C24.9432 26.3103 25.0677 26.228 25.1726 26.1239C25.2776 26.0198 25.3608 25.8959 25.4177 25.7594C25.4745 25.623 25.5038 25.4766 25.5038 25.3288V21.8288C25.4969 21.5348 25.3746 21.2553 25.1634 21.0507C24.9523 20.846 24.6691 20.7327 24.375 20.735H13.7V11.985C13.7 11.8156 13.7673 11.6532 13.8871 11.5334C14.0069 11.4136 14.1693 11.3463 14.3387 11.3463H22.4762L22.6425 11.5125V14.75C22.6425 15.0239 22.7513 15.2865 22.9449 15.4801C23.1385 15.6738 23.4012 15.7825 23.675 15.7825H26.9212L27.0875 15.9488V28.0238Z'
				fill='#36A94D'
			/>
			<path
				d='M17.0424 22.38C16.8432 22.2959 16.6286 22.2542 16.4124 22.2575H15.2224V24.9963H15.8612V24.2438H16.4124C16.6286 24.2471 16.8432 24.2054 17.0424 24.1213C17.2097 24.0499 17.3526 23.9313 17.4537 23.78C17.5513 23.6223 17.603 23.4405 17.603 23.255C17.603 23.0695 17.5513 22.8877 17.4537 22.73C17.3507 22.5783 17.2086 22.4574 17.0424 22.38V22.38ZM16.8062 23.605C16.6819 23.6946 16.5302 23.738 16.3774 23.7275H15.8612V22.7738H16.3774C16.5318 22.7617 16.685 22.8086 16.8062 22.905C16.8579 22.9466 16.8985 23.0003 16.9245 23.0613C16.9504 23.1224 16.9609 23.1889 16.9549 23.255C16.9601 23.321 16.9493 23.3873 16.9234 23.4482C16.8975 23.5092 16.8573 23.563 16.8062 23.605V23.605Z'
				fill='#36A94D'
			/>
			<path
				d='M20.0788 22.4325C19.8335 22.3136 19.5639 22.2537 19.2913 22.2575H18.0488V24.9962H19.2913C19.5632 25.0028 19.8328 24.9458 20.0788 24.83C20.2952 24.7303 20.4799 24.5729 20.6126 24.375C20.7385 24.1568 20.8048 23.9094 20.8048 23.6575C20.8048 23.4056 20.7385 23.1581 20.6126 22.94C20.4875 22.7227 20.3021 22.5464 20.0788 22.4325ZM19.9126 24.2525C19.8256 24.3321 19.7233 24.3933 19.6121 24.4324C19.5008 24.4715 19.3828 24.4877 19.2651 24.48H18.6788V22.7825H19.2651C19.3828 22.7748 19.5008 22.791 19.6121 22.8301C19.7233 22.8692 19.8256 22.9304 19.9126 23.01C19.996 23.0896 20.0611 23.1864 20.1034 23.2937C20.1457 23.401 20.1642 23.5161 20.1576 23.6312C20.1636 23.7463 20.1448 23.8613 20.1025 23.9685C20.0603 24.0756 19.9955 24.1725 19.9126 24.2525V24.2525Z'
				fill='#36A94D'
			/>
			<path
				d='M21.2776 24.9964H21.9076V23.9989H23.1763V23.4914H21.9076V22.7739H23.3426V22.2664H21.2776V24.9964Z'
				fill='#36A94D'
			/>
		</svg>
	);
}
