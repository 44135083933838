import React from "react";

const TopBar = ({ pageName }) => {
	return (
		<>
			<div className='iq-top-navbar bg-white px-3 py-3 items-center sc-htpNat bwaMp'>
				<div className='iq-navbar-custom'>
					{/* <Navbar expand="lg" variant="light" className="p-0">
                        <h4>{pageName}</h4>
                    </Navbar> */}
				</div>
			</div>
		</>
	);
};

export default TopBar;
