import {
	IonButton,
	IonCol,
	IonItem,
	IonLabel,
	IonRadio,
	IonRadioGroup,
	IonRow,
} from "@ionic/react";
import ArrayRight from "assets/images/icon/svg/ArrayRight";
import Check from "assets/images/icon/svg/Check";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ImageUploader from "./ImageUploader";
import User from "services/User";
import { setPropertyData } from "redux/container/businessSettingsSlice";

export default function Property() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const navigate = useNavigate();
	const [files, setFiles] = useState("");
	const [rentalProperty, setRentalProperty] = useState(true);
	const [property, setProperty] = useState("Rental Shop");
	const dispatch = useDispatch();

	const setProperties = (e) => {
		setProperty(e.detail.value);
		if (e?.detail?.value === "Rental Shop") {
			setRentalProperty(true);
		} else {
			setRentalProperty(false);
		}
	};

	const nextHandler = async () => {
		let formData = new FormData();
		formData.append("key", files[0]);

		if (files.length !== 0) {
			let response = await User.toBucketImage(formData);
			if (response.status === 201) {
				let data = {
					propertyType: property,
				};
				if (rentalProperty) {
					navigate("/vbrental-document");
					data.rentalPropertyImage = response.data[0];
					dispatch(setPropertyData(data));
				} else {
					data.WonerPropertyImage = response.data[0];
					dispatch(setPropertyData(data));
				}
			}
		}
	};
	return (
		<div className='pt-14 verifyBusiness-content'>
			<div className='px-4 py-5 pt-6 pb-0'>
				<p className='text-16 font-semibold text-black-1000 pb-3'>
					Property Type
				</p>

				<IonRadioGroup
					value={property}
					onIonChange={(e) => setProperties(e)}>
					<IonRow>
						<IonCol size='6'>
							<IonItem
								lines='none'
								className='bg-white border rounded-[10px] mb-4'>
								<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
									Rental Shop
								</IonLabel>
								<IonRadio
									slot='start'
									value='Rental Shop'
									color='success'
								/>
							</IonItem>
						</IonCol>
						<IonCol size='6'>
							<IonItem
								lines='none'
								className='bg-white border rounded-[10px] mb-4'>
								<IonLabel className='border-0 font-sans text-14 text-black-500 font-medium'>
									Own Shop
								</IonLabel>
								<IonRadio
									slot='start'
									value='Own Shop'
									color='success'
								/>
							</IonItem>
						</IonCol>
					</IonRow>
				</IonRadioGroup>
			</div>

			<div className='pb-5 px-4'>
				<ImageUploader
					files={files}
					setFiles={setFiles}
					bHeight={360}
					placeHolder='Upload Image'
				/>
			</div>

			<div className='pt-5 px-4'>
				{files.length ? (
					<div
						className='rounded-[5px] text-center py-4'
						style={{
							background: "#CCFFD6",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						<p
							className='font-normal text-14 flex justify-center mb-[10px]'
							style={{ color: "#808080" }}>
							<Check></Check> Upload Complete.
						</p>
						{rentalProperty ? (
							<p
								className='font-normal text-14'
								style={{ color: "#808080" }}>
								Please click "
								<span style={{ color: "#00B224" }}>NEXT</span>" button
							</p>
						) : (
							<p
								className='font-normal text-14'
								style={{ color: "#808080" }}>
								Please click "
								<span style={{ color: "#00B224" }}>SUBMIT</span>" button
							</p>
						)}
					</div>
				) : (
					<div
						className='rounded-[5px]'
						style={{
							background: "#FFFFCC",
							boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
						}}>
						{rentalProperty ? (
							<p
								className='font-normal p-[14px] text-14'
								style={{ color: "#808080" }}>
								Please, upload your{" "}
								<b className='text-primary font-semibold'>
									"Rental Agreement Document"
								</b>{" "}
								copy.
							</p>
						) : (
							<p
								className='font-normal p-[14px] text-14'
								style={{ color: "#808080" }}>
								Please, upload your{" "}
								<b className='text-primary font-semibold'>
									"Won Property Paper"
								</b>{" "}
								copy.
							</p>
						)}
					</div>
				)}

				<div className='pt-4 text-center pb-10'>
					<IonButton
						expand={!rentalProperty ? "block" : "none"}
						color='primary'
						className='font-bold h-10'
						onClick={nextHandler}>
						{rentalProperty
							? ekkLanguage.businessCreateName.btnLabel
							: ekkLanguage.businessCreateName.submit}
						<span className='pl-2'>
							<ArrayRight />
						</span>
					</IonButton>
				</div>
				<div className='business-step text-center pb-10'>
					<ul className='flex justify-center items-center'>
						<li className='w-full relative active'>
							<div className='w-7 h-7 border-2 border-primary rounded-full text-white bg-primary z-10 relative'>
								{ekkLanguage.verifyBusiness.numberOne}
							</div>
						</li>
						<li className='w-full relative active'>
							<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-white z-10 relative bg-primary'>
								{ekkLanguage.verifyBusiness.numberTwo}
							</div>
						</li>
						<li className={`${rentalProperty && "w-full"} relative`}>
							<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-white z-10 relative bg-primary'>
								{ekkLanguage.verifyBusiness.numberThree}
							</div>
						</li>
						{rentalProperty && (
							<li className='relative'>
								<div className='w-7 h-7 border-2 border-F94B41-200 rounded-full text-F94B41-200 z-10 relative bg-white'>
									{ekkLanguage.verifyBusiness.numberFour}
								</div>
							</li>
						)}
					</ul>
				</div>
			</div>
		</div>
	);
}
