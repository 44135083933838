import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import TradeLicense from "components/mobile-components/VerifyBusiness/TradeLicense";
import React from "react";

export default function TradeLicensePage() {
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title='Verify Business' />
					<TradeLicense />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
