import { createSlice } from "@reduxjs/toolkit";

export const SubscriptionSlice = createSlice({
	name: "subscription",
	initialState: {
		upgradePlan: {},
		subscriptionPackage: null,
	},
	reducers: {
		setUpgradePlan: (state, action) => {
			state.upgradePlan = action.payload;
		},
		clearSubscription: (state, action) => {
			state.upgradePlan = {};
		},
		setSubscriptionPackage: (state, action) => {
			state.subscriptionPackage = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { setUpgradePlan, setSubscriptionPackage, clearSubscription } =
	SubscriptionSlice.actions;
export const { actions, reducer } = SubscriptionSlice;
export default reducer;
