import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import Drivers from "components/mobile-components/Drivers";
import BottomSheet from "components/mobile-components/Employees/BottomSheet";
import { getUserBusiness } from "config/user";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Business from "services/Business";

export default function MyDriversListPage() {
	const { id } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [employees, setEmployees] = useState([]);
	const [empLoading, setEmpLoading] = useState(false);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const [searchQ, setSearchQ] = useState("");
	const [tempSearchParams, setTempSearchParams] = useState("");
	let useBusiness = getUserBusiness();
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const employeesList = async () => {
		setEmpLoading(true);
		let res = await Business.checkBusiness(useBusiness.id);
		if (res.status === 200) {
			let response = await Business.getEmployesList(res.results.id);
			if (response.status === 200) {
				setEmployees(response.results);
				setEmpLoading(false);
			} else {
				setEmpLoading(false);
			}
		} else {
			setEmpLoading(false);
		}
	};
	const employeesSearch = async (q) => {
		setTempSearchParams(q);
		setEmpLoading(true);
		let res = await Business.checkBusiness(useBusiness.id);
		if (res.status === 200) {
			let response = await Business.getEmployeeSearch(q, res.results.id);
			if (response.status === 200) {
				setEmployees(response.results);
				setEmpLoading(false);
			} else {
				setEmpLoading(false);
			}
		}
	};
	useEffect(() => {
		searchQ === "" && employeesList();
	}, [searchQ]);

	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.orderSummary.driver}
						// burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
						// from='myEmployees'
					/>
					{/* <LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/> */}
					<Drivers
						employees={employees}
						empLoading={empLoading}
						employeesSearch={employeesSearch}
						isForSelect={id ? true : false}
						ekkLanguage={ekkLanguage}
						searchQ={searchQ}
						setSearchQ={setSearchQ}
						tempSearchParams={tempSearchParams}
						setTempSearchParams={setTempSearchParams}
					/>
					<BottomSheet />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
