import { createSlice } from "@reduxjs/toolkit";

const productSummerySlice = createSlice({
	name: "productSummery",
	initialState: {
		summaryType: {},
		productData: {},
		driverData: {},
		driverDeliveryData: {},
	},
	reducers: {
		setSummaryType: (state, action) => {
			state.summaryType = action.payload;
		},
		setProductSummery: (state, action) => {
			state.productData = action.payload;
		},
		setDriverSummery: (state, action) => {
			state.driverData = action.payload;
		},
		setDriverDeliverySummary: (state, action) => {
			state.driverDeliveryData = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = productSummerySlice;

export const setSummaryType = (data) => async (dispatch, getState) => {
	dispatch(actions.setSummaryType(data));
};
export const setProductSummery = (data) => async (dispatch, getState) => {
	dispatch(actions.setProductSummery(data));
};

export const setDriverSummery = (data) => async (dispatch, getState) => {
	dispatch(actions.setDriverSummery(data));
};
export const setDriverDeliverySummary =
	(data) => async (dispatch, getState) => {
		dispatch(actions.setDriverDeliverySummary(data));
	};

// Export the reducer, either as a default or named export
export default reducer;
