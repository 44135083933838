import AppLayout from 'components/global-components/AppLayout'
import TopBar from 'components/global-components/Navigation/TopBar'
import React from 'react'

export default function Analytics() {
  return (
    <AppLayout>
       <TopBar pageName="Analytics Data"/>
    </AppLayout>
  )
}
