import React from "react";

export default function Men() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='14'
			height='18'
			viewBox='0 0 14 18'
			fill='none'>
			<path
				d='M7 9C9.48528 9 11.5 6.98528 11.5 4.5C11.5 2.01472 9.48528 0 7 0C4.51472 0 2.5 2.01472 2.5 4.5C2.5 6.98528 4.51472 9 7 9Z'
				fill='#222222'
			/>
			<path
				d='M7 10.5C3.27379 10.5041 0.254148 13.5238 0.25 17.25C0.25 17.6642 0.585777 18 0.999988 18H13C13.4142 18 13.75 17.6642 13.75 17.25C13.7458 13.5238 10.7262 10.5041 7 10.5Z'
				fill='#222222'
			/>
		</svg>
	);
}
