import React from "react";

export default function Walet({ color = "#F94B41" }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='30'
			height='26'
			viewBox='0 0 30 26'
			style={{ margin: "auto" }}
			fill='none'>
			<path
				d='M26.25 5.5H6.25C5.17625 5.5 4.16 5.035 3.45625 4.25125C4.14375 3.48375 5.1425 3 6.25 3H28.75C29.4412 3 30 2.44 30 1.75C30 1.06 29.4412 0.5 28.75 0.5H6.25C2.79875 0.5 0 3.29875 0 6.75V19.25C0 22.7013 2.79875 25.5 6.25 25.5H26.25C28.3212 25.5 30 23.8213 30 21.75V9.25C30 7.17875 28.3212 5.5 26.25 5.5ZM27.5 21.75C27.5 22.4388 26.94 23 26.25 23H6.25C4.1825 23 2.5 21.3175 2.5 19.25V6.7475C3.5675 7.54625 4.88 8 6.25 8H26.25C26.94 8 27.5 8.56125 27.5 9.25V21.75ZM25 15.5C25 16.19 24.44 16.75 23.75 16.75C23.06 16.75 22.5 16.19 22.5 15.5C22.5 14.81 23.06 14.25 23.75 14.25C24.44 14.25 25 14.81 25 15.5Z'
				fill={color}
			/>
		</svg>
	);
}
