import React from "react";

export default function BpVarified() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'>
			<circle cx='20' cy='20' r='20' fill='#36A94D' fillOpacity='0.15' />
			<g clipPath='url(#clip0_1426_418)'>
				<path
					d='M19.9567 30.0073L19.6617 29.8765C19.3334 29.7323 11.6667 26.2698 11.6667 19.9998V14.5632C11.667 14.04 11.8324 13.5302 12.1393 13.1065C12.4462 12.6827 12.8789 12.3666 13.3759 12.2032L20.0001 10.0073L26.6242 12.2032C27.1212 12.3666 27.554 12.6827 27.8609 13.1065C28.1678 13.5302 28.3331 14.04 28.3334 14.5632V19.9998C28.3334 27.1473 20.5934 29.7957 20.2634 29.9057L19.9567 30.0073ZM20.0001 11.7548L13.9034 13.7765C13.7375 13.8307 13.5931 13.936 13.4907 14.0773C13.3883 14.2186 13.3332 14.3887 13.3334 14.5632V19.9998C13.3334 24.5782 18.7001 27.5482 20.0392 28.2173C21.3759 27.6798 26.6667 25.219 26.6667 19.9998V14.5632C26.6669 14.3887 26.6119 14.2186 26.5095 14.0773C26.4071 13.936 26.2626 13.8307 26.0967 13.7765L20.0001 11.7548Z'
					fill='#36A94D'
				/>
				<path
					d='M19.2592 22.1183H19.2317C19.0208 22.1151 18.8127 22.0691 18.6201 21.9831C18.4274 21.8972 18.2542 21.7731 18.1109 21.6183L16.1892 19.6183L17.39 18.4666L19.26 20.4166L23.5775 16.0991L24.7559 17.2775L20.3717 21.6616C20.2257 21.8075 20.0522 21.923 19.8612 22.0014C19.6702 22.0798 19.4656 22.1195 19.2592 22.1183Z'
					fill='#36A94D'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1426_418'>
					<rect
						width='20'
						height='20'
						fill='white'
						transform='translate(10 10)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
