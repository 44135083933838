import { IonPage } from "@ionic/react";
import ClustarMarkerMap from "components/global-components/common/ClustarMarkerMap";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";

export default function Map() {
	return (
		<IonPage>
			<BackButtonTitle title='Location' />
			<ClustarMarkerMap />
		</IonPage>
	);
}
