import React from "react";

export default function Download() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'>
			<path
				d='M17.25 4.50001C17.2496 4.42282 17.2343 4.34642 17.205 4.27501C17.206 4.25753 17.206 4.24 17.205 4.22251L15.705 1.22251C15.649 1.08177 15.5516 0.961294 15.4258 0.876952C15.2999 0.79261 15.1515 0.748355 15 0.750013H2.25C2.07867 0.745022 1.9108 0.798863 1.77434 0.902573C1.63788 1.00628 1.54106 1.15361 1.5 1.32001L0.749995 4.32001C0.746299 4.34488 0.746299 4.37015 0.749995 4.39501C0.746487 4.42993 0.746487 4.4651 0.749995 4.50001V16.5C0.749995 16.6989 0.829013 16.8897 0.969665 17.0303C1.11032 17.171 1.30108 17.25 1.5 17.25H16.5C16.6989 17.25 16.8897 17.171 17.0303 17.0303C17.171 16.8897 17.25 16.6989 17.25 16.5V4.50001ZM2.835 2.25001H14.535L15.285 3.75001H2.46L2.835 2.25001ZM15.75 15.75H2.25V5.25001H15.75V15.75Z'
				fill='black'
			/>
			<path
				d='M8.58751 14.1225C8.70997 14.2031 8.85338 14.2461 9.00001 14.2461C9.14664 14.2461 9.29005 14.2031 9.41251 14.1225L11.6625 12.6225C11.7545 12.5732 11.8351 12.5052 11.8993 12.4229C11.9635 12.3406 12.0098 12.2459 12.0353 12.1447C12.0608 12.0435 12.0648 11.9381 12.0473 11.8352C12.0297 11.7323 11.9908 11.6343 11.9332 11.5473C11.8755 11.4603 11.8004 11.3863 11.7125 11.33C11.6246 11.2737 11.526 11.2364 11.4228 11.2204C11.3197 11.2044 11.2144 11.2102 11.1136 11.2372C11.0128 11.2643 10.9188 11.312 10.8375 11.3775L9.75001 12.1275V7.5C9.75001 7.30109 9.67099 7.11032 9.53034 6.96967C9.38968 6.82902 9.19892 6.75 9.00001 6.75C8.8011 6.75 8.61033 6.82902 8.46968 6.96967C8.32903 7.11032 8.25001 7.30109 8.25001 7.5V12.0975L7.16251 11.3475C6.99783 11.2592 6.80611 11.2355 6.62489 11.2811C6.44368 11.3267 6.28602 11.4384 6.18279 11.5941C6.07957 11.7499 6.03821 11.9386 6.06683 12.1233C6.09545 12.3079 6.19198 12.4753 6.33751 12.5925L8.58751 14.1225Z'
				fill='black'
			/>
		</svg>
	);
}
