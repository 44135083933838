export const postalCodeInfo = [
	{
		district: "Dhaka",
		thana: "Rampura",
		postalCode: 1206,
	},
	{
		district: "Dhaka",
		thana: "Moghbazar",
		postalCode: 1217,
	},
	{
		district: "Dhaka",
		thana: "Ramna",
		postalCode: 1217,
	},

	{
		district: "Dhaka",
		thana: "Dhamrai",
		postalCode: 1350,
	},
	{
		district: "Dhaka",
		thana: "Dhanmondi",
		postalCode: 1209,
	},
	{
		district: "Dhaka",
		thana: "Gulshan",
		postalCode: 1213,
	},
	{
		district: "Dhaka",
		thana: "Badda",
		postalCode: 1212,
	},
	{
		district: "Dhaka",
		thana: "Jatra Bari",
		postalCode: 1232,
	},
	{
		district: "Dhaka",
		thana: "Dohar",
		postalCode: 1330,
	},
	{
		district: "Dhaka",
		thana: "Keraniganj",
		postalCode: 1312,
	},
	{
		district: "Dhaka",
		thana: "Khilgaon",
		postalCode: 1219,
	},
	{
		district: "Dhaka",
		thana: "Khilkhet",
		postalCode: 1229,
	},
	{
		district: "Dhaka",
		thana: "Lalbag",
		postalCode: 1211,
	},
	{
		district: "Dhaka",
		thana: "Mirpur",
		postalCode: 1216,
	},
	{
		district: "Dhaka",
		thana: "Mohammadpur",
		postalCode: 1207,
	},
	{
		district: "Dhaka",
		thana: "Motijheel",
		postalCode: 1222,
	},
	{
		district: "Dhaka",
		thana: "Nawabganj",
		postalCode: 1323,
	},
	{
		district: "Dhaka",
		thana: "Dohar",
		postalCode: 1321,
	},
	{
		district: "Dhaka",
		thana: "Nawabganj",
		postalCode: 1324,
	},
	{
		district: "Dhaka",
		thana: "Kalabagan",
		postalCode: 1205,
	},
	{
		district: "Dhaka",
		thana: "Palton",
		postalCode: 1000,
	},
	{
		district: "Dhaka",
		thana: "Badda",
		postalCode: 1212,
	},
	{
		district: "Dhaka",
		thana: "Hatirjheel",
		postalCode: 1217,
	},
	{
		district: "Dhaka",
		thana: "Sabujbag",
		postalCode: 1214,
	},
	{
		district: "Savar",
		thana: "Amin Bazar",
		postalCode: 1348,
	},
	{
		district: "Savar",
		thana: "Dairy Farm",
		postalCode: 1341,
	},
	{
		district: "Dhaka",
		thana: "Sutrapur",
		postalCode: 1100,
	},
	{
		district: "Dhaka",
		thana: "Tejgaon",
		postalCode: 1215,
	},
	{
		district: "Dhaka",
		thana: "Uttara",
		postalCode: 1230,
	},
	{
		district: "Dhaka",
		thana: "Badda",
		postalCode: 1212,
	},
	{
		district: "Narayanganj",
		thana: "Rupganj",
		postalCode: 1462,
	},
	{
		district: "Tangail",
		thana: "Tangail Sadar",
		postalCode: 1900,
	},
	{
		district: "Tangail",
		thana: "Sakhipur",
		postalCode: 1950,
	},
];
