import React from "react";
import Sheet from "react-modal-sheet";
import Print from "assets/images/icon/svg/Print";
import { IonItem } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { setAddBankBottomSheet } from "redux/container/userSlice";
import { useNavigate } from "react-router-dom";

export default function AddBankBottomSheet({ order }) {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.bankBottomSheet);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[120, 120, 100, 0]}
				onClose={() => dispatch(setAddBankBottomSheet(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => {
								dispatch(setAddBankBottomSheet(false));
								navigate(`/add-bank-account`);
							}}
							lines='none'>
							<span className='py-[5px]'>
								<img
									src='assets/images/bank.png'
									alt='icon'
									className='w-10'
								/>
							</span>
							<p className='text-14 font-normal text-black-1000 pl-2'>
								{ekkLanguage.addBankSettings.pageTitle}
							</p>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop
					onClick={() => dispatch(setAddBankBottomSheet(false))}
				/>
			</Sheet>
		</div>
	);
}
