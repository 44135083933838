const vietnamese = {
	login: {
		contryLabel: "QUỐC GIA",
		contryPlaceHolder: "",
		phoneLable: "SỐ ĐIỆN THOẠI",
		phonePlaceHolder: "SỐ ĐIỆN THOẠI",
		validationLabel: "Vui lòng cung cấp số điện thoại hợp lệ!",
		btnLabel: "Tiếp tục",
		tokenSuccessLabel:
			"Mã thông báo của bạn đã được gửi đến điện thoại của bạn",
		tokenSuccessFailed:
			"Mã thông báo của bạn chưa được tạo, vui lòng thử lại",
		langChange: "Thay đổi ngôn ngữ thành",
		termsAllow:
			"Vui lòng chấp nhận các điều khoản và điều kiện khi đăng nhập!",
	},
	password: {
		passwordLabel: "MẬT KHẨU",
		passwordPlaceHolder: "Nhập mật khẩu của bạn",
		forgetPass: "Quên mật khẩu",
		btnLabel: "Đăng nhập",
		otpSendLabel: "Mã đã được gửi đến điện thoại di động của bạn",
		backWord: "Quay lại",
		passError: "Mật khẩu không khớp với số điện thoại",
	},
	changePass: {
		passwordLabel: "MẬT KHẨU MỚI",
		passwordPlaceHolder: "*******************",
		confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
		confirmPassPlaceHolder: "*******************",
		passNotMatchLabel: "Mật khẩu không khớp",
		btnLabel: "Tiếp tục",
		successLabel: "Mật khẩu của bạn đã được thay đổi thành công",
		errorLabel: "Thay đổi mật khẩu thất bại",
		lengthErrorLabel: "Mật khẩu phải có ít nhất 4 ký tự",
		notMatchLabel: "Mật khẩu và xác nhận mật khẩu không khớp.",
	},
	token: {
		tokenLabel: "MÃ XÁC THỰC",
		tokenPlaceHolder: "Nhập mã xác thực của bạn",
		tokenResend: "Gửi lại mã xác thực",
		tokenSuccessLabel:
			"Mã đã được gửi đến điện thoại di động của bạn, nhập để tiếp tục",
		tokenErrorLabel: "Gửi mã xác thực thất bại",
		timerLabel: "Vui lòng chờ",
		second: "giây",
		btnLabel: "Tiếp tục",
		tokenNotMatch: "Mã xác thực không khớp",
		tokenVarified: "Mã xác thực của bạn đã được xác minh",
		tokenNotWrite: "Vui lòng nhập mã xác thực của bạn",
	},
	registration: {
		nameLabel: "HỌ VÀ TÊN ĐẦY ĐỦ",
		namePlaceholder: "Nhập họ và tên đầy đủ của bạn",
		emailLabel: "ĐỊA CHỈ EMAIL",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		passwordLabel: "MẬT KHẨU",
		passwordPlaceholder: "Nhập mật khẩu của bạn",
		confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
		confirmPassPlaceholder: "Nhập xác nhận mật khẩu của bạn",
		validationLabel: "Trường nhập phải có ít nhất 4 ký tự",
		passNotMatch: "Mật khẩu và xác nhận mật khẩu không khớp.",
		btnLabel: "Tiếp tục",
		successMessage: "Tạo hồ sơ thành công",
		errorMessage: "Vui lòng kiểm tra thông tin đầu vào hợp lệ",
		passLengthValidLabel: "Mật khẩu phải có ít nhất 4 ký tự",
		passwordLength: "Mật khẩu của bạn là",
		strong: "Mạnh mẽ'",
		medium: "Trung bình",
		weak: "yếu",
		gender: "Giới tính",
		male: "Nam",
		female: "Nữ",
		others: "Khác",
		dobLabel: "Ngày Sinh",
		day: "Ngày",
		month: "Tháng",
		year: "Năm",
	},
	letNav: {
		homeMenuLabel: "Trang chủ",
		orderMenuLabel: "Danh sách đơn hàng",
		mySupplierMenuLabel: "Nhà cung cấp của tôi",
		businessProfileMenuLabel: "Cài đặt Hồ sơ Doanh nghiệp",
		dashboardProfileMenuLabel: "Bảng điều khiển",
		phoneCall: "Gọi để đặt hàng",
		myEmployeesMenuLabel: "Nhân viên của tôi",
		myCustomersMenuLabel: "Nhà phân phối",
		product: "Sản phẩm",
	},

	home: {
		showMoreLabel: "Hiển Thị Thêm",
		brandLabel: "Thương Hiệu",
		offerLabel: "Ưu Đãi Mới",
		mySuppliersLabel: "Nhà Cung Cấp Của Tôi",
		nearSuppliersLabel: "Nhà Cung Cấp Gần Đây",
		recommendentLabel: "Dành Riêng Cho Bạn",
		searchLabel: "Tìm kiếm sản phẩm",
		todayPurchaseLabel: "Mua Hàng Hôm Nay",
		totalOrderLabel: "Sản Phẩm từ",
		totalOrder: "Đơn Hàng",
		skusLabel: "SKU",
		tillNow: "Cho Đến Nay",
		orderDue: "Đơn Hàng Đến Hạn",
		brandProductPageTitle: "Sản Phẩm Thương Hiệu",
		offerProductPageTitle: "Sản Phẩm Ưu Đãi",
		offerProductNotFound:
			"Xin lỗi, Không có ưu đãi nào được tìm thấy cho bạn.",
		mySupplierNotFound:
			"Bạn chưa có bất kỳ nhà cung cấp nào. Vui lòng mua sản phẩm để thêm nhà cung cấp của bạn ở đây.",
		account: "Tài Khoản",
		orderNumber: "Số Đơn Hàng",
		amount: "Số Tiền",
		warning: `Vui lòng lưu ý, các giao dịch sẽ bị ảnh hưởng vào ngày 1 tháng 3 năm 2024 do hệ thống đang được nâng cấp.`,
		warningHeading: "Cảnh Báo",
	},

	profile: {
		pageTitle: "Hồ Sơ & Cài Đặt",
		contactMenuLable: "Liên Hệ & Thông Tin Cơ Bản",
		passwordMenuLable: "Thay Đổi Mật Khẩu",
		logoutMenuLable: "Đăng Xuất",
		changeLanguageMenuLable: "Thay Đổi Ngôn Ngữ",
		kycMenuLable: "Thông tin KYC",
		subscriptionPlan: "gói đăng ký",
	},

	changeLanguages: {
		pageTitle: "Chọn Ngôn Ngữ",
		btnLabel: "Lưu",
	},

	contact: {
		pageTitle: "Thông tin người dùng",
		emailLabel: "Email",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		nidLabel: "Số NID",
		nidPlaceholder: "Nhập số NID",
		btnLabel: "Lưu",
		successLabel: "Hồ sơ của bạn đã được cập nhật thành công.",
		currectEmail: "Vui lòng nhập địa chỉ email hiện tại",
	},

	otpsend: {
		pageTitle: "Xác minh Số điện thoại",
		btnLabel: "Gửi",
		message: "Nhấn để gửi mã xác thực đến điện thoại của bạn qua SMS.",
		successLabel: "Mã xác thực đã được gửi đến điện thoại của bạn",
		errorLabel: "Mã xác thực chưa được tạo. Vui lòng thử lại",
	},

	otpsubmit: {
		pageTitle: "Xác minh Số điện thoại",
		btnLabel: "Gửi",
		title: "Mã Xác Minh",
		verifiLabel: "Xác minh và tiếp tục",
		paragraph:
			"Chúng tôi đã gửi mã xác minh đến Số điện thoại di động của bạn",
		otpLabel: "Mã OTP",
		otpPlaceholder: "Nhập mã OTP của bạn",
		receiveYetLabel: "Chưa nhận được mã?",
		againRequestLabel: "Yêu cầu lại",
		timerLabel: "Vui lòng chờ",
		second: "giây",
		successLabel: "Mã xác thực đã được gửi đến điện thoại của bạn",
		errorLabel: "Mã xác thực chưa được tạo. Vui lòng thử lại",
		tokenVarifiedMessage: "Mã xác thực của bạn đã được xác minh",
		validationLabel: "Vui lòng gửi mã OTP của bạn",
	},

	changePassword: {
		pageTitle: "Thay Đổi Mật Khẩu",
		btnLabel: "Gửi",
		passwordLabel: "MẬT KHẨU",
		passwordPlaceholder: "Nhập mật khẩu của bạn",
		confirmPassLabel: "XÁC NHẬN MẬT KHẨU",
		confirmPassPlaceholder: "Nhập xác nhận mật khẩu của bạn",
		successLabel: "Mật khẩu của bạn đã được cập nhật.",
		errorLabel: "Cập nhật mật khẩu thất bại.",
		passNotMatchLabel: "Mật khẩu và xác nhận mật khẩu không khớp.",
		changePassSuccessPara: "Mật khẩu của bạn đã được thay đổi thành công.",
		changePassContinueBtn: "Tiếp tục",
	},

	brandDetails: {
		skusLabel: "Số SKU",
		descriptionLabel: "Mô tả",
		productTitleLabel: "Sản Phẩm của Thương Hiệu Này",
		pageTitle: "Chi Tiết Thương Hiệu",
		sku: "SKU",
		import: "Nhập khẩu",
		edit: "Chỉnh sửa",
		delete: "Xóa",
		deleteConformationAsk: "Bạn có chắc chắn muốn xóa thương hiệu này?",
	},

	mysuppliers: {
		skusLabel: "Số SKU",
		productTitleLabel: "Sản Phẩm của Nhà Cung Cấp Này",
	},

	createOrder: {
		pageTitle: "Tạo Đơn Hàng",
		buyerDetailsTitle: "Chi Tiết Người Mua",
		sellerDetailsTitle: "Chi Tiết Người Bán",
		deliveryDetailsTitle: "Giao Hàng",
		productItemTitle: "Sản Phẩm",
		costSummaryTitle: "Tóm Tắt Chi Phí",
		paymentDetailsTitle: "Chi Tiết Thanh Toán",
		paymentTermsTitle: "Điều Khoản Thanh Toán",
		paymentMethodeTitle: "Phương Thức Thanh Toán",
		moqLabel: "Số Lượng Đặt Hàng Tối Thiểu",
		pcsLabel: "Cái",
		subtotal: "Tổng Phụ",
		tax: "Thuế / VAT",
		discount: "Giảm Giá",
		deliveryFee: "Phí Giao Hàng",
		creditFee: "Phí Dịch Vụ",
		total: "Tổng Cộng",
		immediatePayment: "Thanh Toán Ngay",
		pickAndPay: "Nhận Hàng Và Thanh Toán",
		today: "Vào Buổi Tối Của Ngày Giao Hàng",
		threeDays: "Thanh Toán Trong 3 Ngày",
		sevenDays: "Thanh Toán Trong 7 Ngày",
		immediatePaymentText: "Không áp dụng phí dịch vụ cho đơn hàng này.",
		todayPaymentText1: "Cho thanh toán vào hôm nay, phí dịch vụ bổ sung là ",
		todayPaymentText2: "sẽ được thêm vào đơn hàng này.",
		threePaymentText1:
			"Cho thanh toán trong vòng 3 ngày, phí dịch vụ bổ sung là ",
		threePaymentText2: "sẽ được thêm vào đơn hàng này.",
		sevenPaymentText1:
			"Cho thanh toán trong vòng 7 ngày, phí dịch vụ bổ sung là ",
		sevenPaymentText2: " sẽ được thêm vào đơn hàng này.",
		cashOnDelivery: "Thanh Toán Bằng Tiền Mặt Khi Nhận Hàng",
		cashOnDeliveryText1: "Thanh toán bằng tiền mặt cho",
		cashOnDeliveryText2: "khi hàng được giao.",
		cash: "Tiền Mặt",
		cashText: "Thanh toán bằng tiền mặt cho",
		bankTransfer: "Chuyển Khoản Ngân Hàng",
		bankTransferText1:
			"Chuyển khoản - Thanh toán vào tài khoản ngân hàng có 'Số Đơn Đặt Hàng' làm tham chiếu",
		bankTransferText2: "Tên Ngân Hàng",
		bankTransferText3: "Tên Tài Khoản",
		bankTransferText4: "Số Tài Khoản",
		bankTransferText5: "Số Định Tuyến",
		bankTransferText6: "Chi Nhánh",
		btnLabel: "Xác Nhận",
		addMoreProductLabel: "Thêm Sản Phẩm Khác",
		selectProductLabel: "Gửi",
		cosgressTitle: "Chúc Mừng!",
		cosgressPara: "Đơn hàng của bạn đã hoàn thành.",
		congressCancelText:
			"Nếu có bất kỳ vấn đề nào với đơn hàng, hãy hủy trước 12 giờ trưa. Sau 12 giờ trưa, bạn không thể hủy đơn hàng nữa.",
		congressProductLabel: "Tiếp Tục",
		orderNow: "Đặt Hàng Ngay",
		alert: "Cảnh Báo",
		importentMessage: "Thông Báo Quan Trọng",
		deliveryDateMessage: "Vui lòng Chọn Ngày Giao Hàng",
		paymentTermsMessage: "Vui lòng Chọn Điều Khoản Thanh Toán",
		paymentMethodMessage: "Vui lòng Chọn Phương Thức Thanh Toán",
		backButtonMessage:
			"Giỏ hàng của bạn có một số sản phẩm. Nếu bạn rời khỏi trang này, giỏ hàng của bạn sẽ bị rỗng.",
		okBtnLabel: "OK",
		loader: "Vui lòng đợi...",
		placeConformationMessage: "Bạn có chắc chắn muốn đặt đơn hàng này?",
		lessThenMoq:
			"Số lượng sản phẩm đã chọn ít hơn số lượng đặt hàng tối thiểu.",
		all: "Tất Cả",
		requestDeliveryDate: "Yêu Cầu Ngày Giao Hàng",
	},
	selectProduct: {
		pageTitle: "Chọn Sản Phẩm",
		totalLabel: "Tổng Cộng",
		btnLabel: "Gửi",
	},
	orderList: {
		pageTitle: "Tạo Đơn Hàng",
		tabMenuRecent: "Tất Cả",
		tabMenuPlaced: "Đã Đặt Hàng",
		tabMenuAccepted: "Đã Chấp Nhận",
		tabMenuScDelivered: "Đã Lên Lịch Giao Hàng",
		tabMenuPartialDelivered: "Giao Hàng Một Phần",
		tabMenuDelivered: "Đã Giao Hàng",
		tabMenuReturn: "Trả Lại Sản Phẩm",
		tabMenuPartialPaid: "Thanh Toán Một Phần",
		tabMenuPaid: "Đã Thanh Toán",
		tabMenuCancel: "Hủy",
		tabMenuDeliveryFailed: "Giao Hàng Thất Bại",
		orderItem: "Mục Đơn Hàng",
		seller: "Người Bán",
		buyer: "Người Mua",
		loader: "Vui lòng đợi...",
		createdBy: "Được Tạo Bởi",
		to: "Đến",
		for: "Cho",
		notFound: "Không Tìm Thấy",
	},
	customerList: {
		pageTitle: "Danh Sách Nhà Phân Phối",
		searchPlaceHolder: "Tìm Kiếm Nhà Phân Phối Của Bạn",
		detailsPageTitle: "Chi Tiết Nhà Phân Phối",
		customerDetailsLabel: "Thông Tin Nhà Phân Phối",
		businessTypeLabel: "Loại Hình Kinh Doanh",
		customerNameLabel: "Tên",
		addressLabel: "Địa Chỉ",
		postalCodeLabel: "Mã Bưu Điện",
		cityLabel: "Thành Phố",
		acceptedCustomerLabel: "Nhà phân phối",
		requestedCustomerLabel: "Danh sách đặt hàng",
		accept: "Chấp Nhận",
		acceptConfirmation: "Bạn có chắc chắn muốn chấp nhận Nhà Phân Phối này?",
		km: "km",
		createCustomer: "Tạo Nhà Phân Phối Mới",
		invitedDistributor: "Danh sách nhà phân phối được mời",
		customerNoHolder: "Số Điện Thoại Nhà Phân Phối",
		nextBtn: "Tiếp Theo",
		deleteDistributor: "Xóa Nhà phân phối",
		addCustomPrice: "Thêm Giá Tùy Chỉnh Sản Phẩm",
		warningMsg: "Bạn có chắc chắn muốn xóa Nhà phân phối này không?",
		yes: "Có",
		no: "Không",
		successMsg: "Đã xóa nhà phân phối thành công!",
		customPriceMsg:
			"Bạn có chắc chắn muốn đặt giá tùy chỉnh cho nhà phân phối này không?",
		inputPlaceholder: "Nhập giá tùy chỉnh",
		priceEmptyError: "Giá tùy chỉnh không thể để trống!",
		alreadySetPriceError:
			"Giá sản phẩm này đã được đặt cho nhà phân phối này.",
		cancelBtn: "Hủy",
		submit: "Gửi",
		modalTitle: "Đặt Giá Tùy Chỉnh Cho Sản Phẩm",
		productsSectionTitle: "Sản Phẩm Giá Tùy Chỉnh",
		distributorCustomerList: "Danh sách khách hàng phân phối",
		updatePriceTitle: "Cập Nhật Giá Tùy Chỉnh",
		deletePriceTitle: "Xóa Sản Phẩm Giá Này",
		productDetailTitle: "Chi Tiết Sản Phẩm",
		offerDetailsTitle: "Chi Tiết Khuyến Mãi",
		description:
			"Bạn có chắc chắn muốn xóa sản phẩm giá tùy chỉnh này không?",
		distributorCode: "Mã Nhà Phân Phối",
		notSet: "Chưa Đặt",
		distributorCodeAdd: "Thêm Mã Nhà Phân Phối",
		distributorCodeEdit: "Cập Nhật Mã Nhà Phân Phối",
		writeDistributorCode: "Nhập Mã Nhà Phân Phối",
		successLabel: "Thêm Mã Nhà Phân Phối Thành Công",
		editSuccessLabel: "Cập Nhật Mã Nhà Phân Phối Thành Công",
	},
	orderDetails: {
		createdBy: "Được Tạo Bởi",
		placedLabel: "Đã Đặt Hàng",
		deliveredLabel: "Đã Giao Hàng",
		paidLabel: "Đã Thanh Toán",
		orderItemLabel: "Mục Đơn Hàng",
		castItemLabel: "Chi Tiết Chi Phí",
		timelineLabel: "Dòng Thời Gian",
		subtotal: "Tổng Phụ",
		tax: "Thuế / VAT",
		discount: "Giảm Giá",
		deliveryFee: "Phí Giao Hàng",
		creditFee: "Phí Dịch Vụ",
		total: "Tổng Cộng",
		paymentSettlementLabel: "Thanh Toán",
		date: "Ngày",
		amount: "Số Tiền",
		dueDate: "Ngày Đáo Hạn",
		remark: "Ghi Chú",
		collectedBy: "Thu Thập Bởi",
		paymentDetailsLabel: "Chi Tiết Thanh Toán",
		paymentMethod: "Phương Thức Thanh Toán",
		paymentTerms: "Điều Khoản Thanh Toán",
		paymentDueDate: "Ngày Đáo Hạn Thanh Toán",
		totalDue: "Tổng Số Còn Nợ",
		totalPaid: "Tổng Số Đã Thanh Toán",
		remaining: "Còn Lại",
		deliveryDetailsLabel: "Chi Tiết Giao Hàng",
		selectTimeDate: "Chọn Ngày & Giờ Giao Hàng",
		selectTime: "Chọn Giờ Giao Hàng",
		selectDate: "Chọn Ngày Giao Hàng",
		acceptOrder: "Chấp Nhận Đơn Hàng",
		cancelOrder: "Hủy Đơn Hàng",
		scheduleDelivery: "Lên Lịch Giao Hàng",
		BuyerName: "Tên Người Mua",
		address: "Địa Chỉ",
		phoneNumber: "Số Điện Thoại",
		email: "Email",
		deliveryDate: "Ngày Giao Hàng",
		downloadInvoices: "Tải Hóa Đơn",
		receiptDownloads: "In Biên Nhận",
		cancelConformationAsk: "Bạn có chắc chắn muốn Hủy Đơn Hàng này?",
		cancelConformationParagraph: `Bạn không thể hủy đơn hàng này, bạn chỉ có thể hủy đơn hàng trong cùng ngày.`,
		yesBtn: "Có",
		noBtn: "Không",
		withoutSettelment:
			"Thông tin thanh toán hoàn tất sẽ được hiển thị ở đây.",
		bankInfoLabel:
			"Thông Tin Tài Khoản Ngân Hàng Cho Chuyển Khoản Thanh Toán",
		assignDriver: "Giao Viên Giao Hàng",
		assignCollector: "Chỉ Định Người Thu Tiền",
		assignedDriver: "Giao Viên Giao Hàng Đã Được Chỉ Định",
		assignedCollector: "Người Thu Tiền Đã Được Chỉ Định",
		notAssigned: "Chưa Được Chỉ Định",
		addDeliveryFee: "Thêm Phí Giao Hàng",
		addDiscount: "Thêm Giảm Giá",
		feePlaceHolder: "Nhập Số Tiền Phí Giao Hàng Của Bạn",
		discountPlaceHolder: "Nhập Số Tiền Giảm Giá Của Bạn",
		requestDeliveryDate: "Yêu Cầu Ngày Giao Hàng",
		scheduleDeliveryDate: "Lên Lịch Ngày Giao Hàng",
		scheduleDeliveryTime: "Lên Lịch Giờ Giao Hàng",
		deliveryFailMessage: "Thông Báo Giao Hàng Thất Bại",
		selectCancelOrder: "Chọn Lý Do Hủy Đơn Hàng",
		multipleTime: "Giao hàng thất bại nhiều lần",
		productNotAvailable: "Sản Phẩm không có sẵn",
		cannotFindAddress: "Không Tìm Thấy Địa Chỉ",
		noShop: "Địa chỉ nhưng không có cửa hàng",
		buyerNotAccept: "Người mua không chấp nhận giá đặt hàng",
		supplierNotGive: "Nhà cung cấp không cung cấp sản phẩm",
		other: "Khác",
		yes: "Có",
		no: "Không",
		deliveryTimeWarning:
			"Bạn phải chọn ngày giao hàng, nếu không chúng tôi không thể lên lịch giao hàng được",
		deliveryDateWarning:
			"Bạn phải chọn giờ giao hàng, nếu không chúng tôi không thể lên lịch giao hàng được",
		deliveryDateTimeWarning: "Bạn phải chọn ngày và giờ giao hàng",
		orderSearchLabel: "Tìm Kiếm Đơn Hàng Của Bạn",
		close: "Đóng",
		confirmDelivery: "Giao hàng hoàn thành",
		instantDelivery:
			"Bạn có chắc chắn muốn Giao hàng hoàn tất cho đơn hàng này không?",
		transactionType: "Loại Giao Dịch",
		gateway: "Cổng Thanh Toán",
		accountName: "Tên Tài Khoản",
		accountNumber: "Số Tài Khoản",
		transactionNumber: "Số Giao Dịch",
		receipt: "Biên lai",
		receiptNotProvided: "Không Cung cấp Biên lai",
		sellerPaymentDone: "Thanh toán của người bán đã hoàn tất",
	},
	businessCreateName: {
		pageTitle: "Tên Doanh Nghiệp",
		businessName: "Tên Doanh Nghiệp",
		businessNamePlaceholder: "Nhập Tên Doanh Nghiệp",
		paragraph:
			"Chúng ta cách nhau 3 bước để tạo doanh nghiệp của bạn. Hãy bắt đầu với tên doanh nghiệp của bạn.",
		btnLabel: "Tiếp Theo",
		submit: "Gửi",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		errorLabel: "Nhập tên doanh nghiệp!",
		congratulation: "Chúc Mừng",
		congratulationMessage: "Doanh nghiệp của bạn đã được tạo thành công",
		kycSubmit: "Gửi KYC Của Bạn",
		pending:
			"Cảm ơn bạn đã gửi KYC. Quá trình xác minh KYC của bạn đang trong quá trình xử lý, vui lòng đợi.",
		goBack: "Quay Lại",
	},
	businessType: {
		pageTitle: "Loại Hình Kinh Doanh",
		paragraph: "Hãy chọn loại hình kinh doanh của bạn.",
		fmcg: "FMCG",
		miniMart: "Cửa Hàng Tiện Lợi Nhỏ",
		superMart: "Siêu Thị",
		pharmacy: "Dược Phẩm",
		cart: "Gánh Hàng Rong",
		bazaarStore: "Cửa Hàng Chợ",
		hawkerCenter: "Khu Bán Hàng Lề Đường",
		restaurant: "Nhà Hàng",
		hotel: "Khách Sạn",
		cosmeticsStore: "Cửa Hàng Mỹ Phẩm",
		nonFmcg: "Không Phải FMCG",
		office: "Văn Phòng",
		NGO: "Tổ Chức Phi Lợi Nhuận",
		entertainmentPlace: "Nơi Giải Trí",
		religiousOrganization: "Tổ Chức Tôn Giáo",
		spa: "Spa",
		salon: "Salon",
		gym: "Phòng Tập Gym",
		electronicsStore: "Cửa Hàng Điện Tử",
		shoeStore: "Cửa Hàng Giày Dép",
		fashionStore: "Cửa Hàng Thời Trang",
		laundryStore: "Cửa Hàng Giặt Ủi",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "Tiếp Theo",
		validationLabel: "Vui lòng Chọn một loại hình kinh doanh",
		producer: "Nhà Sản Xuất",
		reseller: "Người Bán Lại",
	},
	businessLocation: {
		pageTitle: "Địa Điểm Kinh Doanh",
		paragraph: "Nhập thông tin liên hệ của doanh nghiệp của bạn.",
		address: "Địa Chỉ",
		addressPlaceholder: "Nhập địa chỉ của bạn",
		city: "Thành Phố",
		cityPlaceholder: "Nhập tên thành phố",
		postalCode: "Mã Bưu Điện",
		postalCodePlaceholder: "Nhập mã bưu điện",
		email: "Email",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		userName: "Tên người dùng",
		userNamePlaceholder: "Nhập tên người dùng của bạn",
		numberOne: 1,
		numberTwo: 2,
		numberThree: 3,
		btnLabel: "HOÀN THÀNH",
		successLabel: "Chúc mừng, doanh nghiệp đã được tạo",
		errorLabel: "Không tạo được doanh nghiệp",
		validationLabel: "Vui lòng điền địa chỉ để tiếp tục.",
		loader: "Vui lòng đợi...",
		validationCityLabel: "Vui lòng điền tên thành phố.",
		validationPostCodeLabel: "Vui lòng điền mã bưu điện.",
	},
	locationPicker: {
		pageTitle: "Tìm Vị Trí Cửa Hàng",
		btnLabel: "XÁC NHẬN",
		paragraph: `Thay đổi trạng thái của đánh dấu bản đồ để chọn vị trí kinh doanh của bạn`,
	},
	location: {
		title: "Bật Vị Trí Của Bạn",
		paragraph:
			"Ứng dụng này yêu cầu dịch vụ vị trí được bật trên thiết bị của bạn và cho ứng dụng này. Bạn phải bật chúng trong Cài Đặt trước khi sử dụng ứng dụng này.",
		btnLabel: "Cho phép chỉ khi đang sử dụng ứng dụng",
	},
	businessSelect: {
		pageTitle: "Chọn Doanh Nghiệp",
	},
	pwaDownload: {
		header: "Tải Ứng Dụng EkkBaz Brand",
		paragraph:
			"Tải ứng dụng và đặt hàng sĩ từ nhà cung cấp của bạn bất kỳ lúc nào.",
		cancelBtn: "Hủy",
		downloadBtn: "Tải Xuống",
	},
	businessProfile: {
		pageTitle: "Cài Đặt Hồ Sơ Doanh Nghiệp",
		businessDetailsLabel: "Chi Tiết",
		registrationNo: "Số Đăng Ký",
		taxNo: "Số Thuế/VAT",
		mobileNo: "Số Điện Thoại Di Động",
		officeNo: "Số Điện Thoại Văn Phòng",
		email: "Email",
		website: "Trang Web",
		branchLabel: "Danh Sách Chi Nhánh",
		paymentDetailsLabel: "Chi Tiết Thanh Toán",
		bankInfoLabel: "Thông Tin Ngân Hàng",
		bankName: "Tên Ngân Hàng",
		branchName: "Chi Nhánh Ngân Hàng",
		branchAddress: "Địa Chỉ Ngân Hàng",
		swiftCode: "Mã Swift Ngân Hàng",
		acName: "Tên Tài Khoản Ngân Hàng",
		acNumber: "Số Tài Khoản Ngân Hàng",
		bkashInfoLabel: "Thông Tin Bkash",
		bkashAcName: "Tên Tài Khoản Bkash",
		bkashAcNo: "Số Tài Khoản Bkash",
		nagadInfoLabel: "Thông Tin Nagad",
		nagadAcName: "Tên Tài Khoản Nagad",
		nagadAcNo: "Số Tài Khoản Nagad",
		upayInfoLabel: "Thông Tin Upay",
		upayAcName: "Tên Tài Khoản Upay",
		upayAcNo: "Số Tài Khoản Upay",
		verified: "Đã Xác Minh",
		unVerified: "Chưa Xác Minh",
		editBusinessMenu: "Chỉnh Sửa Doanh Nghiệp",
		addPayMenu: "Thêm Phương Thức Thanh Toán",
		setLocation: "Cập nhật địa điểm kinh doanh",
		addMenu: "Thêm Chi Nhánh",
		verifyBusinessMenu: "Xác Minh Doanh Nghiệp",
		bankInfoNotFound: "Không tìm thấy thông tin ngân hàng.",
		paymentTermsSettings: "Cài Đặt Điều Khoản Thanh Toán",
		switchBusiness: "chuyển đổi kinh doanh",
		deleteBusiness: "Xóa doanh nghiệp",
		deleteWarning:
			"Tất cả các đơn đặt hàng, sản phẩm, nhân viên và toàn bộ tài khoản của bạn sẽ bị xóa. Bạn có chắc chắn muốn xóa Doanh nghiệp này không?",
		otpSubmit: "OTP Gửi",
		confirmDeleteWarning:
			"Xin hãy suy nghĩ lại! tất cả các đơn đặt hàng, sản phẩm, nhân viên và toàn bộ tài khoản của bạn sẽ bị xóa. Bạn có chắc chắn muốn xóa Doanh nghiệp này không?",
		directSells: "Bán hàng trực tiếp",
		directSellsEnable:
			"Kích hoạt bán hàng trực tiếp cho doanh nghiệp của bạn?",
		directSellsDisable:
			"Bán hàng trực tiếp đã được kích hoạt cho doanh nghiệp của bạn. Bạn có muốn tắt bán hàng trực tiếp không?",
		yes: "Có",
		no: "Không",
	},

	businessEdit: {
		pageTitle: "Chỉnh Sửa Doanh Nghiệp",
		uploadPhoto: "Tải Ảnh Lên",
		email: "Email",
		emailPlaceholder: "Nhập địa chỉ email của bạn",
		address: "Địa Chỉ",
		addressPlaceholder: "Nhập địa chỉ của bạn",
		city: "Thành Phố",
		cityPlaceholder: "Nhập tên thành phố",
		postalCode: "Mã Bưu Điện",
		postalCodePlaceholder: "Nhập mã bưu điện",
		resigter: "Số Đăng Ký",
		resigterPlaceholder: "Nhập số đăng ký của bạn",
		tex: "Số Thuế/VAT",
		texPlaceholder: "Nhập số thuế/VAT của bạn",
		mobile: "Số Điện Thoại Di Động",
		mobilePlaceholder: "Nhập số điện thoại di động của bạn",
		office: "Số Điện Thoại Văn Phòng",
		officePlaceholder: "Nhập số điện thoại văn phòng của bạn",
		website: "Trang Web",
		websitePlaceholder: "Nhập địa chỉ trang web của bạn",
		btnLabel: "Lưu",
		primaryIndustry: "Primary Industry",
		successLabel: "Hồ sơ của bạn đã được cập nhật thành công.",
		currectEmail: "Vui lòng nhập địa chỉ email hiện tại",
	},
	bankDetails: {
		referenceNumber: "Số Tham Chiếu",
		bankName: "Tên Ngân Hàng",
		accountName: "Tên Tài Khoản",
		accountNumber: "Số Tài Khoản",
		routeNumber: "Mã Số Định Tuyến",
		branch: "Chi Nhánh",
	},
	invoice: {
		title: "HÓA ĐƠN",
		receipt: "Biên lai",
		buyerDetails: "Chi tiết người mua",
		sellerDetails: "Chi tiết người bán",
		description: "MÔ TẢ",
		qty: "SỐ LƯỢNG",
		price: "GIÁ",
		subtotal: "TỔNG CỘNG PHỤ",
	},
	verifyBusiness: {
		numberOne: "1",
		numberTwo: "2",
		numberThree: "3",
		numberFour: "4",
		numberFive: "5",
		numberSix: "6",
	},
	receipt: {
		placed: "Đã Đặt Hàng",
		name: "Tên",
		qty: "Số Lượng",
		price: "Giá",
		subtotal: "Tổng Phụ",
		tax: "Thuế",
		discount: "Giảm Giá",
		creditFee: "Phí Dịch Vụ",
		deliveryFee: "Phí Giao Hàng",
		total: "Tổng Cộng",
		paid: "Đã Thanh Toán",
		totalDue: "Tổng Còn Lại",
		paymentTerms: "Điều Kiện Thanh Toán",
		days: "Ngày",
		paymentMethod: "Phương Thức Thanh Toán",
		referenceNumber: "Số Tham Chiếu Ngân Hàng",
		deliveryDate: "Ngày Giao Hàng",
		dueDate: "Ngày Đáo Hạn Thanh Toán",
		printTime: "Thời Gian In",
		conditionsCreditFee: "Điều Kiện Thanh Toán & Phí Dịch Vụ",
		immediatePayment: "Thanh Toán Ngay Lập Tức",
		cashOnDelivery: "Thanh Toán Bằng Tiền Mặt Khi Giao Hàng",
		atNight: "Vào Đêm Ngày Giao Hàng",
		payInthreeDay: "Thanh Toán Trong 3 Ngày",
		payInSevenDay: "Thanh Toán Trong 7 Ngày",
		afterSeven: "Sau 7 Ngày (thêm cho mỗi ngày thêm)",
	},
	landing: {
		header1: "dễ dàng quản lý việc bán hàng kênh nhà",
		header2: "phân phối của bạn",
		login: "Đăng nhập",
		bangla: "বাংলা",
		english: "English",
		vietnamese: "Tiếng Việt",
		malaysia: "Malay",
		retail: "Bán Lẻ",
		manufacture: "Nhà Cung Cấp",
		brand: "Thương Hiệu",
		hotline: "Hotline",
		phoneNumber: "Số Điện Thoại",
		phoneNumberPlaceHolder: "Nhập Số Điện Thoại",
		brandHeader: "Thương hiệu làm việc với chúng tôi!",
		fAbout:
			"Giải pháp duy nhất là bán hàng, làm cho việc bán hàng dễ dàng hơn!",
		location: "Địa Điểm",
		singapore: "Văn Phòng Singapore",
		bangladesh: "Văn Phòng Bangladesh",
		bOfficeAddress: "Tầng 13, Razzak Plaza, Moghbazar Moor, Dhaka-1205",
		otherInfo: "Thông tin khác",
		partners: "Đối tác của chúng tôi",
		news: "Tin tức",
		support: "Hỗ trợ",
		corporate: "Doanh Nghiệp",
		refundPolicy: "Chính Sách Hoàn Trả",
		termsCondition: "Điều Khoản",
		privacyPolicy: "Chính Sách Bảo Mật",
		contactUs: "Liên hệ với chúng tôi",
		messageUs: "Gửi tin nhắn",
		facebook: "Facebook",
		sales: "Bán hàng",
		media: "Truyền thông",
		downloadBtn: "Tải Ứng Dụng",
		backers: "Nhà Đầu Tư của Chúng Tôi",
		benefits: "Lợi ích của bạn",
		discover: "Tin tức mới nhất về EkkBaz",
		benefitsOne: "Tăng doanh số bán hàng",
		benefitsDisOne: "",
		benefitsTwo: "Dễ dàng tiếp cận khách hàng",
		benefitsDisOneTwo: "",
		benefitsThree: "Tăng khả năng hiển thị sản phẩm",
		benefitsDisOneThree: "",
		benefitsFore: "Kỹ thuật số hóa quy trình bán hàng của bạn",
		benefitsDisOneFore: "",
		seeMore: "Xem Thêm",
		downloadApp: "Tải ứng dụng ngay!",
	},

	//   Rest Translation

	employees: {
		pageTitle: "Nhân Viên",
		createNewEmployees: "Tạo Nhân Viên Mới",
		searchPlaceHolder: "Tìm kiếm nhân viên của bạn",
	},
	employeesDetails: {
		pageTitle: "Chi Tiết Nhân Viên",
	},
	createEmployees: {
		pageTitle: "Tạo Nhân Viên Mới",
		customerNoHolder: "Nhập Số Điện Thoại",
		employeeNumber: "Mã Nhân Viên",
		name: "Tên",
		namePlaceholder: "Nhập tên nhân viên",
		confirmBtn: "Xác Nhận",
		selectEmployees: "Chọn vị trí nhân viên",
		admin: "Quản trị viên",
		staff: "Nhân viên",
		orderManager: "Quản lý Đơn Hàng",
		bookManager: "Quản lý Sách",
		productManager: "Quản lý Sản Phẩm",
		employeeManager: "Quản lý Nhân Viên",
		nameError: "Vui lòng nhập tên nhân viên",
		roleError: "Vui lòng chọn vai trò của nhân viên",
		edit: "Sửa",
		delete: "Xóa",
		editEmployee: "Sửa Nhân Viên",
		deleteConformationAsk: "Bạn có chắc chắn muốn xóa nhân viên này không?",
		updateEmployee: "Cập Nhật Nhân Viên",
	},
	productList: {
		pageTitle: "Danh Sách Sản Phẩm",
		offerPageTitle: "Danh Sách Khuyến Mãi",
		searchYourProduct: "Tìm Kiếm Sản Phẩm Của Bạn",
		brandList: "Danh Sách Thương Hiệu",
		createProduct: "Tạo Sản Phẩm",
		createPack: "Tạo Gói",
		createOffer: "Tạo Khuyến Mãi",
		product: "Sản Phẩm",
		pack: "Gói",
		Offer: "Khuyến Mãi",
		discount: "Giảm giá",
		all: "Tất cả",
	},
	productDetails: {
		pageTitle: "Chi Tiết Sản Phẩm",
		offerStart: "Ngày Bắt Đầu Khuyến Mãi",
		offerEnd: "Ngày Kết Thúc Khuyến Mãi",
		moqLabel: "MOQ (Số Lượng Tối Thiểu Đặt Hàng)",
		consumerLabel: "Giá Tiêu Dùng (MRP)",
		profitLabel: "Lợi Nhuận",
		productDetailTitle: "Chi Tiết Sản Phẩm",
		expireData: "Hạn Sử Dụng",
		manufactureData: "Ngày Sản Xuất",
		productWeight: "Trọng Lượng Sản Phẩm",
		packingType: "Loại Đóng Gói",
		importedBy: "Nhập Khẩu Bởi",
		manufacturedBy: "Sản Xuất Bởi",
		country: "Quốc Gia",
		descriptionLabel: "Mô Tả",
		btnLabel: "Đặt Hàng Ngay",
		inStock: "Còn Hàng",
		outStock: "Hết Hàng",
		description: "Mô Tả",
		inventory: "Tồn Kho",
		unpublished: "Chưa Xuất Bản",
		published: "Đã Xuất Bản",
		edit: "Sửa",
		delete: "Xóa",
		deleteText: "Bạn có chắc chắn muốn xóa sản phẩm này không?",
		myProfit: "Lợi Nhuận Của Tôi",
		buyerProfit: "Lợi Nhuận Của Người Mua",
		skuId: "SKU ID",
		searchTram: "Tìm kiếm Trạm",
	},
	brandList: {
		pageTitle: "Danh Sách Thương Hiệu Của Tôi",
		searchBrand: "Tìm Kiếm Thương Hiệu Theo Tên",
		myBrand: "Thương Hiệu Của Tôi",
		allBrand: "Tất Cả Thương Hiệu",
		createNewBrand: "Tạo Thương Hiệu Mới Của Tôi",
		importBrand: "Nhập Thương Hiệu Khác",
		brandList: "Danh Sách Thương Hiệu",
	},
	brandDetail: {
		pageTitle: "Chi Tiết Thương Hiệu",
		sku: "Mã SKU",
		import: "Nhập Khẩu",
		edit: "Sửa",
		delete: "Xóa",
		deleteConformationAsk: "Bạn có chắc chắn muốn xóa thương hiệu này không?",
	},
	createNewBrand: {
		pageTitle: "Tạo Thương Hiệu Mới",
		uploadNewLogo: "Tải lên logo mới",
		brandName: "Tên Thương Hiệu",
		brandNamePlaceHolder: "vui lòng nhập tên thương hiệu của bạn",
		selectCountry: "Chọn Quốc Gia",
		description: "Mô Tả",
		descriptionPlaceHolder: "vui lòng nhập mô tả thương hiệu của bạn",
		createdBy: "Được Tạo Bởi",
		complete: "Hoàn Thành",
		congratulation: "Chúc Mừng",
		congratulationParagraph:
			"Tạo Thương Hiệu Mới đã được thực hiện thành công",
		continue: "Tiếp Tục",
		conformModal: "Bạn có chắc chắn muốn tạo thương hiệu này không?",
		importBrandConformations:
			"Bạn có chắc chắn muốn nhập thương hiệu này không?",
		errorMessage: "Bạn không thể tải lên hình ảnh có dung lượng lớn hơn 1 MB",
		imageFormatError:
			"Định dạng hình ảnh không hợp lệ. Chỉ chấp nhận JPEG, PNG và JPG",
		ownerName: "Chủ sở hữu pháp lý của Thương Hiệu",
		ownerNamePlaceholder: "Nhập tên của chủ sở hữu pháp lý",
		isLegalOwner: "Công Ty Của Tôi Chính Thức Sở Hữu Thương Hiệu Này",
		ownerNumber: "Số Điện Thoại",
		ownerNumberPlaceholder: "vui lòng nhập số điện thoại của bạn",
	},
	updateBrand: {
		pageTitle: "Cập Nhật Thương Hiệu",
		congratulationParagraph: "Cập nhật thương hiệu thành công",
		conformModal: "Bạn có chắc chắn muốn cập nhật thương hiệu này không?",
	},
	createProduct: {
		pageTitle: "Tạo Sản Phẩm",
		productWarning: "Tiêu đề Sản Phẩm sẽ tự động được tạo ở đây.",
		productPhoto: "Ảnh Sản Phẩm",
		productPhotoUpload: "Tải Ảnh Sản Phẩm Lên",
		uploadPhoto: "Tải Ảnh Lên",
		uploadFromGallery: "Tải Ảnh Từ Thư Viện",
		takePhoto: "Chụp Ảnh",
		productName: "Tên Sản Phẩm",
		productNamePlaceHolder: "Nhập tên sản phẩm của bạn",
		description: "Mô Tả",
		descriptionPlaceHolder: "Nhập mô tả sản phẩm của bạn",
		selectBrand: "Chọn Thương Hiệu",
		selectDepartment: "Chọn Bộ Phận",
		selectCategory: "Chọn danh mục",
		selectSubCategory: "Chọn danh mục con",
		setPrice: "Đặt Giá",
		sizeDetermine: "Xác định Kích Thước",
		inventoryDetermine: "Xác định Tồn Kho",
		manufacturingDate: "Ngày Sản Xuất",
		expireData: "Ngày Hết Hạn",
		ManufacturerInformation: "Thông Tin Nhà Sản Xuất",
		save: "Lưu",
		publish: "Xuất Bản",
		purchasePrice: "Giá Tiêu Dùng",
		purchasePricePlaceHolder: "Nhập giá tiêu dùng",
		sellingPrice: "Giá Bán",
		sellingPricePlaceHolder: "Nhập giá bán",
		priceWarning: "Giá của bạn trống",
		customerPrice: "Giá Đại Lý",
		customerPricePlaceHolder: "Nhập giá phân phối",
		taxVat: "Thuế VAT",
		moq: "Số Lượng Đặt Hàng Tối Thiểu (MOQ)",
		taxVatPlaceHolder: "Nhập thuế VAT",
		moqPlaceHolder: "Nhập giá MOQ",
		weight: "Trọng Lượng",
		grossWeight: "Trọng Lượng Tổng",
		grossWeightPlaceholder: "Nhập trọng lượng tổng",
		weightPlaceholder: "Nhập trọng lượng",
		weightWarning: "Trọng lượng trống",
		measurement: "Đơn Vị Đo Lường",
		weightUnits: "Đơn Vị Trọng Lượng",
		units: "Đơn Vị",
		packingType: "Loại Đóng Gói",
		ok: "OK",
		manufacturedCountry: "Quốc Gia Sản Xuất",
		manufacturedBy: "Nhà Sản Xuất",
		manufacturedByPlaceHolder: "Nhập Tên Nhà Sản Xuất",
		importedBy: "Nhập Khẩu Bởi",
		importedByPlaceHolder: "Nhập tên của người nhập khẩu",
		distributedBy: "Phân Phối Bởi",
		distributedByPlaceHolder: "Nhập tên của người phân phối",
		initialInventory: "Tồn Đầu Kỳ",
		initialInventoryPlaceHolder: "Nhập tồn đầu kỳ",
		skuPlaceholder: "Nhập số lượng SKU",
		sku: "SKU",
		empty: "Trống",
		barCode: "Mã Vạch",
		scan: "Quét",
		width: "Chiều Rộng",
		widthPlaceholder: "Nhập chiều rộng",
		height: "Chiều Cao",
		heightPlaceholder: "Nhập chiều cao",
		length: "Chiều Dài",
		lengthPlaceholder: "Nhập chiều dài",
		searchDepartment: "Tìm Bộ Phận theo Tên",
		searchCategory: "Tìm danh mục theo Tên",
		searchSubCategory: "Tìm danh mục con theo Tên",
		conformModal: "Bạn có chắc chắn muốn tạo sản phẩm này không?",
		updateConformModal: "Bạn có chắc chắn muốn cập nhật sản phẩm này không?",
		saveConformModal: "Bạn có chắc chắn muốn lưu sản phẩm này không?",
		updateBtn: "Cập Nhật",
		updateMessage: "Tên sản phẩm, giá, và tồn kho phải có để xuất bản.",
		publishMessage: "Bạn có chắc chắn muốn xuất bản sản phẩm này không?",
		unPublishMessage:
			"Bạn có chắc chắn muốn không xuất bản sản phẩm này không?",
		englishName: "Tên Tiếng Anh để Tìm Kiếm",
		englishNamePlaceholder: "Nhập tên để tìm kiếm",
		productCreated: "Tạo sản phẩm thành công!",
		finalGrossWeight: "Trọng Lượng Giao Hàng Cuối Cùng",
		grossWeightUnit: "Đơn Vị Trọng Lượng Tổng Cộng",
		productUpdated: "Cập nhật sản phẩm thành công!",
	},
	updateProduct: {
		pageTitle: "Cập Nhật Sản Phẩm",
	},
	createPack: {
		pageTitle: "Tạo Gói",
		packWarning: "Tiêu đề Gói sẽ tự động được tạo ở đây.",
		packName: "Tên Gói",
		packNamePlaceHolder: "Vui lòng viết tên gói của bạn",
		description: "Mô Tả",
		descriptionPlaceHolder: "Vui lòng viết mô tả",
		selectProduct: "Sản Phẩm Được Chọn",
		qtyPack: "Số Lượng trong Gói",
		qtyPackPlaceholder: "viết số lượng trong gói",
		conformModal: "Bạn có chắc chắn muốn tạo gói này không?",
		saveConformModal: "Bạn có chắc chắn muốn lưu gói này không?",
		productCreated: "Tạo gói thành công!",
		productUpdated: "Cập nhật gói thành công!",
	},
	createOffer: {
		pageTitle: "Tạo Khuyến Mãi",
		offerWarning: "Tiêu đề Khuyến Mãi sẽ tự động được tạo ở đây.",
		offerName: "Tên Khuyến Mãi",
		offerNamePlaceHolder: "Vui lòng viết tên khuyến mãi của bạn",
		description: "Mô Tả",
		descriptionPlaceHolder: "Vui lòng viết mô tả",
		selectProduct: "Sản Phẩm Được Chọn",
		qtyOffer: "Số Lượng trong Khuyến Mãi",
		qtyOfferPlaceholder: "viết số lượng trong khuyến mãi",
		conformModal: "Bạn có chắc chắn muốn tạo khuyến mãi này không?",
		saveConformModal: "Bạn có chắc chắn muốn lưu khuyến mãi này không?",
		offerStartDate: "Ngày Bắt Đầu Khuyến Mãi",
		offerEndDate: "Ngày Kết Thúc Khuyến Mãi",
		selectedItem: "Sản Phẩm Được Chọn",
		totalProductCount: "Tổng Sản Phẩm",
		totalAmount: "Tổng Số Tiền",
		spWarnings: "Bạn phải chọn một sản phẩm",
		closeButton: "Đóng",
		offerCreated: "Tạo khuyến mãi thành công!",
		offerUpdated: "Cập nhật khuyến mãi thành công!",
	},
	updateOffer: {
		pageTitle: "Cập Nhật Khuyến Mãi",
		conformModal: "Bạn có chắc chắn muốn cập nhật khuyến mãi này không?",
	},
	dashboard: {
		statusLable: "Trạng thái Đơn hàng",
		today: "Hôm nay",
		yesterday: "Hôm qua",
		thisWeek: "Tuần này",
		thisMonth: "Tháng này",
		thisYear: "Năm nay",
		lastWeek: "Tuần trước",
		lastMonth: "Tháng trước",
		lastYear: "Năm trước",
		custom: "Ngày tùy chỉnh",
		selectDay: "Chọn ngày",
		Order: "Đơn hàng",
		totalOrder: "Tổng số đơn hàng",
		placed: "Đã đặt",
		accepted: "Đã chấp nhận",
		scDelivered: "Đã lên lịch giao hàng",
		partialDelivered: "Giao hàng một phần",
		delivered: "Đã giao hàng",
		return: "Trả sản phẩm",
		partialPaid: "Thanh toán một phần",
		paid: "Đã thanh toán",
		totalPaid: "Đã thanh toán",
		cancel: "Hủy",
		deliveryFailed: "Giao hàng thất bại",
		orderAmount: "Số tiền đơn hàng",
		totalAmount: "Số tiền đơn hàng",
		collectedAmount: "Số tiền thu",
		dueAmount: "Số tiền còn nợ",
		totalDue: "Số tiền còn nợ",
		directOrder: "Đơn hàng trực tiếp",
		directOrderAmount: "Số tiền đơn hàng trực tiếp",
		ekkHeroOrder: "Đơn hàng EkkHero",
		ekkHeroOrderAmount: "Số tiền đơn hàng EkkHero",
		cancelOrderAmount: "Số tiền đơn hàng bị hủy",
		seeDetails: "Xem chi tiết",
		period: "Giai đoạn",
		perviousWeek: "Tuần trước",
		nextWeek: "Tuần tới",
		supplierOrder: "Đơn Đặt Hàng Nhà Cung Cấp",
		customerOrder: "Đơn Đặt Hàng Khách Hàng",
	},
	newCustomer: {
		pageTitle: "Tạo Nhà Phân Phối Mới",
		mobileNo: "Số Điện Thoại",
		customerNoHolder: "Số điện thoại của nhà phân phối",
		customerName: "Tên Nhà Phân Phối",
		customerNameHolder: "Nhập tên đầy đủ của nhà phân phối",
		businessPhoto: "Ảnh Cửa Hàng/Doanh Nghiệp",
		uploadPhoto: "Tải Ảnh Cửa Hàng/Doanh Nghiệp Lên",
		uploadPhotoTitle: "Tải Ảnh",
		uploadFromGallery: "Tải Ảnh Từ Thư Viện",
		takePhoto: "Chụp Ảnh",
		businessName: "Tên Cửa Hàng/Doanh Nghiệp",
		businessNameHolder: "Nhập tên cửa hàng/doanh nghiệp",
		address: "Địa Chỉ",
		addressHolder: "Nhập địa chỉ cửa hàng/doanh nghiệp",
		city: "Thành Phố",
		cityHolder: "Nhập thành phố",
		postalCode: "Mã Bưu Điện",
		postalCodeHolder: "Nhập mã bưu điện",
		selectBusiness: "Chọn Loại Doanh Nghiệp",
		fmcg: "FMCG",
		nonFmcg: "NON-FMCG",
		nextBtn: "Tiếp Theo",
		completeBtn: "Hoàn Thành",
		customerFindText:
			"Nhà phân phối đã tồn tại ở đây, bạn có thể thêm vào 'Nhà Phân Phối Đã Chấp Nhận'. Và nếu bạn muốn, bạn có thể tạo một Nhà Phân Phối mới bằng cách nhấn vào nút màu xanh lá cây.",
		addBusiness: "Thêm Doanh Nghiệp",
		addBusinessInfo:
			"Bạn có muốn thêm doanh nghiệp này làm Nhà Phân Phối của tôi không?",
		noBtn: "Không",
		alreayExist: "Nhà phân phối đã tồn tại!",
		reseller: "Đại Lý",
		businessNameWarning: "Vui lòng thêm tên doanh nghiệp",
		distributorNameWarning: "Vui lòng thêm tên nhà phân phối",
		businessCreationSuccess: "Tạo Doanh Nghiệp Mới Thành Công",
		businessLocationWarning:
			"Vui lòng thêm địa điểm kinh doanh từ google map",
	},
	kycVerification: {
		imageWarning: "Bạn không thể tải lên hình ảnh có dung lượng lớn hơn 1 MB",
		imageUpload: "Tải lên hình ảnh của bạn ở đây hoặc duyệt tệp",
		nidFront: "CMND - Mặt Trước",
		nidBack: "CMND - Mặt Sau",
		uploadNidFront: "Tải Ảnh CMND 'Mặt Trước'",
		uploadNidBack: "Tải Ảnh CMND 'Mặt Sau'",
		complete: "Tải lên hoàn thành",
		pleaseCheck: "Vui lòng kiểm tra",
		next: "TIẾP",
		button: "nút",
		upload: "Vui lòng tải lên",
		nid: "CMND",
		copy: "bản sao",
		tradeLicense: "Giấy Phép Kinh Doanh",
		uploadTradeLicense: "Tải Ảnh 'Giấy Phép Kinh Doanh'",
		giveLater: "Tôi sẽ cung cấp thông tin này sau",
		propertyType: "Loại Tài Sản",
		rented: "Thuê",
		own: "Sở Hữu",
		rentalAgreement: "Hợp Đồng Thuê/Đặc Ước với Chủ Sở Hữu",
		ownAgreement: "Hợp Đồng Sở Hữu/Đặc Ước",
		rentalDocument: "Tài Liệu Hợp Đồng Thuê",
		ownDocument: "Tài Liệu Sở Hữu Bất Động Sản",
		startDate: "Ngày Bắt Đầu Thuê",
		rentPerMonth: "Tiền Thuê Mỗi Tháng",
		rentPerMonthPlaceholder: "viết số tiền thuê cửa hàng của bạn mỗi tháng",
		select: "Vui lòng chọn",
		fillup: "& điền thông tin của bạn",
		amount: "số tiền",
		click: "Vui lòng nhấp",
		submit: "GỬI",
	},
	notification: {
		pageTitle: "Thông Báo",
		customer: "Khách Hàng",
		supplier: "Nhà Cung Cấp",
		update: "Cập Nhật",
		supllierPageTitle: "Thông Báo từ Nhà Cung Cấp",
	},
	paymentSettings: {
		pageTitle: "Cài Đặt Điều Khoản Thanh Toán",
		mainTitle: "Chọn Điều Khoản Thanh Toán phù hợp với bạn",
		subTitle:
			"Những điều khoản thanh toán bạn chọn ở đây, khách hàng chỉ có thể sử dụng Điều Khoản Thanh Toán này khi đặt hàng.",
		btnLabel: "Lưu",
		payNow: "Thanh Toán Ngay",
		cashOnDelivery: "Thanh Toán Bằng Tiền Mặt Khi Nhận Hàng",
		pickAndPay: "Tự Đến Lấy và Thanh Toán",
		today: "Vào Buổi Tối Của Ngày Giao Hàng",
		threeDays: "Thanh Toán Trong Vòng 3 Ngày",
		sevenDays: "Thanh Toán Trong Vòng 7 Ngày",
		fourteenDays: "Thanh Toán Trong Vòng 14 Ngày",
		thirtyDays: "Thanh Toán Trong Vòng 30 Ngày",
	},
	orderSummary: {
		orderProductPageTitle: "Tóm tắt Đơn hàng Sản phẩm",
		orderProductDetailsPageTitle: "Đặt hàng Chi tiết tóm tắt sản phẩm",
		driverProductPageTitle: "Đặt hàng trình điều khiển",
		orderDriverProductDrilldown:
			"Đặt hàng Trình điều khiển Chi tiết Tóm tắt Sản phẩm",
		driverDeliveryPageTitle: "Tóm tắt giao hàng của tài xế đặt hàng",
		deliveryAmountPageTitle: "Tóm tắt số tiền giao hàng",
		orderDeliveryAmountDrilldown: "Chi tiết số tiền giao hàng đặt hàng",
		buyerSummary: "Tóm tắt người mua",
		buyerSummaryDrillDown: "Tóm tắt người mua hàng đặt hàng",
		productTitle: "Tiêu đề sản phẩm",
		qty: "Số lượng",
		orderNo: "Số Đơn hàng",
		date: "Ngày",
		status: "Trạng thái",
		product: "Sản phẩm",
		driver: "Tài xế",
		searchPlaceholder: "Tìm kiếm trình điều khiển",
		totalAmount: "Tổng cộng",
		totalPaid: "Tổng số chi trả",
		totalDue: "Tổng số tiền đến hạn",
		numberOfOrders: "Tổng số đơn đặt hàng",
		deliveryFailed: "Giao hàng thất bại",
		gvmAmount: "GMV",
		numberOfFulfillment: "Thực hiện",
		orderCancel: "Hủy đơn hàng",
		revenue: "Doanh thu",
		delayInPayment: "Chậm trễ trong thanh toán",
		dueAmount: "Số tiền đến hạn",
		dayPassed: "ngày trôi qua",
		storeName: "Tên cửa hàng",
		delayPlaceholder: "Chọn ngày trì hoãn",
		selectPlaceholder: "Chọn ngày trì hoãn",
		amount: "Số lượng",
		orderAmount: "Số lượng đơn đặt hàng",
	},
	autoLogOut: {
		pageTitle: "Đăng xuất tự động",
		warningMsg:
			"Tự động đăng xuất vì số này đã được sử dụng trong lần đăng nhập khác",
		instrMsg: "Bấm vào nút xanh bên dưới để đăng nhập lại",
		btnLabel: "Đăng nhập",
	},
	customerOrder: {
		customerOrderAmount: "Số lượng đơn đặt hàng của Nhà phân phối",
	},
	addBankSettings: {
		bottomSheetTitle: "Thêm Tài Khoản Ngân Hàng",
		pageTitle: "Thêm Tài Khoản Ngân Hàng",
		accountName: "Tên Tài Khoản",
		accountNamePlaceholder: "Nhập tên tài khoản của bạn",
		accountNumber: "Số Tài Khoản",
		accountNumberPlaceholder: "Nhập số tài khoản của bạn",
		selectBank: "Chọn Ngân Hàng",
		selectBankPlaceHolder: "Chọn tài khoản ngân hàng của bạn",
		branch: "Chi Nhánh",
		branchPlaceHolder: "Nhập chi nhánh ngân hàng của bạn",
		address: "Địa Chỉ",
		addressPlaceholder: "Nhập địa chỉ ngân hàng",
		selectCountry: "Chọn Quốc Gia",
		selectCountryPlaceHolder: "Chọn quốc gia ngân hàng của bạn",
		selectCity: "Chọn Thành Phố",
		selectCityPlaceHolder: "Chọn thành phố ngân hàng của bạn",
		postalCode: "Mã Bưu Điện",
		postalCodePlaceHolder: "Nhập mã bưu điện ngân hàng của bạn",
		buttonTitle: "Lưu",
		bankNameWarning: "Vui lòng nhập tên tài khoản ngân hàng của bạn",
		bankNumberWarning: "Vui lòng nhập số tài khoản ngân hàng của bạn",
		selectBankWarning: "Vui lòng chọn ngân hàng",
		branchWarning: "Vui lòng nhập chi nhánh của bạn",
		addressWarning: "Vui lòng nhập địa chỉ của bạn",
		cityWarning: "Vui lòng nhập thành phố của bạn",
		searchBank: "Tìm kiếm ngân hàng của bạn",
	},
	addedBankList: {
		pageTitle: "Danh sách ngân hàng đã thêm",
		acName: "Tên tài khoản",
		acNumber: "Số tài khoản",
		branch: "Chi nhánh",
		address: "Địa chỉ",
		city: "Thành phố",
		country: "Quốc gia",
		addBank: "Thêm tài khoản ngân hàng của bạn",
	},
	defaultPayment: {
		menuTitle: "Thanh toán mặc định",
		pageTitle: "Các thanh toán mặc định",
		pageHeadline:
			"Chọn và chỉ định các điều khoản thanh toán và cài đặt phương thức phù hợp cho người phân phối này khi đặt hàng với Baz AI",
		pageSubHeadline:
			"Các điều khoản thanh toán và phương thức bạn chọn ở đây, người phân phối chỉ có thể sử dụng các điều khoản và phương thức thanh toán này khi đặt hàng với Baz AI",
		paymentTermLabel: "Điều khoản thanh toán",
		paymentTermPlaceHolder: "Chọn một điều khoản thanh toán",
		paymentMethodLabel: "Phương thức thanh toán",
		paymentMethodPlaceHolder: "Chọn một ngân hàng",
		successMessage: "Thanh toán mặc định được thêm thành công",
		successMessage2: "Thanh toán mặc định đã được cập nhật thành công",
	},
	blackListed: {
		pageTitle: "Danh sách đen",
		warningMsg: "Doanh nghiệp của bạn đã bị đưa vào Danh sách đen",
		instrMsg:
			"Vui lòng yêu cầu chủ doanh nghiệp của bạn liên hệ với EkkBaz để thực hiện các biện pháp cần thiết để kích hoạt lại doanh nghiệp của bạn.",
		btnLabel: "Đăng xuất",
	},
	payment: {
		pageTitle: "Thanh toán Ekkbaz",
		bank: "Ngân hàng",
		acName: "Tên TK",
		acNumber: "Số TK",
		branch: "Chi nhánh",
		address: "Địa chỉ",
		city: "Thành phố",
		postal: "Mã bưu chính",
		country: "Quốc gia",
		selectTimeDate: "Chọn Thời gian & Ngày",
		transitionId: "ID Giao dịch",
		transitionIdPlaceHolder: "Nhập ID giao dịch của bạn",
		amount: "Số tiền",
		amountPlaceHolder: "Nhập số tiền bạn muốn gửi",
		uploadReceipt: "Tải lên Hóa đơn",
		senderInfo: "Thông tin Người gửi",
		receiverInfo: "Thông tin Người nhận",
		senderWarning: "Vui lòng thêm tài khoản ngân hàng của bạn trước",
		paymentConformationMessage:
			"Bạn có chắc chắn muốn thực hiện thanh toán này không?",
		inputWarning: "Vui lòng điền vào ô này",
		congressPayment: "Chúc mừng! Thanh toán của bạn đã được gửi thành công.",
		paymentRecieptImage: "Hình ảnh Biên lai Thanh toán",
		receiptOrBank: "Tải lên hình ảnh biên lai hoặc phiếu ngân hàng",
		warning: "Số tiền thanh toán không chính xác.",
	},
	industryType: {
		pageTitle: "Ngành công nghiệp'",
		title: "Xin vui lòng chọn ngành nghề làm việc của bạn",
	},
	driectSales: {
		pageTitle: "Bán Trực Tiếp",
		customer: "Danh Sách Khách Hàng",
		orderList: "Danh Sách Đơn Hàng",
		searchCustomer: "Tìm Kiếm Khách Hàng",
		ownCustomer: "Khách Hàng Riêng",
		allCustomer: "Tất Cả Khách Hàng",
		ownCustomerSearch: "Tìm Kiếm Khách Hàng Riêng",
		allCustomerSearch: "Tìm Kiếm Tất Cả Khách Hàng",
	},
	invitedDistributor: {
		pageTitle: "Danh sách nhà phân phối được mời",
		searchPlaceHolder: "ghi tên nhà phân phối",
		invitedDistributor: "Thêm nhà phân phối này?",
		invited: "Đã mời",
		inviteApprove: "Lời mời được chấp nhận thành công",
	},
	addCard: {
		pageTitle: "Thêm Thẻ",
		addedList: "Danh Sách Thẻ Đã Thêm",
		addNewCard: "Thêm Thẻ Mới",
		continue: "Tiếp Tục",
	},
	paymentModule: {
		pageTitle: "Chọn Gói Nâng Cấp",
		upgradeNow: "Nâng Cấp Ngay",
		monthlyPlan: "Gói Hàng Tháng",
		annualPlan: "Gói Hàng Năm",
		card: "Thẻ",
		paymentMethod: "Phương Thức Thanh Toán",
		continue: "Tiếp Tục",
		selectedMethod: "Vui lòng chọn phương thức thanh toán..!",
		checkOut: "Thanh Toán",
		paymentCard: "Thẻ Thanh Toán",
		changeCard: "Thay Đổi Thẻ",
		selectPackage: "Chọn Gói",
		orderSummary: "Tóm Tắt Đơn Hàng",
		subtotal: "Tạm Tính",
		total: "Tổng Cộng",
		payNow: "Thanh Toán Ngay",
		successTitle: "Thanh Toán Thành Công",
		successText: "Cảm ơn bạn đã thanh toán",
		yourPlan: "Gói Đăng Ký Của Bạn",
		transactionHistory: "Lịch Sử Giao Dịch",
		cardNumber: "Số Thẻ",
		status: "Trạng Thái",
		invoice: "Hóa Đơn",
		newCard: "Thêm Thẻ Mới",
		couponDiscount: "Giảm giá phiếu mua hàng",
		discount: "Giảm giá",
		getDiscount: "Nhận giảm giá",
		noAvailableCard: "Không có thẻ nào có sẵn",
		noTransaction: "Bạn không có giao dịch nào trước đây",
		enterCouponCode: "Nhập mã phiếu giảm giá",
	},
};
export default vietnamese;
