import { IonCol, IonRow, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrder } from "redux/container/orderSlice";

function MyOrderStatusBtn({
	setOrderStatus,
	buyer,
	setReachedEnd,
	setHasMoreData,
}) {
	const dispatch = useDispatch();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonRow>
			<IonCol size='12'>
				<IonSegment
					value=''
					scrollable
					className='orderBtnWrapper '
					onIonChange={(e) => {
						setOrderStatus(e.detail.value);
						setReachedEnd(false);
						setHasMoreData(true);
						if (buyer === "buyer") {
							dispatch(fetchOrder("buyer", e.detail.value));
						} else {
							dispatch(fetchOrder("seller", e.detail.value));
						}
					}}>
					<IonSegmentButton value='' className='recent relative'>
						<button
							className={`text-black-1000 font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuRecent}
						</button>
					</IonSegmentButton>
					<IonSegmentButton value='Placed' className='placed relative'>
						<button
							className={`text-FFC227-1000 font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuPlaced}
						</button>
					</IonSegmentButton>
					<IonSegmentButton value='Accepted' className='accepted relative'>
						<button
							className={`text-ADA2FF font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuAccepted}
						</button>
					</IonSegmentButton>

					<IonSegmentButton
						value='Scheduled For Delivery'
						className='scDelivery relative'>
						<button
							className={`text-7149C6 font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuScDelivered}
						</button>
					</IonSegmentButton>
					<IonSegmentButton
						value='Delivery Failed'
						className='delivery-failed relative'>
						<button className='text-F27900 font-sans p-0 font-bold shadow-none text-14'>
							{ekkLanguage.orderList.tabMenuDeliveryFailed}
						</button>
					</IonSegmentButton>
					<IonSegmentButton
						value='Partially_Delivered'
						className='partial-delivery relative'>
						<button
							className={`text-66D9FF-1000 font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuPartialDelivered}
						</button>
					</IonSegmentButton>
					<IonSegmentButton
						value='Delivered'
						className='delivered relative'>
						<button
							className={`text-0099CC-1000 font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuDelivered}
						</button>
					</IonSegmentButton>
					<IonSegmentButton
						value='Returned_Items'
						className='return relative'>
						<button
							className={`text-262626-1000 font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuReturn}
						</button>
					</IonSegmentButton>

					<IonSegmentButton
						value='Partially_Paid'
						className='partial-payment relative'>
						<button
							className={`text-00FF33-1000 font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuPartialPaid}
						</button>
					</IonSegmentButton>
					<IonSegmentButton value='Paid' className='paid relative'>
						<button
							className={`text-00B224-1000 font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuPaid}
						</button>
					</IonSegmentButton>
					<IonSegmentButton value='Cancel' className='cancel relative'>
						<button
							className={`text-F94B41-1000 font-bold font-sans p-0 shadow-none text-14`}>
							{ekkLanguage.orderList.tabMenuCancel}
						</button>
					</IonSegmentButton>
				</IonSegment>
			</IonCol>
		</IonRow>
	);
}
export default memo(MyOrderStatusBtn);
