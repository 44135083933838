import React from "react";

export default function Download2({ color }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<g clipPath='url(#clip0_1618_181)'>
				<path
					d='M5.90054 11.8C7.0721 12.9716 8.9716 12.9716 10.1432 11.8L10.1432 11.8L12.2846 9.65866C12.6818 9.27497 12.6928 8.64191 12.3091 8.24466C11.9254 7.84741 11.2924 7.83641 10.8951 8.22009C10.8868 8.22812 10.8786 8.23634 10.8706 8.24466L9.01523 10.0993L8.99988 1C8.99988 0.447719 8.55216 0 7.99988 0C7.4476 0 6.99988 0.447719 6.99988 1L7.01388 10.0847L5.17323 8.244C4.77598 7.86031 4.14291 7.87131 3.75923 8.26856C3.38495 8.65609 3.38495 9.27047 3.75923 9.658L5.90054 11.8Z'
					fill={color}
				/>
				<path
					d='M15 9.6665C14.4477 9.6665 14 10.1142 14 10.6665V13.7272C13.9996 13.8776 13.8778 13.9995 13.7273 13.9998H2.27266C2.12222 13.9994 2.00034 13.8776 2 13.7272V10.6665C2 10.1142 1.55228 9.6665 1 9.6665C0.447719 9.6665 0 10.1142 0 10.6665V13.7272C0.00146875 14.9817 1.01812 15.9984 2.27266 15.9998H13.7273C14.9819 15.9984 15.9985 14.9817 16 13.7272V10.6665C16 10.1142 15.5523 9.6665 15 9.6665Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_1618_181'>
					<rect width='16' height='16' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
