import React from "react";
import "./style.scss";

export default function SectionTitle({ title, innerPage }) {
	return (
		<div className='section-title'>
			<h5
				className={`font-semibold text-16 text-222222 relative overflow-hidden text-left`}
				style={innerPage ? { color: "rgba(0,0,0,.33)", fontSize: 16 } : {}}>
				{title}
				<span></span>
			</h5>
		</div>
	);
}
