import React from "react";
import SingleProduct from "./SingleProduct";

export default function ProductHolder({ title }) {
	return (
		<></>
		// <Container fluid>
		// 	<Row>
		// 		<Col md={12}>
		// 			<div className='header flex justify-between py-3 px-1'>
		// 				<h4 className='font-bold'>{title}</h4>
		// 				<Button variant='text'>SHOW MORE</Button>
		// 			</div>
		// 		</Col>
		// 	</Row>
		// 	<Row>
		// 		<Col md={12}>
		// 			<div className='d-flex flex-wrap justify-center'>
		// 				<div className='lg:w-1/3 md:w-1/2 xl:w-1/5 px-2'>
		// 					<SingleProduct />
		// 				</div>
		// 				<div className='lg:w-1/3 md:w-1/2 xl:w-1/5 px-2'>
		// 					<SingleProduct />
		// 				</div>
		// 				<div className='lg:w-1/3 md:w-1/2 xl:w-1/5 px-2'>
		// 					<SingleProduct />
		// 				</div>
		// 				<div className='lg:w-1/3 md:w-1/2 xl:w-1/5 px-2'>
		// 					<SingleProduct />
		// 				</div>
		// 				<div className='lg:w-1/3 md:w-1/2 xl:w-1/5 px-2'>
		// 					<SingleProduct />
		// 				</div>
		// 			</div>
		// 		</Col>
		// 	</Row>
		// </Container>
	);
}
