import { IonApp, IonContent, IonPage } from "@ionic/react";
import OtpSubmit from "components/mobile-components/BusinessProfile/OtpSubmit";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React from "react";
import { useSelector } from "react-redux";

export default function OtpSubmitPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.businessProfile.otpSubmit} />
					<OtpSubmit />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
