import React from "react";
import SingleSubCategory from "./SingleSubCategory";

export default function SubCategoryList({ subCategoryList }) {
	return (
		<div>
			{subCategoryList.length > 0 &&
				subCategoryList.map((el, i) => {
					return <SingleSubCategory item={el} key={i} />;
				})}
		</div>
	);
}
