import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import DashboardDetails from "components/mobile-components/DashBoard/DashboardDetails";
import React from "react";
import { useSelector } from "react-redux";

export default function DashboardDetailsPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.letNav.dashboardProfileMenuLabel}
					/>
					<div className='pt-14'>
						<DashboardDetails />
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
