import React from "react";

export default function BpEdit() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='40'
			height='40'
			viewBox='0 0 40 40'
			fill='none'>
			<circle cx='20' cy='20' r='20' fill='#36A94D' fillOpacity='0.15' />
			<path
				d='M22.8087 10.5498H14.3387C13.9605 10.5498 13.5977 10.7001 13.3302 10.9675C13.0628 11.235 12.9125 11.5978 12.9125 11.9761V20.7961C12.6861 20.8664 12.4878 21.0069 12.3463 21.1971C12.2048 21.3874 12.1273 21.6177 12.125 21.8548V25.3548C12.1273 25.5919 12.2048 25.8222 12.3463 26.0125C12.4878 26.2027 12.6861 26.3432 12.9125 26.4136V28.0498C12.9194 28.4235 13.0727 28.7796 13.3394 29.0414C13.6061 29.3032 13.965 29.4499 14.3387 29.4498H26.4488C26.827 29.4498 27.1898 29.2995 27.4573 29.0321C27.7247 28.7646 27.875 28.4018 27.875 28.0236V15.6248L22.8087 10.5498ZM13.245 25.6611C13.1568 25.6611 13.0722 25.626 13.0099 25.5637C12.9475 25.5013 12.9125 25.4167 12.9125 25.3286V21.8286C12.9125 21.7404 12.9475 21.6558 13.0099 21.5934C13.0722 21.5311 13.1568 21.4961 13.245 21.4961H24.375C24.464 21.496 24.5495 21.5308 24.6133 21.5929C24.677 21.655 24.714 21.7396 24.7162 21.8286V25.3286C24.714 25.4175 24.677 25.5021 24.6133 25.5642C24.5495 25.6263 24.464 25.6611 24.375 25.6611H13.245ZM27.0875 28.0236C27.0875 28.193 27.0202 28.3554 26.9004 28.4752C26.7806 28.595 26.6182 28.6623 26.4488 28.6623H14.3387C14.1693 28.6623 14.0069 28.595 13.8871 28.4752C13.7673 28.3554 13.7 28.193 13.7 28.0236V26.4486H24.375C24.5228 26.4497 24.6694 26.4216 24.8063 26.3658C24.9432 26.3101 25.0677 26.2277 25.1726 26.1236C25.2776 26.0195 25.3608 25.8956 25.4177 25.7592C25.4745 25.6227 25.5038 25.4764 25.5038 25.3286V21.8286C25.4969 21.5346 25.3746 21.2551 25.1634 21.0504C24.9523 20.8458 24.6691 20.7324 24.375 20.7348H13.7V11.9848C13.7 11.8154 13.7673 11.6529 13.8871 11.5331C14.0069 11.4134 14.1693 11.3461 14.3387 11.3461H22.4762L22.6425 11.5123V14.7498C22.6425 15.0236 22.7513 15.2863 22.9449 15.4799C23.1385 15.6735 23.4012 15.7823 23.675 15.7823H26.9212L27.0875 15.9486V28.0236Z'
				fill='#36A94D'
			/>
			<path
				d='M17.0424 22.38C16.8432 22.2959 16.6286 22.2542 16.4124 22.2575H15.2224V24.9963H15.8612V24.2438H16.4124C16.6286 24.2471 16.8432 24.2054 17.0424 24.1213C17.2097 24.0499 17.3526 23.9313 17.4537 23.78C17.5513 23.6223 17.603 23.4405 17.603 23.255C17.603 23.0695 17.5513 22.8877 17.4537 22.73C17.3507 22.5783 17.2086 22.4574 17.0424 22.38ZM16.8062 23.605C16.6819 23.6946 16.5302 23.738 16.3774 23.7275H15.8612V22.7738H16.3774C16.5318 22.7617 16.685 22.8086 16.8062 22.905C16.8579 22.9466 16.8985 23.0003 16.9245 23.0613C16.9504 23.1224 16.9609 23.1889 16.9549 23.255C16.9601 23.321 16.9493 23.3873 16.9234 23.4482C16.8975 23.5092 16.8573 23.563 16.8062 23.605Z'
				fill='#36A94D'
			/>
			<path
				d='M20.0788 22.4325C19.8335 22.3136 19.5639 22.2537 19.2913 22.2575H18.0488V24.9962H19.2913C19.5632 25.0028 19.8328 24.9458 20.0788 24.83C20.2952 24.7303 20.4799 24.5729 20.6126 24.375C20.7385 24.1568 20.8048 23.9094 20.8048 23.6575C20.8048 23.4056 20.7385 23.1581 20.6126 22.94C20.4875 22.7227 20.3021 22.5464 20.0788 22.4325ZM19.9126 24.2525C19.8256 24.3321 19.7233 24.3933 19.6121 24.4324C19.5008 24.4715 19.3828 24.4877 19.2651 24.48H18.6788V22.7825H19.2651C19.3828 22.7748 19.5008 22.791 19.6121 22.8301C19.7233 22.8692 19.8256 22.9304 19.9126 23.01C19.996 23.0896 20.0611 23.1864 20.1034 23.2937C20.1457 23.401 20.1642 23.5161 20.1576 23.6312C20.1636 23.7463 20.1448 23.8613 20.1025 23.9685C20.0603 24.0756 19.9955 24.1725 19.9126 24.2525Z'
				fill='#36A94D'
			/>
			<path
				d='M21.2776 24.9961H21.9076V23.9986H23.1763V23.4911H21.9076V22.7736H23.3426V22.2661H21.2776V24.9961Z'
				fill='#36A94D'
			/>
		</svg>
	);
}
