import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import TradLicense from "components/mobile-components/VerifyBusiness/TradLicense";
import React from "react";

export default function VBTradLicensePage() {
	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title='Verify your Business' />
					<TradLicense />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
