import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import "./map.scss";
import Location from "assets/images/icon/svg/Location";

const ClustarMarkerMap = ({ data, height }) => {
	const [zoom, setZoom] = useState(8);
	const [bounds, setBounds] = useState(null);
	const locations = data ? data : [];

	const points = locations.map((item, i) => ({
		type: "Feature",
		properties: {
			cluster: false,
			userId: i,
			category: "Location viewer",
		},
		geometry: {
			type: "Point",
			coordinates: [parseFloat(item?.lon), parseFloat(item?.lat)],
		},
	}));

	const { clusters } = useSupercluster({
		points,
		bounds,
		zoom,
		options: { radius: 75, maxZoom: 20 },
	});

	const Marker = ({ children }) => children;

	return (
		<>
			<div className='contact-map'>
				<div
					style={{
						height: height ? height : "calc(100vh - 200px)",
						width: "100%",
					}}>
					<GoogleMapReact
						bootstrapURLKeys={{
							key: "AIzaSyC_BbO07oKPwPwkaClBRCKJV3zAKf2XBZk",
						}}
						defaultCenter={{
							lat: 23.80078341332467,
							lng: 90.43026931780823,
						}}
						defaultZoom={zoom}
						onChange={({ zoom, bounds }) => {
							setZoom(zoom);
							setBounds([
								bounds.nw.lng,
								bounds.se.lat,
								bounds.se.lng,
								bounds.nw.lat,
							]);
						}}>
						{clusters?.map((cluster, index) => {
							const [longitude, latitude] = cluster.geometry.coordinates;
							const { cluster: isCluster, point_count: pointCount } =
								cluster.properties;

							if (isCluster) {
								return (
									<Marker
										key={`cluster-${cluster?.id}`}
										lat={latitude}
										lng={longitude}>
										<div
											className='cluster-marker'
											style={{
												width: `${
													20 + (pointCount / points.length) * 20
												}px`,
												height: `${
													20 + (pointCount / points.length) * 20
												}px`,
											}}>
											{pointCount}
										</div>
									</Marker>
								);
							} else {
								return (
									<Marker key={index} lat={latitude} lng={longitude}>
										<button className='crime-marker'>
											<Location />
										</button>
									</Marker>
								);
							}
						})}
					</GoogleMapReact>
				</div>
			</div>
		</>
	);
};

export default ClustarMarkerMap;
