import React from "react";
export default function EyeOn() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='28'
			height='28'
			viewBox='0 0 28 28'
			fill='none'>
			<g clipPath='url(#clip0_1445_30)'>
				<path
					d='M27.1493 10.9886C25.3398 8.04162 21.2238 3.09729 13.9998 3.09729C6.77582 3.09729 2.65982 8.04162 0.850316 10.9886C0.290932 11.8934 -0.00537109 12.9361 -0.00537109 13.9998C-0.00537109 15.0635 0.290932 16.1062 0.850316 17.011C2.65982 19.958 6.77582 24.9023 13.9998 24.9023C21.2238 24.9023 25.3398 19.958 27.1493 17.011C27.7087 16.1062 28.005 15.0635 28.005 13.9998C28.005 12.9361 27.7087 11.8934 27.1493 10.9886ZM25.1601 15.7895C23.6061 18.3165 20.0886 22.569 13.9998 22.569C7.91098 22.569 4.39348 18.3165 2.83948 15.7895C2.50714 15.2517 2.33111 14.632 2.33111 13.9998C2.33111 13.3676 2.50714 12.7479 2.83948 12.2101C4.39348 9.68312 7.91098 5.43062 13.9998 5.43062C20.0886 5.43062 23.6061 9.67846 25.1601 12.2101C25.4925 12.7479 25.6685 13.3676 25.6685 13.9998C25.6685 14.632 25.4925 15.2517 25.1601 15.7895Z'
					fill='#222222'
				/>
				<path
					d='M13.9997 8.1665C12.846 8.1665 11.7182 8.50862 10.7589 9.1496C9.7996 9.79057 9.05193 10.7016 8.61042 11.7675C8.16891 12.8334 8.05339 14.0063 8.27847 15.1379C8.50355 16.2694 9.05912 17.3088 9.87493 18.1246C10.6907 18.9404 11.7301 19.496 12.8617 19.7211C13.9932 19.9462 15.1661 19.8306 16.232 19.3891C17.2979 18.9476 18.209 18.2 18.85 17.2407C19.4909 16.2814 19.8331 15.1536 19.8331 13.9998C19.8312 12.4533 19.216 10.9707 18.1225 9.87709C17.0289 8.78353 15.5462 8.16836 13.9997 8.1665ZM13.9997 17.4998C13.3075 17.4998 12.6308 17.2946 12.0552 16.91C11.4796 16.5254 11.031 15.9788 10.7661 15.3392C10.5012 14.6997 10.4319 13.996 10.567 13.317C10.702 12.6381 11.0354 12.0144 11.5248 11.525C12.0143 11.0355 12.638 10.7021 13.3169 10.5671C13.9958 10.432 14.6996 10.5014 15.3391 10.7663C15.9787 11.0312 16.5253 11.4798 16.9099 12.0553C17.2944 12.6309 17.4997 13.3076 17.4997 13.9998C17.4997 14.9281 17.131 15.8183 16.4746 16.4747C15.8182 17.1311 14.928 17.4998 13.9997 17.4998Z'
					fill='#222222'
				/>
			</g>
			<defs>
				<clipPath id='clip0_1445_30'>
					<rect width='28' height='28' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
