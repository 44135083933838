import Location from "assets/images/icon/svg/Location";
import { getUserBusiness } from "config/user";
import React from "react";
import { useSelector } from "react-redux";
import Card from "../Common/Card";

export default function CustomerDetailsCard() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='px-2 pt-4 pb-5'>
			<Card title={ekkLanguage.createOrder.buyerDetailsTitle}>
				<div className='pt-4 px-2'>
					<h4 className='text-success text-14 font-bold pb-3'>
						{getUserBusiness()?.business_name}
					</h4>
					<div className='flex'>
						<span>
							<Location />
						</span>
						<p className='text-12 text-000080 ml-2'>{`${
							getUserBusiness()?.address_line
						} ${getUserBusiness()?.country} ${getUserBusiness()?.city} ${
							getUserBusiness()?.postal_code
						}`}</p>
					</div>
				</div>
			</Card>
		</div>
	);
}
