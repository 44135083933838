import {
	IonApp,
	IonContent,
	IonLabel,
	IonPage,
	IonButton,
	IonCol,
	IonSpinner,
} from "@ionic/react";
import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomCheckBox from "components/global-components/common/CustomCheckBox";
import DatePicker from "react-datepicker";
import Calendar from "assets/images/icon/svg/Calendar";
import moment from "moment";
import { getSelectedDate } from "redux/container/dashboardSlice";
import Select from "react-select";
import {
	dateTimeFormatter,
	digitChanger,
	numberWithCommas,
} from "helpers/helper";
import DashBoard from "services/Dashboard";
import { setDashboardBottomSheet } from "redux/container/userSlice";
import { setProductSummery } from "redux/container/ProductSummerySlice";

export default function DelayInPayment() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	// const [selectedDate, setSelectedDate] = useState(moment(new Date()));
	const [selectedDate, setSelectedDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [day, setDay] = useState(null);
	const dayInfo = useSelector((state) => state.dashboard.dateInfo.day);
	const days = useSelector((state) => state.productSummery.productData.day);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let [loading, setLoading] = useState(false);
	const [orderList, setOrderList] = useState({});

	const options = [
		// { value: "All", label: ekkLanguage.orderProductSummary.all },
		{ value: "2", label: digitChanger(`2 ${ekkLanguage.receipt.days}`) },
		{ value: "3", label: digitChanger(`3 ${ekkLanguage.receipt.days}`) },
		{ value: "5", label: digitChanger(`5 ${ekkLanguage.receipt.days}`) },
		{ value: "7", label: digitChanger(`7 ${ekkLanguage.receipt.days}`) },
		{ value: "14", label: digitChanger(`14 ${ekkLanguage.receipt.days}`) },
		{ value: "21", label: digitChanger(`21 ${ekkLanguage.receipt.days}`) },
		{ value: "30", label: digitChanger(`30 ${ekkLanguage.receipt.days}`) },
		{ value: "45", label: digitChanger(`45 ${ekkLanguage.receipt.days}`) },
		{ value: "60", label: digitChanger(`60 ${ekkLanguage.receipt.days}`) },
		{ value: "90", label: digitChanger(`90 ${ekkLanguage.receipt.days}`) },
	];
	const OfferStartDate = forwardRef(({ value, onClick }, ref) => (
		<div
			className={`bg-white h-10 w-10 rounded-[5px] ml-1 flex items-center justify-center`}
			onClick={onClick}>
			<Calendar></Calendar>
		</div>
	));
	const getDelayInPayAll = async () => {
		let res = await DashBoard.getAllDelayInPay();
		if (res.status === 200) {
			setLoading(false);
			setOrderList(res?.data);
		} else {
			setLoading(false);
			setOrderList([]);
		}
	};
	const getDelayInPay = async (day) => {
		setLoading(true);
		setOrderList("");
		let res = await DashBoard.getDelayInPay(day);
		if (res.status === 200) {
			setLoading(false);
			setOrderList(res?.data);
		} else {
			setLoading(false);
			setOrderList([]);
		}
	};

	dispatch(setDashboardBottomSheet(false));
	{
	}
	useEffect(() => {
		setDay(days);
		let interval = setTimeout(() => {
			if (dayInfo) {
				getDelayInPay(dayInfo);
			}
			// else {
			//     getDelayInPayAll();
			// }
		}, 500);
		return () => clearTimeout(interval);
	}, [, dispatch]);

	const selectDayChanger = (e) => {
		setDay(e.value);
		getDelayInPay(e.value);
		let dayStatus = {
			day: e.value,
		};
		dispatch(getSelectedDate(dayStatus));
	};
	const handleNavigate = (buyerId, buyerName) => {
		let data = {
			buyerId,
			buyerName,
			day,
		};

		dispatch(setProductSummery(data));
		navigate(`/delay-in-payment/${buyerId}`);
	};

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<div className='pt-20'>
						<div className='flex justify-center'>
							<Select
								onFocus={(e) => (e.target.readOnly = true)}
								value={options.find((el) => el.value === dayInfo)}
								options={options}
								onChange={selectDayChanger}
								placeholder={`${ekkLanguage.orderSummary.selectPlaceholder}`}
								className='custom-select'
								styles={{
									control: (baseStyles, state) => ({
										...baseStyles,
										width: 328,
										height: 40,
										border: dayInfo
											? "0.5px solid #00B224"
											: "1px solid #FF564C",
										outLine: "none",
										fontWeight: 500,
										fontSize: 12,
										borderRadius: 5,
									}),
									placeholder: (baseStyles, state) => ({
										...baseStyles,
										color: "#FF564C",
									}),
									indicatorSeparator: (baseStyles, state) => ({
										...baseStyles,
										display: "none",
									}),
									dropdownIndicator: (baseStyles, state) => ({
										...baseStyles,
										color: "#FF564C",
										color: dayInfo ? "#00B224" : "#FF564C",
									}),
								}}
								theme={(theme) => ({
									...theme,
									borderRadius: 5,
									colors: {
										...theme.colors,
										primary25: "rgb(204, 255, 214)",
										primary: "#00FF33",
									},
								})}
							/>
						</div>

						<div className='mt-5'>
							<table
								className='table-auto w-full bg-white'
								style={{
									boxShadow: "0px 3px 20px 0px rgba(0, 0, 0, 0.05)",
								}}>
								<thead>
									<tr className='border h-[45px] border-r-0 border-l-0 border-D9D9D9 px-4'>
										<th className='border p-2 w-14 font-bold text-12'>
											S/N
										</th>
										<th className='border py-2 w-[185px] font-bold text-12'>
											{ekkLanguage.orderSummary.storeName}
										</th>
										<th className='border p-1 w-32 font-bold text-12'>
											{ekkLanguage.orderSummary.dueAmount}
										</th>
									</tr>
								</thead>
								<tbody>
									{orderList?.length
										? orderList?.map((el, i) => {
												return (
													<tr
														className='text-center h-[34px] border border-r-0 border-l-0 border-D9D9D9 px-4'
														key={i}>
														<td className='border font-semibold text-10'>
															{digitChanger(i + 1)}
														</td>

														<td
															onClick={() =>
																handleNavigate(
																	el?.buyerId,
																	el?.businessName
																)
															}
															className='border text-[#2493c7] font-semibold p-2 text-10'>
															{digitChanger(el?.businessName)}
														</td>
														<td className='border font-semibold text-10'>
															৳
															{digitChanger(
																numberWithCommas(el?.due)
															)}
														</td>
													</tr>
												);
										  })
										: ""}
								</tbody>
							</table>
							{loading ? (
								<div className='flex items-center justify-center h-[156px] m-auto'>
									{" "}
									<IonSpinner name='crescent'></IonSpinner>
								</div>
							) : (
								<div>
									{orderList?.length === 0 && (
										<>
											<IonCol size='2'></IonCol>
											<IonCol size='8'>
												<div
													className='text-center flex items-center justify-center'
													style={{
														height: "calc(100vh - 420px)",
													}}>
													<div>
														<img
															className='m-auto mb-[10px] mt-5'
															src='/assets/images/not-found-file.png'
															alt='emptyCard'
														/>
														<p className='text-16 font-normal text-222222 mb-[35px]'>
															{ekkLanguage.orderList.notFound}
														</p>
													</div>
												</div>
											</IonCol>
										</>
									)}
								</div>
							)}
						</div>
					</div>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
