import React from "react";

export default function SingleMethodCard({
	method,
	selected,
	setSelected,
	setAlert,
}) {
	return (
		<div
			className={`bg-[#FFFFFF] ${
				method?.title ? "p-2" : "p-3"
			} flex flex-col justify-center items-center rounded-[10px] border-2 ${
				selected?.id === method?.id
					? "border-[#00B224]"
					: "border-[#FFFFFF]"
			}`}
			style={{ boxShadow: "0px 3px 15px 0px #0000000D" }}
			onClick={() => {
				setSelected(method);
				setAlert("");
			}}>
			<img src={method?.icon} alt='' />
			{method?.title && (
				<p className='text-[#000000] font-bold text-14 mt-1'>
					{method?.title}
				</p>
			)}
		</div>
	);
}
