import React from "react";
import SingleDepartment from "./SingleDepartment";

export default function DepartmentList({ departmentList }) {
	return (
		<div>
			{departmentList.length > 0 &&
				departmentList.map((el, i) => {
					return <SingleDepartment item={el} key={i} />;
				})}
		</div>
	);
}
