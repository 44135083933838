import React from "react";

export default function Product() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='20'
			height='20'
			viewBox='0 0 20 20'
			fill='none'>
			<g clipPath='url(#clip0_2144_214)'>
				<path
					d='M17.5 13.8516V6.14844C17.4994 6.03717 17.4695 5.92803 17.4132 5.83202C17.357 5.736 17.2764 5.65651 17.1797 5.60157L10.3047 1.73438C10.2121 1.6809 10.107 1.65274 10 1.65274C9.89303 1.65274 9.78795 1.6809 9.69531 1.73438L2.82031 5.60157C2.72356 5.65651 2.643 5.736 2.58676 5.83202C2.53052 5.92803 2.5006 6.03717 2.5 6.14844V13.8516C2.5006 13.9628 2.53052 14.072 2.58676 14.168C2.643 14.264 2.72356 14.3435 2.82031 14.3984L9.69531 18.2656C9.78795 18.3191 9.89303 18.3473 10 18.3473C10.107 18.3473 10.2121 18.3191 10.3047 18.2656L17.1797 14.3984C17.2764 14.3435 17.357 14.264 17.4132 14.168C17.4695 14.072 17.4994 13.9628 17.5 13.8516Z'
					stroke='black'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M13.8281 11.9141V7.85156L6.25 3.67188'
					stroke='black'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M17.4141 5.82812L10.0703 10L2.58594 5.82812'
					stroke='black'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
				<path
					d='M10.0703 10L10 18.3438'
					stroke='black'
					strokeWidth='1.5'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0_2144_214'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
}
