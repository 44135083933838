import { IonApp, IonContent, IonLoading, IonPage } from "@ionic/react";
import AddBankBottomSheet from "components/mobile-components/AddBankAccount/AddBankBottomSheet";
import AddedBankList from "components/mobile-components/AddBankAccount/AddedBankList";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Business from "services/Business";

export default function AddedBankListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [banks, setBanks] = useState([]);
	const [empLoading, setEmpLoading] = useState(false);

	const banksSearch = async () => {
		setEmpLoading(true);
		let response = await Business.getBankList();
		if (response.status === 200) {
			setBanks(response.results);
			setEmpLoading(false);
		} else {
			setEmpLoading(false);
		}
	};
	useEffect(() => {
		banksSearch();
	}, []);

	return (
		<IonApp>
			<IonPage className='business-name'>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.addedBankList.pageTitle}
						from='addedBankList'
					/>
					{empLoading && (
						<IonLoading
							isOpen={true}
							message={ekkLanguage.orderList?.loader}
						/>
					)}
					<section className='pt-14 px-2'>
						<AddedBankList banks={banks} ekkLanguage={ekkLanguage} />
					</section>
					<AddBankBottomSheet />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
