import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
} from "@ionic/react";
import { getUser, getUserBusiness } from "config/user";
import { updateToken } from "../../../config/SuperFetch";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { authOtpSend } from "helpers/helper";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OTPInput from "../Profile/OTPInput";
import Modal from "react-modal";
import { ClipLoader } from "react-spinners";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import Business from "services/Business";
import { actions } from "redux/container/authSlice";
import OtpInput from "react-otp-input";

export default function OtpSubmit() {
	let user = getUser();
	let [otp, setOtp] = useState("");
	const [waiting, setWaiting] = useState(false);
	const [counter, setCounter] = useState(120);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [loader, setLoader] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);
	const dispatch = useDispatch();
	let time = 0;
	var interval;
	let business = getUserBusiness();
	const otpSubmitHandler = async () => {
		setLoader(true);
		if (otp.length > 0) {
			let req_body = {
				otp: Number(otp),
			};

			try {
				let response = await Business.businessDelete(req_body);
				if (response.status === 200) {
					setLoader(false);
					toast.success(ekkLanguage.otpsubmit.tokenVarifiedMessage);
					dispatch(actions.logout());
					localStorage.removeItem("master_token");
					localStorage.removeItem("_u");
					localStorage.removeItem("_b");
					localStorage.removeItem("cb");
					localStorage.setItem("auth", "false");
				} else if (response.status === 406) {
					setLoader(false);
					onCloseModal();
					toast.error(ekkLanguage.otpsubmit.optNotMatch);
				} else {
					setLoader(false);
					toast.error(response.message);
				}
			} catch (e) {
				toast.error(e.errorData.message);
			}
		} else {
			toast.error(ekkLanguage.otpsubmit.validationLabel);
		}
	};

	//this function handle token resend
	const resendTokenHandler = async (phoneNumber, country, countryCode) => {
		let getToken = Business.businessDeleteOtp();
		getToken
			.then((res) => {
				if (res.status === 200) {
					toast.success(ekkLanguage.otpsubmit.successLabel);
					setWaiting(true);
					interval = setInterval(() => {
						time = time + 1;
						setCounter(counter - time);
						if (time === 120) {
							setWaiting(false);
							clearInterval(interval);
							setCounter(120);
						}
					}, 1000);
				} else {
					toast.success(ekkLanguage.otpsubmit.errorLabel);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		setWaiting(true);
		let time = 0;
		let int;
		int = setInterval(() => {
			time = time + 1;
			setCounter(counter - time);
			if (time === 120) {
				setWaiting(false);
				clearInterval(int);
				setCounter(120);
			}
		}, 1000);
	}, []);

	const customStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	return (
		<div className='px-2 pt-24 relative change-password-screen'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='mb-2 text-center'>
							<h5 className='text-24 font-semibold text-black-1000 mb-5'>
								{ekkLanguage.otpsubmit.title}
							</h5>
							<p className='ext-14 font-normal text-black-500 '>
								{ekkLanguage.otpsubmit.paragraph}
							</p>
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='flex mb-7'>
							<div className='w-full otp-wrapper'>
								<h5 className='text-dark-1000 text-16 font-semibold text-center mb-[70px]'>
									{ekkLanguage.otpsubmit.otpLabel}
								</h5>
								{/* <IonItem> */}

								<OtpInput
									style={{ justifyContent: "center" }}
									value={otp}
									onChange={setOtp}
									numInputs={6}
									type='number'
									// renderSeparator={<span>-</span>}
									renderInput={(props) => (
										<input
											type='number'
											{...props}
											className='h-10 border-b-2 mx-1 text-center focus-visible:bg-00B224-333 focus:outline-none'
											style={{
												width: "40px",
												borderColor: "#00E52E",
											}}
										/>
									)}
								/>
								{/* <OTPInput
									otp={otp}
									ekkLanguage={ekkLanguage}
									setOtp={setOtp}
								/> */}
							</div>
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='mb-7'>
							<div className='ml-[10px] w-full text-center'>
								{!waiting ? (
									<p className='text-dark-500 text-14 font-normal'>
										{ekkLanguage.otpsubmit.receiveYetLabel}{" "}
										<span
											className='underline text-primary font-semibold cursor-pointer'
											onClick={() =>
												resendTokenHandler(
													user.phone_number,
													user.Country,
													""
												)
											}>
											{ekkLanguage.otpsubmit.againRequestLabel}
										</span>
									</p>
								) : (
									<p className='text-dark-500 text-14 font-normal'>
										{ekkLanguage.otpsubmit.timerLabel} {counter}{" "}
										{ekkLanguage.otpsubmit.second}
									</p>
								)}
							</div>
						</div>
					</IonCol>
				</IonRow>
				<div className='submit-button-wrapper'>
					<IonRow>
						<IonCol size='12'>
							<div className='text-center pt-28'>
								<IonButton
									onClick={onOpenModal}
									expand='full'
									className='bg-success rounded-[7px] font-extrabold text-18 h-[50px]'>
									{ekkLanguage.otpsubmit.verifiLabel}
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</div>
			</IonGrid>
			<Modal
				style={customStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.businessProfile.confirmDeleteWarning}
					</h6>

					<div className='flex'>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 mr-2 border-2 bg-primary text-white flex justify-center items-center`}
							onClick={!loader && otpSubmitHandler}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2  ml-2 bg-success text-white'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
