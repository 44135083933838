import React from "react";

export default function UpgrationBenefits({ selected }) {
	return (
		<div className='mt-8 pb-8'>
			{selected?.description?.map((benefit, i) => {
				return (
					<div key={i} className='flex my-2'>
						<img
							className='w-[17px] h-[17px] mr-2'
							src='/assets/images/tic.png'
							alt=''
						/>
						<p className='font-semibold text-16 leading-5 text-white'>
							{benefit}
						</p>
					</div>
				);
			})}
		</div>
	);
}
